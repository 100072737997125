import React from 'react'
import { ListItem, ListItemText, ListItemButton, Dialog, DialogTitle, DialogActions, DialogContent, Button, List } from '@mui/material'
import jsPDF from 'jspdf'
import { CSVLink, CSVDownload } from "react-csv";
import { useRef } from 'react';
import { read, utils, writeFileXLSX } from 'xlsx';

const styles = theme => ({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
  
    item: {
      padding: 0
    }
  });
  


const data = [
    {
      Event: 'Tax',
      Date: '03-12-21',
      Rate: '1,000.00',
      Base: '1,000.00',
      Interest: '',
      Balance: ''
    },
    {
      Event: 'Interest',
      Date: '03-31-21',
      Rate: '3.00',
      Base: '1,000.00',
      Interest: '1.56',
      Balance: '1,001.56'
    },
    {
      Event: 'Interest',
      Date: '06-30-21',
      Rate: '3.00',
      Base: '1,001.56',
      Interest: '7.52',
      Balance: '1,009.08'
    },
    {
      Event: 'Interest',
      Date: '09-30-21',
      Rate: '3.00',
      Base: '1,009.08',
      Interest: '7.66',
      Balance: '1,016.74'
    },
    {
      Event: 'Interest',
      Date: '12-31-21',
      Rate: '3.00',
      Base: '1,016.74',
      Interest: '7.72',
      Balance: '1,024.46'
    },
    {
      Event: 'Interest',
      Date: '03-31-22',
      Rate: '3.00',
      Base: '1,024.46',
      Interest: '7.61',
      Balance: '1,032.07'
    },
    {
      Event: 'Interest',
      Date: '06-30-22',
      Rate: '4.00',
      Base: '1,032.07',
      Interest: '10.34',
      Balance: '1,042.41'
    },
    {
      Event: 'Interest',
      Date: '09-30-22',
      Rate: '5.00',
      Base: '1,042.41',
      Interest: '13.22',
      Balance: '1,055.63'
    },
    {
      Event: 'Interest',
      Date: '12-31-22',
      Rate: '6.00',
      Base: '1,055.63',
      Interest: '16.08',
      Balance: '1,071.71'
    },
    {
      Event: 'Interest',
      Date: '03-31-23',
      Rate: '7.00',
      Base: '1,071.71',
      Interest: '18.66',
      Balance: '1,090.37'
    },
    {
      Event: 'Interest',
      Date: '06-30-23',
      Rate: '7.00',
      Base: '1,090.37',
      Interest: '19.19',
      Balance: '1,109.56'
    },
    {
      Event: 'Interest',
      Date: '09-30-23',
      Rate: '7.00',
      Base: '1,109.56',
      Interest: '19.75',
      Balance: '1,129.31'
    },
    {
      Event: 'Interest',
      Date: '12-31-23',
      Rate: '8.00',
      Base: '1,129.31',
      Interest: '23.00',
      Balance: '1,152.31'
    },
    {
      Event: 'Interest',
      Date: '03-12-24',
      Rate: '8.00',
      Base: '1,152.31',
      Interest: '18.28',
      Balance: '1,170.59'
    }
  ]

const ExportAs = (props) => {

    const csvLinkRef = useRef(null);

    const onExportAsPdf = () => {
        const doc = new jsPDF({
            orientation: 'l',
            format: 'a4',
            unit: 'px',
        });

        doc.html(props.report, {
            async callback(doc) {
                await doc.save('tax-value-report');
                props.onClose();
            },
        });

    }

    const onExportAsCsv = () => {
        console.log(csvLinkRef.current);
        setTimeout(() => {

            csvLinkRef.current.click();
        });
        props.onClose();
    }
    const onExportAsExcel = () => {
        const ws = utils.json_to_sheet(data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        writeFileXLSX(wb, "tax-value-report.xlsx");
        props.onClose();
    }


    const onExportAsWordDoc = ()=>{
        var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
        var postHtml = "</body></html>";
        var html = preHtml+props.report+postHtml;
    
        var blob = new Blob(['\ufeff', html], {
            type: 'application/msword'
        });
        
        // Specify link url
        var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
        
        // Specify file name
        const filename = 'tax-value-report.doc';
        
        // Create download link element
        var downloadLink = document.createElement("a");
    
        document.body.appendChild(downloadLink);
        
        if(navigator.msSaveOrOpenBlob ){
            navigator.msSaveOrOpenBlob(blob, filename);
        }else{
            // Create a link to the file
            downloadLink.href = url;
            
            // Setting the file name
            downloadLink.download = filename;
            
            //triggering the function
            downloadLink.click();
        }
        
        document.body.removeChild(downloadLink);
    }

    return (
        <div className="report">

            <Dialog
                open={props.open}
                onClose={props.onClose}
                maxWidth={'sm'}
                scroll={'paper'}
            >
                <DialogContent dividers={true}>
                    <List>
                        <ExportItem classes = {styles} name={"Export As PDF"} onClick={onExportAsPdf} />
                        <ExportItem classes = {styles} name={"Export As DOC"} onClick={onExportAsWordDoc} />
                        {/* <CSVLink className='csvLink' data={data} ref={csvLinkRef} filename='tax-value-report.csv'><div className="export">Export As CSV</div></CSVLink> */}
                        <ExportItem classes = {styles} name={"Export As Excel"} onClick={onExportAsExcel} />
                    </List>
                </DialogContent>
                <DialogActions>
                    <button className='action-button cancel' onClick={props.onClose} >Close</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ExportAs


const ExportItem = (props) => {
    const { classes } = props;

    return (
        <ListItem key={props.name} classes={{ root: classes.item }}>
            <ListItemButton onClick={props.onClick} disablePadding>
                <ListItemText primary={props.name} />
            </ListItemButton>
        </ListItem>
    )
}