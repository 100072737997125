import React, { useState, useEffect, useRef } from 'react'
import './TaxInterest.css'
import Topbar from '../Topbar/Topbar'
import Sheets from '../Sheets/Sheets'
import Sidebar from '../Sidebar/Sidebar'
import TaxInterestForm from '../TaxInterestForm'
import usePenalties from '../../hooks/usePenalties'
import useToday from '../../hooks/useToday'
import FilesModal from '../FilesPicker/FilesModal'
import useLocalStorage from '../../hooks/useLocalStorage'
import FileNameDialog from '../FileName/FileNameDialog'
import AlertDialogWithCancel from '../AlertDialogWithCancel'
import AlertDialogWithClose from '../AlertDialogWithClose'
import AlertDialog from '../AlertDialog'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast';
import useClerk from '../../hooks/userClerk'
import useEvents from '../../hooks/useEvents'
import Compute from '../compute/Compute'
import moment from 'moment'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CustomTextField from '../TextField/CustomTextField';
import FileNameAllDialog from '../FileName/FileNameAllDialog'
import _ from 'lodash';
const TaxInterest = (props) => {
  const [openPenalty, setOpenPenalty] = useState(false);
  const [openFilesModal, setOpenFilesModal] = useState(false)
  const [openHotInterest, setOpenHotInterest] = useState(false);
  const [openCCorp, setOpenCCorp] = useState(false);
  const [openStates, setOpenStates] = useState(false);
  const { penalties, hotInterest, cCorp, values } = usePenalties();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openRenameAlert, setOpenRenameAlert] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openCloseConfirmation, setOpenCloseConfirmation] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showCloseMessage, setShowCloseMessage] = useState(false);
  const { getClerkToken } = useClerk();
  const [openComputeDialog, setOpenComputeDialog] = useState(false);
  const [computePayload, setComputePayload] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [computeData, setComputeData] = useState('');
  const [oldFolderName, setOldFolderName] = useState('cf');
  const [folderNames, setFolderNames] = useState('');
  const [trashFiles, setTrashFiles] = useState('');
  const [trashFolders, setTrashFolders] = useState();
  const { eventNames } = useEvents();
  const [filesList, setFilesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const newFileValue = {
    events: [
      {
        eventType: "Tax",
        eventDate: useToday(), // Set initial date value
        eventAmount: null,
        eventDescription: '',
      }
    ],
    state: {
      "name": "Federal",
      "code": "Federal",
      "displayName": "Intrest Rates",
      "rateCurrentsTo": "09-30-2023",
      "tips": "Tips for the table"
    },
    penalties: penalties,
    user: {
      name: '',
      id: ''
    },
    hotInterest: hotInterest,
    cCorp: cCorp
  }
  // localStorage.clear();
  const [data, setData] = useLocalStorage('tables', [{ fileName: 'Untitled 1', value: newFileValue, fileInfo: null, isSaved: false }])
  const [hasChange, setHasChange] = useState(false);
  //const [tValue, setTvalue] = useState(data[currentIndex].value);
  const [tValue, setTvalue] = useState(data[currentIndex]?.value || newFileValue);
  const [initialFileData, setInitialFileData] = useState(newFileValue);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  //const [tValue, setTvalue] = useState(newFileValue);
  const onSelectedMenu = (index) => {
    switch (index) {
      case 0:
        onAddNewFile();
        break
      case 1:
        setOpenFilesModal(true);
        break;
      case 2:
        onCompute();
        break;
      case 3:
        openPenalties();
        break;
      case 4:
        setOpenHotInterest(true);
        break;
      case 5:
        setOpenCCorp(true);
      case 6:
          setOpenCCorp(true);
        break;
      default:
        break;
    }
  }
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  useEffect(() => {
    if (data.length === 0) {
      onAddNewFile();
    }
    // setTvalue(data[currentIndex].value);
  }, [data, currentIndex]);
  useEffect(() => {
    if (currentIndex !== null) {
      console.log(data);
     // onChangeTvalue(data[currentIndex].value);
      //onSelect(currentIndex); // Trigger onSelect when currentIndex is updated
    }
  }, [currentIndex]);
  const inputRef = useRef(null);
  
  useEffect(() => {
    if (sessionStorage.getItem('isRefreshed')) {
      console.log('Page is refreshed!');
      sessionStorage.setItem('isRefreshed', 'false');
      // Add any logic you want to run on page refresh
    } else {
      // First page load
      sessionStorage.setItem('isRefreshed', 'true');
    }
    const timeout = setTimeout(() => {
      const fetchFolders = async () => {
        try {
          const foldernames = await onListFolders();
          console.log("initial fol");
          console.log(foldernames);
          setFolderNames(foldernames);
          //setOldFolderName(foldernames[0]);
        } catch (error) {
          console.error("Error fetching folder names: ", error);
        }
      };
      const fetchFiles = async () => {
        const token = await getClerkToken();
        console.log(token)
        const fileurl = BASE_URL+"/api/files";
        axios.get(
            fileurl, {headers:{'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}}
        ).then((response) => {
            const updatedFiles = response.data.map(file => {
              console.log("load initial use eff");
                if(data[currentIndex].fileInfo!==null && data[currentIndex].fileInfo.fileKey == file.fileKey) {
                  const fileurl = BASE_URL+"/api/files/"+file._id+"/url";
                  axios.get(
                    fileurl, {headers:{'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}}
                ).then((response) => {
                  const table = response.data;
                  console.log("load initial use eff", file);
                  console.log(response);
                  console.log(table);
                  setInitialFileData(response.data);
                }).catch((error) => { });
                  
                }
                const folName = file.fileKey.includes('/') ? file.fileKey.substring(0, file.fileKey.lastIndexOf('/')) : "cf";
                return { ...file, folName }; // Add folderName to each file object
              });
              setFilesList(updatedFiles);
        }).catch((error) => { });
    }
      fetchFolders();
      fetchFiles();
      fetchTrashFiles();
      fetchTrashFolders();
    if (props.open && inputRef.current) {
        inputRef.current.select();
      //inputRef.current.focus();
    }
}, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const fetchFiles = async () => {
    const token = await getClerkToken();
    console.log(token)
    try {
      // Replace this with your actual folder listing API call
      return new Promise((resolve) => {
        setTimeout(() => {
          console.log("File Names List");
          const fileurl = BASE_URL+"/api/files";
          axios.get(
            fileurl, {headers:{'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}}
        ).then((response) => {
            const updatedFiles = response.data.map(file => {
                const folName = file.fileKey.includes('/') ? file.fileKey.substring(0, file.fileKey.lastIndexOf('/')) : "cf";
                return { ...file, folName }; // Add folderName to each file object
              });
              console.log("vbr");
              console.log(updatedFiles);
              setFilesList(updatedFiles);
              resolve(response.data);
              //return updatedFiles;
        }).catch((error) => { });
        }, 3000); // Simulate a delay
      });
    } catch (error) {
      console.error("Error fetching folders: ", error);
      return [];
    }

    
}

  const onChangeTvalue = async(newValue, changeState='') => {
    console.log("onChangeTvalue");
    console.log(newValue);
    console.log("initialFileData", initialFileData);
    console.log(data[currentIndex]);
    console.log(sessionStorage.getItem('isRefreshed'));
    
    //const isChanged = JSON.stringify(newValue) !== JSON.stringify(data[currentIndex].value);
    let isChanged = null;
    console.log("changeState", changeState);
    if(changeState=='' && sessionStorage.getItem('isRefreshed')) {
      if(data[currentIndex].fileInfo===null) {
        isChanged = !_.isEqual(newFileValue, data[currentIndex].value);
      } else {
          const token = await getClerkToken();
          const fileurl = BASE_URL+"/api/files/"+data[currentIndex].fileInfo._id+"/url";
          axios.get(
            fileurl, {headers:{'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}}
          ).then((response) => {
          const table = response.data;
          console.log("onchange load initial use eff",response);
          console.log(table);
          setInitialFileData(response.data);
          isChanged = !_.isEqual(response.data, data[currentIndex].value);
          console.log("is is si",isChanged);
          const newItem = {
            ...data[currentIndex],
            value: newValue,
            isSaved: isChanged
          }
          setData(data.map((item, index) =>
            index === currentIndex ? newItem : item
          ))
        }).catch((error) => { });
      }
    } else if(!changeState) {
      console.log("else if");
       isChanged = !_.isEqual(newValue, data[currentIndex].value);
       const newItem = {
        ...data[currentIndex],
        value: newValue,
        isSaved: isChanged
      }
      setData(data.map((item, index) =>
        index === currentIndex ? newItem : item
      ))
    } else {
      console.log("else");
      isChanged = changeState;
      const newItem = {
        ...data[currentIndex],
        value: newValue,
        isSaved: isChanged
      }
      setData(data.map((item, index) =>
        index === currentIndex ? newItem : item
      ))
    }
    
    console.log("isChanged", isChanged);
    
    console.log(data);
    setOpenFilesModal(false);
  }
  const loadFile = async (file) => {

  }
  const duplicateDownloadFile = async (file) => {
    const getIndex = fileKeyExists(data,file.fileKey);
    
    if(getIndex!==null) {
        console.log(getIndex)
        onSelect(getIndex);
        console.log("already exists");
    } else if(data.length >=12) {
        alert("You have reached the maximum number of files to open. Please close unused files.");
        return;
      } else {
        try {
            const token = await getClerkToken();
            console.log(token)
            const fileurl = BASE_URL+"/api/files/"+file._id+"/url";
            const response = await axios.get(encodeURI(fileurl), {headers:{'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}});
            const table = response.data;
            
            onFileValueChange(file, table);
        } catch (error) {
            console.log(error);
        }
    }
}
  const onFileValueChange = (file, newFileValu) => {
    console.log(file);
   
    setCurrentIndex(data.length);
    //const folName = file.fileKey.includes('/') ? file.fileKey.substring(0, file.fileKey.lastIndexOf('/')) : "cf";
    const folderId = file.folderId && file.folderId !== null ? file.folderId : '';
    console.log("folderId",folderId);
    setData([...data, { fileName: file.fileName, value: newFileValu, fileInfo: file, activeFolderName:folderId, isSaved: false }]);
    console.log("new fol name" + folderId );
    if(folderId != '') {
      setOldFolderName(folderId);
    }
    setOpenFilesModal(false);
  }

  const onFileValueReplace = (file, newFileValu, n) => {
    console.log(file);
   // setData([...data, { fileName: file.fileName, value: newFileValue, fileInfo: file }]);
   //const getIndex = data.findIndex(datum => datum.fileInfo._id === file._id);
   //const n = data.filter((item, index) => index !== currentIndex && index !== getIndex);
   //const nIndex = n.length+1;
   
    const folName = file.fileKey.includes('/') ? file.fileKey.substring(0, file.fileKey.lastIndexOf('/')) : "cf";
    setData([...n, { fileName: file.fileName, value: newFileValu, fileInfo: file, activeFolderName:folName, isSaved: false }]);
    // console.log("dataLength"+nIndex);
    setCurrentIndex(n.length);
    
    console.log("new fol name" + folName );
    if(folName != '') {
      setOldFolderName(folName);
    }
    setOpenFilesModal(false);
  }

  const onAddNewFile = () => {
    if (data.length >= 12) {
      alert("You have reached the maximum number of files to open. Please close unused files.");
      return;
    }
    console.log(data)
    const existingIndices = data
      .map(file => {
        if (file.fileInfo == null) {
          const match = file.fileName.match(/Untitled (\d+)/);
          return match ? parseInt(match[1], 10) : 0;
        }
      })
      .filter(index => !isNaN(index));
    // Find the highest index
    const maxIndex = existingIndices.length ? Math.max(...existingIndices) : 0;

    // Create a new file with an incremented index
    const newFileIndex = maxIndex + 1;

    setData([...data, { fileName: 'Untitled ' + newFileIndex, value: newFileValue, fileInfo: null, isSaved: false }]);
    setCurrentIndex(data.length);
  }
  const onOpenTable = () => {
    setOpenFilesModal(true);
  }

  const onDelete = (indexToRemove) => {
    /*if (data.length === 1) {
      setShowErroMessage(true);
    } else {
      setOpenDeleteConfirmation(true);
    }*/
    setOpenDeleteConfirmation(true);
  }
  function getNewFilename(fileName) {
    const copyPattern = /(.+?) copy(?: (\d+))?$/; // Regex to match "copy" and "copy N" patterns
    let match = fileName.match(copyPattern);
  
    if (match) {
      // If "copy" exists, increase the number (or start with 2 if it was just "copy")
      let baseName = match[1]; // the part before "copy"
      let copyNumber = match[2] ? parseInt(match[2], 10) + 1 : 2; // Increment copy number
      return `${baseName} copy ${copyNumber}`;
    } else {
      // If "copy" doesn't exist, simply add "copy"
      return `${fileName} copy`;
    }
  }
  const onDuplicate = async (indexToDuplicate) => {
    console.log(localStorage)
    const file = data[indexToDuplicate];
    //const newFileValue= file.value;
    
    const filename = getNewFilename(file.fileName);
    if(file.fileInfo!==null) {
      //const activeFolderName = file.fileInfo.fileKey.includes('/') ? file.fileInfo.fileKey.substring(0, file.fileInfo.fileKey.lastIndexOf('/')) : "cf";
      const folderId = file.fileInfo.folderId;
      const nf = { value:file.value, fileName: filename, parentId: folderId,fileInfo:[] };
      
      // setData([...data, { value:newFileValue, fileName: filename, activeFolderName:activeFolderName, fileInfo:[], isSaved: false }]);
      // setCurrentIndex(data.length);
      const token = await getClerkToken();
      const fileurl = BASE_URL + "/api/files";
      axios.post(
        fileurl, nf, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
        .then(res => {
          const fieleInfo = res.data;
          const nFile = {
            ...nf,
            fileInfo: fieleInfo,
          };
          console.log(nFile);
          duplicateDownloadFile(fieleInfo);
          // setData(data.map((item, index) =>
          //   index === currentIndex ? nFile : item
          // ));
          // setCurrentIndex(data.length);
          fetchFiles();
          toast('Saved changes');
        })
        .catch(err => {
          // setErrorTitle('API Error');
          // setErrorMessage('Oops! Something went wrong. Please try again or contact your administrator');
          // setShowErroMessage(true)
        })
    } else {
      const updatedFileValue = {
        ...newFileValue, // Keep the rest of the object the same
        events: [...[file.value.events]] // Update the events array with the new file's events
      };
      const newFileData = _.cloneDeep(file.value);
      setData([...data, { value:newFileData, fileName: filename, activeFolderName:"cf", fileInfo:null, isSaved: false }]);
      setTvalue(file.value);
      setCurrentIndex(data.length);
    }
  }

  const onRename = (index) => {
    console.log("renaming");
    console.log(data[index])
    if( data[index].fileInfo){
      const folName = data[index].fileInfo.fileKey.includes('/') ? data[index].fileInfo.fileKey.substring(0, data[index].fileInfo.fileKey.lastIndexOf('/')) : "cf";
      setOldFolderName(folName);
    }
    
    // if(data[index].activeFolderName) {
    //   setOldFolderName(folName);
    // } else {
    //   setOldFolderName("cf");
    // }
    
    setOpenRenameAlert(true);
  }
  

  const onSelect = (index) => {
    console.log(data);
    setCurrentIndex(index);
    console.log(data[index].value);
    //onChangeTvalue(data[index].value);
    setTvalue(data[index].value);
    if(data[index].fileInfo) {
      //const folName = data[index].fileInfo.fileKey.split('/')[0];
      //const folName = data[index].fileInfo.fileKey.split('/').length > 1 ? data[index].fileInfo.fileKey.split('/')[0] : 'cf';
      //const folName = data[index].fileInfo.fileKey && data[index].fileInfo.fileKey.includes('/') ? data[index].fileInfo.fileKey.substring(0, data[index].fileInfo.fileKey.lastIndexOf('/')) : "cf";
      const folderId = data[index].fileInfo.folderId ? data[index].fileInfo.folderId : '';
      console.log("folderId", folderId);
      setOldFolderName(folderId);
      // console.log("new fol name on sel" + folName );
      //   console.log(data[index]);
      //   if(folName != '') {
      //     setOldFolderName(folName);
      //   }
    }
    setOpenFilesModal(false);
  }

  const onSaveRename = (name) => {
    console.log("onSaveRename" + name);
    const newItem = {
      ...data[currentIndex],
      isSaved: false,
      fileName: name
    }
    console.log(newItem);
    setData(data.map((item, index) =>
      index === currentIndex ? newItem : item
    ))
    setOpenRenameAlert(false);
  }

  const onSaveRenameAll = (name) => {
    console.log(name);
    console.log("ranmae index" + currentFileIndex);
    const newItem = {
      ...data[currentFileIndex],
      fileName: name
    }
    console.log(newItem);
    setData(data.map((item, index) =>
      index === currentFileIndex ? newItem : item
    ))
    console.log("new data");
    console.log(data);
    setOpenRenameAlert(false);
    
  }

  const onAccept = async () => {
    setOpenDeleteConfirmation(false);
    setOpenCloseConfirmation(false);
    if (data[currentIndex].fileInfo && data[currentIndex].fileInfo._id) {
      await deleteRemoteFile(data[currentIndex].fileInfo._id)
    }
    deleteFile(currentIndex);
  }

  const onCloseAccept = async () => {
    setOpenDeleteConfirmation(false);
    setOpenCloseConfirmation(false);
    deleteFile(currentIndex);
  }

  const onCancel = () => {
    setOpenDeleteConfirmation(false);
  }
  const onCloseCancel = () => {
    setOpenCloseConfirmation(false);
  }

  const onCloseErrorAlert = () => {
    setShowErrorMessage(false);

  }
  const onCloseFAlert = () => {
    setShowCloseMessage(false);
  }
  const deleteFile = (indexToRemove) => {
    setData(data.filter((item, index) => index !== indexToRemove));
    if (indexToRemove !== 0) {
      setCurrentIndex(prevCount => prevCount - 1);
    }
  }

  const closeFile = (indexToClose) => {
    deleteFile(indexToClose);
  }
  const fileKeyExists = (data, key) => {
    const index = data.findIndex(item => item.fileInfo && item.fileInfo.fileKey === key);
    return index !== -1 ? index : null;
  };
  const downloadFile = async (file, n) => {
   // const getIndex = fileKeyExists(data[currentIndex],file.fileKey);
    const id = file._id
    
     if(data.length >=12) {
        alert("You have reached the maximum number of files to open. Please close unused files.");
        return;
      } else {
        try {
            const token = await getClerkToken();
            console.log(token)
            const fileurl = BASE_URL+"/api/files/"+id+"/url";
            const response = await axios.get(encodeURI(fileurl), {headers:{'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}});
            const table = response.data;
            console.log();
            const eventNames = {
                'Tax': 'TXIEventTypeTax',
                'Payment': 'TXIEventTypePayment',
                'Deposit as Bond': 'TXIEventTypeDeposit',
                'Suspend Interest': 'TXIEventTypeStopInterest',
                'Resume Interest': 'TXIEventTypeStartInterest',
                'IRS Check': 'TXIEventTypeIRSCheck',
                'Entered Penalty': 'TXIEventTypeUserPenalty',
                'Motivated Tax': 'TXIEventTypeMotivatedTax',
              };
              const eventDataArray = table.events;
              eventDataArray.forEach(eventData => {
                for (const key in eventNames) {
                  eventData.eventDate = moment(eventData.eventDate).format('MM/DD/YYYY')
                  if (eventNames.hasOwnProperty(key) && eventData.eventType === eventNames[key]) {
                    eventData.eventType = key;
                    break; // Stop loop if a match is found
                  }
                }
              });
              onFileValueReplace(file, table, n);
        } catch (error) {
            console.log(error);
        }
    }
}
  const onSave = (name = '', activeFolderName= '', saveAs=0, existingFile='', parentId=null) => {
    console.log(name);console.log(activeFolderName);console.log(saveAs);console.log(existingFile);console.log(parentId);
    const table = data[currentIndex];
    const updatedTable = {
      ...table,
      isSaved: false
    };
    const newData = [...data];
    newData[currentIndex] = updatedTable;
    //setData(newData);
    
    if(existingFile!='' && existingFile._id!='') {
      const id = existingFile._id;
      const { fileInfo, ...payload } = updatedTable;
      replaceFile(existingFile, payload, name, activeFolderName, parentId);
    }
    else if (saveAs == 0 && table.fileInfo) {
      const id = table.fileInfo._id;
      const { fileInfo, ...payload } = updatedTable;
      updateFile(id, payload, name, activeFolderName, parentId);
    } else {
      console.log(data);
      console.log(name);
      saveFile(table, name, activeFolderName, '' ,parentId);
    }
    setOpenCloseConfirmation(false);
  }
  const [unnamedFiles, setUnnamedFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);

  const [newName, setNewName] = useState('');
  
  const [dialogResolve, setDialogResolve] = useState(null);

  const onSaveAll = async (name = '', activeFolderName= '') => {
    let newData = [];
    for (const [index, datum] of data.entries()) {
        const table = datum;
        const updatedTable = {
            ...table,
            isSaved: false
        };
        newData[index] = updatedTable; // Use the loop index here
        //setData(newData);
        // setData(data.map((item, dindex) =>
        //   dindex === index ? updatedTable : newData[dindex]
        // ))
        
        if (table.fileInfo) {
            const id = table.fileInfo._id;
            const { fileInfo, ...payload } = table;
            const folderId = table.fileInfo.folderId;
            await updateFile(id, payload, table.name, activeFolderName, folderId); // Assume updateFile returns a Promise
        } else {
            await onRenameAll(index, activeFolderName); // Await onRename to complete
            console.log("tableindesx"+index);
            // saveFile(table, table.name); // If saveFile is async, make sure to await it as well
        }
    }
    console.log("newData", newData);
    setData(newData);
}

const onRenameAll = async (index, activeFolderName) => {  
  return new Promise((resolve) => {
    setUnnamedFiles((prevUnnamedFiles) => {
      setCurrentFileIndex(index);
      setCurrentFile(data[index]);
      setNewName(data[index].fileName)
      setIsDialogOpen(true);
      //setOpenRenameAlert(true);
      console.log("Dialog opened for index:", index);
      // Attach the resolve function to a ref or pass it down
      // to your dialog component to call after dialog interaction.
      return prevUnnamedFiles;
    });

    // Save the resolve function for later use in handleDialogSubmit
    setDialogResolve(() => resolve);
  });
};

const onCreateFolder = async (folPath, parentId, folderName) => {
  console.log("folderName"+folPath);
  console.log("parentId", parentId);
  const token = await getClerkToken();
  const fileurl = BASE_URL + "/api/files/createfolder";

  axios.post(
    fileurl, JSON.stringify({ folPath, parentId, folderName }), { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
    .then(res => {
      setOldFolderName(folPath);
      console.log(res);
      toast('Saved changes');
    })
    .catch(err => {
      console.log(err);
    })
};

const onUpdateFolder = async (oldFolderName, oldFolderId, newFolderName) => {
  console.log("folderName"+oldFolderName)
  const token = await getClerkToken();
  const fileurl = BASE_URL + "/api/files/updatefolder";

  axios.post(
    fileurl, JSON.stringify({ oldFolderName, oldFolderId, newFolderName }), { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
    .then(res => {
      console.log(res);
      toast('Saved changes');
    })
    .catch(err => {
      console.log(err);
    })
};

const onUpdateFile = async (oldFileName, id, newFileName) => {
  console.log("folderName"+oldFileName)
  const token = await getClerkToken();
  const fileurl = BASE_URL + "/api/files/updatefile";

  axios.post(
    fileurl, JSON.stringify({ oldFileName, id, newFileName }), { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
    .then(res => {
      console.log(res);
      toast('Saved changes');
    })
    .catch(err => {
      console.log(err);
    })
};

const onFolderDelete = async (folderId) => {
  console.log("onFolderDelete"+folderId)
  const token = await getClerkToken();
  const fileurl = BASE_URL + "/api/files/deletefolder";

  axios.post(
    fileurl, JSON.stringify({ folderId }), { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
    .then(res => {
      console.log(res);
      toast('Saved changes');
    })
    .catch(err => {
      console.log(err);
    })
};

const onFileDelete = async (fileId) => {
  console.log("onFolderDelete"+fileId)
  const token = await getClerkToken();
  const fileurl = BASE_URL + "/api/files/deletefile";

  axios.post(
    fileurl, JSON.stringify({ fileId }), { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
    .then(res => {
      console.log(res);
      toast('Saved changes');
    })
    .catch(err => {
      console.log(err);
    })
};

const onCreateSubFolder = async (folderName) => {
  console.log("folderName"+folderName)
  const token = await getClerkToken();
  const fileurl = BASE_URL + "/api/files/createsubfolder";

  axios.post(
    fileurl, JSON.stringify({ folderName }), { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
    .then(res => {
      console.log(res);
      toast('Saved changes');
    })
    .catch(err => {
      console.log(err);
    })
};

const onListFolders = async () => {
  const token = await getClerkToken();
  try {
    // Replace this with your actual folder listing API call
    return new Promise((resolve) => {
      setTimeout(() => {
        
        console.log("Folder Names List");
        const fileurl = BASE_URL + "/api/files/listfolders";
        axios.get(
          fileurl, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
          .then(res => {
            console.log(res.data);
            const foldersInfo = res.data;
            setFolderNames(foldersInfo);
            resolve(res.data);
      
          })
          .catch(err => {
            console.log("res err"+err);
      
            // setErrorTitle('API Error');
            // setErrorMessage('Oops! Something went wrong. Please try again or contact your administrator');
            // setShowErroMessage(true)
          })
      }, 1000); // Simulate a delay
    });
  } catch (error) {
    console.error("Error fetching folders: ", error);
    return [];
  }
};
const fetchTrashFolders = async () => {
  const token = await getClerkToken();
  try {
    // Replace this with your actual folder listing API call
    return new Promise((resolve) => {
      setTimeout(() => {
        
        console.log("Folder Names List");
        const fileurl = BASE_URL + "/api/files/listtrashfolders";
        axios.get(
          fileurl, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
          .then(res => {
            console.log(res.data);
            const foldersInfo = res.data;
            setTrashFolders(foldersInfo);
            resolve(res.data);
      
          })
          .catch(err => {
            console.log("res err"+err);
      
            // setErrorTitle('API Error');
            // setErrorMessage('Oops! Something went wrong. Please try again or contact your administrator');
            // setShowErroMessage(true)
          })
      }, 1000); // Simulate a delay
    });
  } catch (error) {
    console.error("Error fetching folders: ", error);
    return [];
  }
};
const fetchTrashFiles = async () => {
  console.log(" trash files ");
  const token = await getClerkToken();
  console.log(token)
  try {
    // Replace this with your actual folder listing API call
    return new Promise((resolve) => {
      setTimeout(() => {
        console.log("File Names List");
        const fileurl = BASE_URL+"/api/files/listtrashfiles";
        axios.get(
          fileurl, {headers:{'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}}
      ).then((response) => {
          const updatedFiles = response.data.map(file => {
              const folName = file.fileKey.includes('/') ? file.fileKey.substring(0, file.fileKey.lastIndexOf('/')) : "cf";
              return { ...file, folName }; // Add folderName to each file object
            });
            console.log(updatedFiles);
            setTrashFiles(updatedFiles);
            resolve(response.data);
            //return updatedFiles;
      }).catch((error) => { });
      }, 3000); // Simulate a delay
    });
  } catch (error) {
    console.error("Error fetching files: ", error);
    return [];
  }

  
}

const handleDialogSubmit = async (newName, activeFolderName='',saveAs=0, existingFile='', parentId=null) => {
  
  setNewName(newName)
  if (newName.trim() !== '') {
    const updatedFile = {
      ...currentFile,
      name: newName
    };

    console.log("updatedFileIndex:", currentFileIndex);
    console.log(data[currentFileIndex]);

    // Save the file after getting the new name
    await saveFileAll(updatedFile, newName, currentFileIndex, activeFolderName, parentId);

    // Close the dialog and prepare for the next file
    setIsDialogOpen(false);
    setOpenFilesModal(false);
    setUnnamedFiles((prev) => prev.slice(1)); // Remove the processed file

    if (unnamedFiles.length > 1) {
      setCurrentFile(unnamedFiles[1]); // Move to the next file
    } else {
      setCurrentFile(null);
    }

    setNewName('');

    // Resolve the promise after the rename process is completed
    if (dialogResolve) dialogResolve();
  }
};

const handleDialogClose = () => {
  setIsDialogOpen(false);
  setOpenFilesModal(false);
  setNewName('');
  // Resolve the promise even if the dialog is closed without renaming
  if (dialogResolve) dialogResolve();
};


  const onSaveClose = async () => {
    const table = data[currentIndex];
    if (table.fileInfo) {
      const id = table.fileInfo._id;
      const { fileInfo, ...payload } = table;
      const folderId = table.fileInfo.folderId;
            await updateFile(id, payload, table.name, '', folderId);
      //updateFile(id, payload);
      closeFile(currentIndex);
    } else {
      console.log(data);
      //setIsDialogOpen(true);
      //saveFileClose(table);
      onRename(currentIndex);
    }
    setOpenCloseConfirmation(false);
    console.log("closefile")

  }

  const saveFile = async (payload, name = '', activeFolderName='',duplicateIndex='', parentId=null) => {
    console.log(data);
    console.log(data.length);
    console.log(duplicateIndex);
    const loadIndex = duplicateIndex ? duplicateIndex : currentIndex;
    console.log(loadIndex);
    payload['parentId'] = parentId;
    if(duplicateIndex==''){
      if (name != '') {
        if(activeFolderName != ''){
          payload['activeFolderName'] = activeFolderName;
        }
        payload['fileName'] = name;
        onSaveRename(name);
      }
    }
    const token = await getClerkToken();
    const fileurl = BASE_URL + "/api/files";
    setLoading(true);
    axios.post(
      fileurl, payload, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
      .then(res => {
        const fieleInfo = res.data;
        const newFile = {
          ...data[loadIndex],
          fileInfo: fieleInfo,
          isSaved: false,
        };
        console.log(newFile);
        console.log(data);
        setData(data.map((item, index) =>
          index === loadIndex ? newFile : item
        ))
        fetchFiles();
        toast('Saved changes');
        setLoading(false);

      })
      .catch(err => {
        // setErrorTitle('API Error');
        // setErrorMessage('Oops! Something went wrong. Please try again or contact your administrator');
        // setShowErroMessage(true)
      })
  }

  const saveFileAll = async (payload, name = '', findex, activeFolderName,parentId=null) => {
    const token = await getClerkToken();
    if (name != '') {
      payload['fileName'] = name;
      payload['activeFolderName'] = activeFolderName;
      payload['parentId'] = parentId;
      onSaveRenameAll(name);
    }
    setLoading(true);
    const fileurl = BASE_URL + "/api/files";
    axios.post(
      fileurl, payload, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
      .then(res => {
        const fieleInfo = res.data;
        const newFile = {
          ...payload,
          fileInfo: fieleInfo,
          isSaved: false,
        };
        setData(data.map((item, index) =>
          index === findex ? newFile : item
        ))
        fetchFiles();
        setLoading(false);
        toast('Saved changes');
      })
      .catch(err => {
        // setErrorTitle('API Error');
        // setErrorMessage('Oops! Something went wrong. Please try again or contact your administrator');
        // setShowErroMessage(true)
      })
  }

  const saveFileClose = async (payload) => {
    const token = await getClerkToken();
    payload.parentId = null;
    const fileurl = BASE_URL+"/api/files";

    axios.post(
      fileurl, payload, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
      .then(res => {
        const fieleInfo = res.data;
        const newFile = {
          ...data[currentIndex],
          fileInfo: fieleInfo,
        };
        closeFile(currentIndex);
        fetchFiles();
        toast('Saved changes');

      })
      .catch(err => {
        // setErrorTitle('API Error');
        // setErrorMessage('Oops! Something went wrong. Please try again or contact your administrator');
        // setShowErroMessage(true)
      })
  }

  const updateFile = async (id, payload, name = '', activeFolderName='', parentId=null) => {
    const newItem = {
      ...data[currentIndex],
      isSaved: false
    }
    console.log(newItem);
    setData(data.map((item, index) =>
      index === currentIndex ? newItem : item
    ))
    console.log(payload, id);
    const token = await getClerkToken();
    if (name != '') {
      payload.fileName = name;
      payload.activeFolderName = activeFolderName;
      
      onSaveRename(name);
    }
    payload.parentId = parentId;
    // if(payload.activeFolderName==''){
    //   const payload.activeFolderName = data.fileKey.includes('/') ? file.fileKey.substring(0, file.fileKey.lastIndexOf('/')) : "cf";
    // }
    console.log("updating file pls2");
    console.log("activeFolderName"+activeFolderName);
    console.log("filename"+name);
    console.log("parentId"+parentId);
    const fileurl = BASE_URL+"/api/files/"+id;
    setLoading(true);
    axios.put(
      fileurl, payload, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
      .then(res => {
        const data = res.data;
        console.log(data);
        fetchFiles();
        toast('Updated changes');
        setLoading(false);
      })
      .catch(err => {
        // setErrorTitle('API Error');
        // setErrorMessage('Oops! Something went wrong. Please try again or contact your administrator');
        // setShowErroMessage(true)
      })
    console.log(payload, id);
  }

  const replaceFile = async (existingFile, payload, name = '', activeFolderName='', parentId=null) => {
    setOpenRenameAlert(false);

    const id = existingFile._id

    const getIndex = data.findIndex(datum => datum.fileInfo && datum.fileInfo._id === existingFile._id);
    
    const n = data.filter((item, index) => index !== currentIndex && index !== getIndex);
    console.log(n)
    //setData(n);
    // console.log(data[currentIndex].value);
    
    payload.fileName = name;
    payload.activeFolderName = activeFolderName;
    payload.parentId = parentId;
    //console.log(payload, id);
    const token = await getClerkToken();
    // if (name != '') {
    //   payload.fileName = name;
    //   payload.activeFolderName = activeFolderName;
    //   onSaveRename(name);
    // }
    console.log("updating file pls3");
    console.log("activeFolderName"+activeFolderName);
    console.log("filename"+name);
    const fileurl = BASE_URL+"/api/files/"+id;
    axios.put(
      fileurl, payload, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
      .then(res => {
        const fieleInfo = res.data;
        console.log(fieleInfo);
        fetchFiles();
        toast('Updated changes');
        // const newFile = {
        //   ...data[currentIndex],
        //   fileName: fieleInfo.fileName,
        //   fileInfo: fieleInfo,
        //   isSaved: true,
        // };
        // console.log(newFile);
        // setData(data.map((item, index) =>
        //   index === data[getIndex] ? newFile : item
        // ))

        // Update the currentIndex after data modification
        //setCurrentIndex(getIndex);
        
        downloadFile(existingFile, n );
        
      })
      .catch(err => {
        // setErrorTitle('API Error');
        // setErrorMessage('Oops! Something went wrong. Please try again or contact your administrator');
        // setShowErroMessage(true)
      })
    console.log(payload, id);
  }

  const deleteRemoteFile = async (id) => {
    const token = await getClerkToken();
    const fileurl = BASE_URL+"/api/files/"+id;
    const response = await axios.delete(
      fileurl, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } })
      fetchFiles();
    console.log(response);
  }

  const onClose = () => {
    if (data[currentIndex].isSaved === true) {
      setOpenCloseConfirmation(true);
    } else {
      closeFile(currentIndex);
    }
    //setOpenCloseConfirmation(true);
    //closeFile(currentIndex);
  }

  const openPenalties = () => {
    const taxRows = [];
    const events = data[currentIndex].value.events;
    events.forEach((rowNode) => {
      const rowData = rowNode
      const eventType = eventNames[`${rowData.eventType}`]
      if (eventType === 'TXIEventTypeTax' || eventType === 'TXIEventTypeMotivatedTax') {
        taxRows.push(rowData);
      }
    });
    if (taxRows.length === 1) {
      setOpenPenalty(true)
    } else {
      setErrorMessage('Penalties are not available either because there is no underpayment, or there are multiple tax due events');
      setShowErrorMessage(true)
    }
  }

  const onCompute = () => {
    const allRowDatum = [];
    const { events, state, penalties, user, hotInterest, cCorp } = data[currentIndex].value;
    let motivatedTaxAmount = 0;
    let taxAmount = 0;
    events.forEach((rowNode) => {
      const rowData = rowNode;
      const datum = {}
      datum.eventType = eventNames[`${rowData.eventType}`];
      if(rowData.eventType == "Tax") {
        taxAmount = rowData.eventAmount;
      }
      if(rowData.eventType == "Motivated Tax") {
        motivatedTaxAmount = rowData.eventAmount;
      }
      const utcDate = moment(new Date(rowData.eventDate)).format('yyyy-MM-DDThh:mm:ss');
      datum.eventDate = utcDate
      datum.eventDescription = rowData.eventDescription
      datum.eventAmount = rowData.eventAmount
      allRowDatum.push(datum)
    });
    
    if( (taxAmount==0 && motivatedTaxAmount==0 ) || (taxAmount=='' && motivatedTaxAmount=='')){
      setErrorMessage('please enter amount for tax');
      setShowErrorMessage(true);
      return;
    }
    if(hotInterest.enabled===false && taxAmount >= 100000){
      setErrorMessage('Caution: TaxInterest has detected an underpayment of more than $100,000 but no "Hot" interest.');
      setShowErrorMessage(true);
    }
    if(hotInterest.enabled===true && taxAmount < 100000){
      setErrorMessage("Caution: The 'Large corporate' or Section 6621(c) 'Hot' interest rate will be computed, but the deficiency is not over $100,000.\n \n Generally 'Hot' Interst is limited to deficiencies over $100,000");
      setShowErrorMessage(true);
    }
    const payload = {
      events: allRowDatum,
      state: state,
      penalties: penalties,
      user: user,
      hotInterest: hotInterest,
      cCorp: cCorp
    };
    const computeurl = BASE_URL+"/api/compute";
    axios.post(
      computeurl, payload)
      .then(res => {
        console.log(res.data);
        const data = res.data.data;
        const formattedData = data.report.replace(/\n/g, '<br>');
        const formattedDataVal = `<pre>${formattedData}</pre>`;
        const value = {
          ...data,
          report: formattedDataVal
        }
        setComputePayload(payload)
        setComputeData(value);
        setOpenComputeDialog(true);
      })
      .catch(err => {
        // setErrorTitle('API Error');
        setErrorMessage('Oops! Something went wrong. Please try again or contact your administrator');
        setShowErrorMessage(true)
      })
  }


  return (
    <div className='tvalue-contrainer'>
      <Topbar />
      <Sheets files={data} selectedIndex={currentIndex} onSelect={onSelect} onSaveAll={onSaveAll} onSave={onSave} onClose={onClose} onAddNewFile={onAddNewFile} onOpenTable={onOpenTable} onDelete={onDelete} onDuplicate={onDuplicate} onRename={onRename} hasChange={hasChange} />
      <div className="dashboard-container">
        
          <Sidebar onSelectedMenu={onSelectedMenu} />
        
        <div className="vr-line"></div>
        <div className="tax-grid-container">
          <div className="grid-container">
            <TaxInterestForm
              penaltyIndex={0}
              openPenalty={openPenalty}
              closePenalty={status => setOpenPenalty(status)}
              openHotInterest={openHotInterest}
              closeHotInterest={status => setOpenHotInterest(status)}
              openCCorp={openCCorp}
              closeCCorp={status => setOpenCCorp(status)}
              openStates={openStates}
              closeStates={status => setOpenStates(false)}
              tValue={((data[currentIndex] && data[currentIndex].value) || newFileValue)}
              onChangeTvalue={onChangeTvalue}
              fileIndex={currentIndex}
              onCompute={onCompute}
              filedata={data}
            />
          </div>
        </div>
        {/* {isDialogOpen && (
              <Dialog
              open={isDialogOpen}
              onClose={onCancel}
              PaperProps={{
                sx: {
                  borderRadius: "15px",
                  width: "30%"
                }
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
               <DialogTitle id="alert-dialog-title">
                <div className="title-section">
                    Enter a name for the file:
                </div>
              </DialogTitle>
                <DialogContent>
                <div className="dialog">
                    <CustomTextField 
                        value={newName} 
                        onChange={(e) => setNewName(e.target.value)} 
                        ref={inputRef} 
                        maxLength = "48"
                    />
                     <div className="action-buttons">
                      <button className='action-button cancel' onClick={handleDialogClose}>Cancel</button>
                      <button className='action-button save' onClick={handleDialogSubmit}>Save</button>
                    </div>
                </div>
                </DialogContent>
              </Dialog>
                
            )} */}
      </div>
      {
        isDialogOpen && <FileNameDialog title={'Save TaxInterest Document to TaxInterest Online Account'} data={data} oldData={data[currentFileIndex]} open={isDialogOpen} onListFolders={onListFolders} onSave={handleDialogSubmit} onCreateFolder={onCreateFolder} onFolderDelete={onFolderDelete} onFileDelete={onFileDelete} onUpdateFolder={onUpdateFolder} onUpdateFile={onUpdateFile} onCreateSubFolder={onCreateSubFolder} onCancel={handleDialogClose} folderNames ={folderNames} oldFolderName={oldFolderName}  fetchFiles={fetchFiles} filesList ={filesList} onSelect={onSelect} onFileValueChange={onFileValueChange} hideSave={false}/>
      }
      {
        openFilesModal && <FileNameDialog title={'Open TaxInterest Document from TaxInterest Online Account'} oldData={data[currentIndex]} data={data} open={openFilesModal} onListFolders={onListFolders} onSave={handleDialogSubmit} onCreateFolder={onCreateFolder} onFolderDelete={onFolderDelete} onFileDelete={onFileDelete} onUpdateFolder={onUpdateFolder} onUpdateFile={onUpdateFile} onCreateSubFolder={onCreateSubFolder} onCancel={handleDialogClose} folderNames ={folderNames} oldFolderName={oldFolderName}  fetchFiles={fetchFiles} filesList ={filesList} downloadFile={true} onSelect={onSelect} onFileValueChange={onFileValueChange} hideSave={true} trashFiles={trashFiles} trashFolders={trashFolders} fetchTrashFolders={fetchTrashFolders} fetchTrashFiles={fetchTrashFiles}/>
      }
      {/* {
        openFilesModal && <FilesModal onOpen={openFilesModal} oldData={data[currentFileIndex]} onClose={() => setOpenFilesModal(false)} data={data} onSelect={onSelect} onFileValueChange={onFileValueChange} onUpdateFolder={onUpdateFolder} onFolderDelete={onFolderDelete} onListFolders={onListFolders} folderNames ={folderNames} oldFolderName={oldFolderName} filesList ={filesList} fetchFiles={fetchFiles} />
      } */}
      {
        openRenameAlert && <FileNameDialog title={'Save TaxInterest Document to TaxInterest Online Account'} data={data} oldData={data[currentIndex]} open={openRenameAlert} onSave={onSave} onListFolders={onListFolders} onCreateFolder={onCreateFolder} onUpdateFolder={onUpdateFolder} onFolderDelete={onFolderDelete} onFileDelete={onFileDelete} onUpdateFile={onUpdateFile} onCreateSubFolder={onCreateSubFolder} onCancel={() => setOpenRenameAlert(false)} folderNames ={folderNames} oldFolderName={oldFolderName}  filesList ={filesList} fetchFiles={fetchFiles} onSelect={onSelect} onFileValueChange={onFileValueChange} hideSave={false} trashFiles={trashFiles} trashFolders={trashFolders} fetchTrashFolders={fetchTrashFolders} fetchTrashFiles={fetchTrashFiles}/>
      }

      {
        openDeleteConfirmation && <AlertDialogWithCancel title={'Delete Confirmation'} message={'Please confirm, do you want to delete this file?'} open={openDeleteConfirmation} onAccept={onAccept} onCancel={onCancel} />
      }
      {
        openCloseConfirmation && <AlertDialogWithClose title={'Close Confirmation'} message={'Changes will not be saved. Are you sure to close?'} open={openCloseConfirmation} onSave={onSaveClose} onAccept={onCloseAccept} onCancel={onCloseCancel} />
      }
      {
        showErrorMessage && <AlertDialog title={'Error'} message={errorMessage} open={showErrorMessage} onClose={onCloseErrorAlert} />
      }
      {
        showCloseMessage && <AlertDialog title={'Error'} message={'You couldn\'t close this file'} open={showCloseMessage} onSave={onSave} onClose={onCloseFAlert} />
      }

      {openComputeDialog && <Compute openComputeDialog={openComputeDialog} onCloseComputeDialog={() => setOpenComputeDialog(false)} value={computeData} payload={computePayload} />}
      {loading && <div className="loading-overlay"><div className="loading-spinner"></div></div>}
      <Toaster
        position="top-right"
        reverseOrder={false} />
    </div>

  )
}

export default TaxInterest