// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/select-icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.negligence-penanlty-form{
    display: flex;
    gap: 10px;
    flex-direction: column;
    font-family: 'Inter';
}

.left-container{
    display: flex;
    display: 100%;
    gap: 20px;
}

.penalty-activation-conatiner{
    height: 40px;
}
.due-date-container{
    height: 40px;
}

.net-tax-container{
    height: 40px;
}

.percentage-container{
    display: flex;
    justify-content: space-between;
}

.ok-button, .cancel-button, .show-tips{
    height: 50px;
}

.percentage-select{
    width: 60px;
    height: 30px;
    align-items: center;
    background-color: #fafafa;
    border-radius: 6px !important;
    border: 1px #E8E8E8 solid !important;
    appearance: none;
     background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
     background-position: calc(100% - 5px) center;
     background-repeat: no-repeat;
     padding-left: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/penalties/penalty6662g/Penalty6662G.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,6BAA6B;IAC7B,oCAAoC;IAInC,gBAAgB;KAChB,yDAAwD;KACxD,4CAA4C;KAC5C,4BAA4B;KAC5B,iBAAiB;AACtB","sourcesContent":[".negligence-penanlty-form{\n    display: flex;\n    gap: 10px;\n    flex-direction: column;\n    font-family: 'Inter';\n}\n\n.left-container{\n    display: flex;\n    display: 100%;\n    gap: 20px;\n}\n\n.penalty-activation-conatiner{\n    height: 40px;\n}\n.due-date-container{\n    height: 40px;\n}\n\n.net-tax-container{\n    height: 40px;\n}\n\n.percentage-container{\n    display: flex;\n    justify-content: space-between;\n}\n\n.ok-button, .cancel-button, .show-tips{\n    height: 50px;\n}\n\n.percentage-select{\n    width: 60px;\n    height: 30px;\n    align-items: center;\n    background-color: #fafafa;\n    border-radius: 6px !important;\n    border: 1px #E8E8E8 solid !important;\n    \n    -webkit-appearance: none;\n     -moz-appearance: none;\n     appearance: none;\n     background-image: url('../../../assets/select-icon.svg');\n     background-position: calc(100% - 5px) center;\n     background-repeat: no-repeat;\n     padding-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
