// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-text-feild-container{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width:49%
}

.custom_user_name{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width:40%;
}
.custom_user_id {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width:27%;
}

.label-text{
    font-size: 12px;
    font-weight: 400;
    font-family: 'Inter';
    color: #4B5257;
}

.custom-text-field{
    border-radius: 10px;
}

.text-field{
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter';
    color: #010C12;
    border-right: 1px solid ;
    background-color: #FAFAFA;
    height: 47px;
    border: none;
    border-radius: 10px;
    text-indent: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/TextField/CustomTextField.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR;AACJ;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,SAAS;AACb;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,cAAc;IACd,wBAAwB;IACxB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".custom-text-feild-container{\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    width:49%\n}\n\n.custom_user_name{\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    width:40%;\n}\n.custom_user_id {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    width:27%;\n}\n\n.label-text{\n    font-size: 12px;\n    font-weight: 400;\n    font-family: 'Inter';\n    color: #4B5257;\n}\n\n.custom-text-field{\n    border-radius: 10px;\n}\n\n.text-field{\n    font-size: 14px;\n    font-weight: 500;\n    font-family: 'Inter';\n    color: #010C12;\n    border-right: 1px solid ;\n    background-color: #FAFAFA;\n    height: 47px;\n    border: none;\n    border-radius: 10px;\n    text-indent: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
