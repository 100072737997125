import React from 'react'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';
import Collapsible from 'react-collapsible';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import './StateList.css'
const StatesList = (props) => {
    const onClickCancel = () => {
        props.onClose();
    }
    return (
        <div>
            <Dialog
                open={props.onOpen}
                maxWidth={'sm'}
                scroll={'paper'}
                onClose={onClickCancel}
                PaperProps={{
                    sx: {
                        width: "40%",
                        maxHeight: "50%",
                        borderRadius: "15px"
                    }
                }}>
                <DialogTitle sx={{ fontFamily: 'Inter', fontSize: "16px", fontWeight: 500, color: "#010C12" }}>Interest Rate Table Options</DialogTitle>
                <IconButton
                        aria-label="close"
                        onClick={props.onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                <DialogContent dividers={true}>
                    
                    <div className="states-list">
                    <div className="state-list">
                                    <div className="state-list-row row-header">
                                        <div className="state-name name">Tax Name</div>
                                        <div className="state-name rates" >Rates Current To</div>
                                        <div className="state-name tips" >Description</div>
                                    </div>
                                </div>
                        {
                            props.states.map((state, index) =>
                                <div className="state-list">
                                    <div className="state-list-row" onClick={() => props.onSubmit(state)}>
                                        <div className="state-name name" >{state.name}</div>
                                        <div className="state-name rates" >{state.rateCurrentsTo}</div>
                                        <div className="state-name tips" >{state.tips}</div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </DialogContent>

                {/* <DialogActions>
                    <Button onClick={props.onClose} sx={{ border: 1, width: 75, height: 30 }}>Close</Button>
                </DialogActions> */}
            </Dialog>


        </div>
    )
}

export default StatesList