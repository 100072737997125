import React from 'react';
import './Product.css';
import IconRightArrow from '../../assets/icon-arrow-right.svg';


const Product = (props) => {

    const product = props.value

    return (
        <div className="product-container" onClick={()=> props.onClick(product)}>
            <div className="product-header">
                <img className="header-image" src={product.logo} alt="Logo" />
            </div>
            <div className="product-content">
                <div className="text-container">
                    <div className="title">{product.title}</div>
                    <div className="description">{product.description}</div>
                </div>
               
            </div>
            <div className="open-tool-button">
                    <div className="button-text">Open</div>
                    <img src={IconRightArrow} />
                </div>
        </div>
    );
};

export default Product;
