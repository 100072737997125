// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.negligence-penanlty-form{
    display: flex;
    gap: 10px;
    flex-direction: column;
    font-family: 'Inter';
    font-size: 14px;
}

.left-container{
    display: flex;
    display: 100%;
    gap: 20px;
}

.penalty-activation-conatiner{
    height: 40px;
}
.due-date-container{
    height: 40px;
}

.net-tax-container{
    height: 40px;
}

.ok-button, .cancel-button, .show-tips{
    height: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/components/penalties/penalty6663/Penalty6663.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,sBAAsB;IACtB,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".negligence-penanlty-form{\n    display: flex;\n    gap: 10px;\n    flex-direction: column;\n    font-family: 'Inter';\n    font-size: 14px;\n}\n\n.left-container{\n    display: flex;\n    display: 100%;\n    gap: 20px;\n}\n\n.penalty-activation-conatiner{\n    height: 40px;\n}\n.due-date-container{\n    height: 40px;\n}\n\n.net-tax-container{\n    height: 40px;\n}\n\n.ok-button, .cancel-button, .show-tips{\n    height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
