import React from 'react'
import './Tips.css'
const Tips = (props) => {
    return (
        <div className='tips-container'>
            <div className="tips-text">
                <p>Here’s how TaxInterest uses this information. </p>
				<p className="tips-text-sub">This penalty is based on the amount of an underpayment that is due to a valuation understatement. </p>
				<p className="tips-text-sub">Interest on the penalty starts on the due date of the return (with extensions).</p>
				<p className="tips-text-sub">This penalty is one of the accuracy-related penalties. Amounts subject to this 
	penalty will not be subjected to other parts of the accuracy-related penalty.</p>
				<p className="tips-text-sub">There is a minimum tax amount required before the IRS can apply the penalty.
	The tax debt should reach $5,000 first, or else the IRS may revert to other kinds
	of penalties.</p>
			</div>
        </div>
    )
}

export default Tips