import React, { useState } from 'react'
import './Sidebar.css'
import NewIcon from '../../assets/plus.svg'
import OpenIcon from '../../assets/open-icon.svg'
import BalanceIcon from '../../assets/balance-icon.svg'
import PenaltiesIcon from '../../assets/penalties-icon.svg'
import InterestIcon from '../../assets/interest-icon.svg'
import CCorpIcon from '../../assets/c-corp-icon-new.svg'
import SupportIcon from '../../assets/support.svg'
import VideosIcon from '../../assets/videos.svg'
import HelpDocs from '../../assets/help-docs.svg'
import PoliciesIcon from '../../assets/policies.svg'
import LicensePolicy from '../../assets/license-policy.svg'
import OnlineHelpIcon from '../../assets/online-help.svg'
import PoliciesDotIcon from '../../assets/policies-dot.svg'
import PrivacyPolicyIcon from '../../assets/privacy-policy-icon.svg'
import PrivacyRuleIcon from '../../assets/policy-rule.svg'
import ArrowIcon from '../../assets/menu-arrow.svg'
import WebIcon from '../../assets/web.svg'
import PhoneIcon from '../../assets/phone.svg'
import MailIcon from '../../assets/mail.svg'
import { Popover } from '@mui/material';
import PopoverMenuItem from '../PopoverMenuItem/PopoverMenuItem'; 

const Sidebar = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activePopover, setActivePopover] = useState(null);
  const open = Boolean(anchorEl);
  const open1 = activePopover === 'popover0';
  const open2 = activePopover === 'popover1';
  const open3 = activePopover === 'popover2';
  const id = open ? 'popover1' : undefined;
  const handleClick = (event, popoverid) => {
    console.log(popoverid);
    setAnchorEl(event.currentTarget);
    setActivePopover("popover"+popoverid);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setActivePopover(null);
  };
  const sidemenu = [
    {
      name: "New",
      icon: NewIcon
    },
    {
      name: "Open",
      icon: OpenIcon
    },
    {
      name: "Balance",
      icon: BalanceIcon
    },
    {
      name: "Penalties",
      icon: PenaltiesIcon
    },
    {
      name: "Hot Interest",
      icon: InterestIcon
    },
    {
      name: "C Corp Refund",
      icon: CCorpIcon
    }
  ]

  const sidemenubottom = [
    {
      name: "Support",
      icon: SupportIcon
    },
    {
      name: "Online Help",
      icon: OnlineHelpIcon
    },
    {
      name: "Policies",
      icon: PrivacyRuleIcon
    }
  ]

  return (
    <div className='sidebar-container'>
      <div className="sidebar-menu-list">
      {
        sidemenu.map((item, index) => {
          return (
            <div className={`sidebar-menu`} key={index} onClick={()=>  props.onSelectedMenu(index)}>
              <img className={`sidebar-menu-icon`}  src={item.icon} alt={item.name} color='white' />
              <span className= {`sidebar-menu-name`} > {item.name}</span>
            </div>
          )
        })
      }
       </div>
       <div className="sidebar-menu-list-bottom">
        {
          sidemenubottom.map((item, index) => {
            return (
              <div className={`sidebar-menu-bottom`} key={index} onClick={(event) => handleClick(event, index)}>
                <img className={`sidebar-menu-icon`}  src={item.icon} alt={item.name} color='white' />
                <span className= {`sidebar-menu-name`} > {item.name}</span>
                <span className= {`sidebar-menu-arrow`} ><img className={`sidebar-menu-icon`}  src={ArrowIcon} alt="Menu Dropdown" /></span>
              </div>
            )
          })
        }
        </div>
        <Popover
        id="popover0"
        open={open1}
        elevation={0}
        slotProps={{
          paper: {
             sx:{
              width: '230px',
              borderRadius: '15px',
              marginLeft: '5px',
              boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.05)'
             }
          }
      }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'left',
          horizontal: 'right',
        }}
      >

        <div className="sheet-pop-over-menu">
          <a href="tel:+18004264741">
          <PopoverMenuItem icon={PhoneIcon} title={'800-426-4741'} onClick= {handleClose}/>
          </a>
          <a href="mailto:support@timevalue.com">
            <PopoverMenuItem icon={MailIcon} title={'support@TimeValue.com'} onClick= {handleClose}/>
          </a>
        </div>
        {/* <Typography className="settings-menu-item" onClick={handleOptionClick} sx={{ p: 1.5 }}>Rename</Typography>
        <Typography className="settings-menu-item" onClick={handleOptionClick} sx={{ p: 1.5 }}>Close</Typography> */}
      </Popover>
      <Popover
        id="popover1"
        open={open2}
        elevation={0}
        slotProps={{
          paper: {
             sx:{
              width: '210px',
              borderRadius: '15px',
              marginLeft: '5px',
              boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.05)'
             }
          }
      }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'left',
          horizontal: 'right',
        }}
      >

        <div className="sheet-pop-over-menu">
          <a href="https://www.timevalue.com/category-training-video/taxinterest" target="_blank" rel="noopener noreferrer">
            <PopoverMenuItem icon={VideosIcon} title={'Training Videos'} onClick= {handleClose}/>
          </a>
          <a href="https://www.timevalue.com/sites/default/files/product-download/TaxInterest-Users-Guide.pdf" target="_blank" rel="noopener noreferrer">
             <PopoverMenuItem icon={HelpDocs} title={'Help Docs'} onClick= {handleClose}/>
          </a>
        </div>
        {/* <Typography className="settings-menu-item" onClick={handleOptionClick} sx={{ p: 1.5 }}>Rename</Typography>
        <Typography className="settings-menu-item" onClick={handleOptionClick} sx={{ p: 1.5 }}>Close</Typography> */}
      </Popover>
      <Popover
        id="popover2"
        open={open3}
        elevation={0}
        slotProps={{
          paper: {
             sx:{
              width: '175px',
              borderRadius: '15px',
              marginLeft: '5px',
              boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.05)'
             }
          }
      }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'left',
          horizontal: 'right',
        }}
      >

        <div className="sheet-pop-over-menu">
          <a href="https://www.timevalue.com/privacy-statement" target="_blank" rel="noopener noreferrer">
             <PopoverMenuItem icon={PrivacyPolicyIcon} title={'Privacy Policy'} onClick= {handleClose}/>
          </a>
          <a href="https://www.timevalue.com/terms-of-use" target="_blank" rel="noopener noreferrer">
             <PopoverMenuItem icon={PoliciesIcon} title={'Terms of Use'} onClick= {handleClose}/>
          </a>
          <a href="https://www.timevalue.com/license-policy" target="_blank" rel="noopener noreferrer">
            <PopoverMenuItem icon={LicensePolicy} title={'License Policy'} onClick= {handleClose}/>
          </a>
          
        </div>
        {/* <Typography className="settings-menu-item" onClick={handleOptionClick} sx={{ p: 1.5 }}>Rename</Typography>
        <Typography className="settings-menu-item" onClick={handleOptionClick} sx={{ p: 1.5 }}>Close</Typography> */}
      </Popover>
    </div>
  )
}

export default Sidebar