import React from 'react'
import './Tips.css'
import PenaltyLabel from '../../Label/PenaltyLabel';
const Tips = (props) => {
    return (
        <div className='tips-container'>
            <div className="tips-text">
                <p>Here’s how TaxInterest uses this information. </p>
				<p className="tips-text-sub">The penalty runs from the return due date until the IRS receives the return.</p>
				<p className="tips-text-sub">Generally, the penalty is 5% per month up to a maximum of 25%. If fraudulent, the penalty is 15% per month, up to a maximum of 75%</p>
				<div className="tips-text-sub">For income tax returns filed more than 60 days late, the minimum penalty is the smaller of 100% of the tax, and
				<div className='sub-text'>$450 for returns due on or after 1/1/2023,<br/>
				$435 for returns due between 1/1/2020 and 12/31/2022,<br/>
				$210 for returns due between 1/1/2018 and 12/31/2019,<br/>
				$205 for returns due between 1/1/2016 and 12/31/2017,<br/>
				$135 for returns due between 1/1/2009 and 12/31/2015, or<br/>
				$100 for returns due before 1/1/2009</div>
				</div>
            </div>
        </div>
    )
}

export default Tips