// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popover-menu-item{
    display: flex;
    color: #010C12; 
    font-size: 14px; 
    font-family: 'Inter'; 
    font-weight: 500;
    height: 40px;
    width: 160px;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.popover-menu-item:hover{
    /* background-color: #E9EFF2;
    border-radius: 5px; */
    color: #274F89;
}

.popover-menu-item img{
    padding-left: 5px;
    height: 18px;
    width: 18px;
}

`, "",{"version":3,"sources":["webpack://./src/components/PopoverMenuItem/PopoverMenuItem.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,eAAe;IACf,oBAAoB;IACpB,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,SAAS;IACT,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI;yBACqB;IACrB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,WAAW;AACf","sourcesContent":[".popover-menu-item{\n    display: flex;\n    color: #010C12; \n    font-size: 14px; \n    font-family: 'Inter'; \n    font-weight: 500;\n    height: 40px;\n    width: 160px;\n    gap: 10px;\n    align-items: center;\n    cursor: pointer;\n}\n\n.popover-menu-item:hover{\n    /* background-color: #E9EFF2;\n    border-radius: 5px; */\n    color: #274F89;\n}\n\n.popover-menu-item img{\n    padding-left: 5px;\n    height: 18px;\n    width: 18px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
