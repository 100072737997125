// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.state-name{
    font-family: 'Inter';
    font-size: 14px;
    color: #010C12;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 5px;
}
.state-list:hover{
    color: white;
    background-color: #FAFAFA;
    border-radius: 3px;
}
.table-name{
    cursor: pointer;
    padding-left: 20px;
}
.state-list-row {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    cursor: pointer;
}
.state-list-row.row-header {
    cursor: default;
}
.row-header .state-name{
    font-weight: 600;
}
.state-list-row .name {
    flex: 3 1;
}
.state-list-row .rates {
    flex: 3 1;
}
.state-list-row .tips {
    flex: 3 1;
}`, "",{"version":3,"sources":["webpack://./src/components/StateList.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;IACpB,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,SAAS;AACb;AACA;IACI,SAAS;AACb;AACA;IACI,SAAS;AACb","sourcesContent":[".state-name{\n    font-family: 'Inter';\n    font-size: 14px;\n    color: #010C12;\n    font-weight: 500;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    margin-left: 5px;\n}\n.state-list:hover{\n    color: white;\n    background-color: #FAFAFA;\n    border-radius: 3px;\n}\n.table-name{\n    cursor: pointer;\n    padding-left: 20px;\n}\n.state-list-row {\n    display: flex;\n    justify-content: space-between; \n    align-items: center;\n    cursor: pointer;\n}\n.state-list-row.row-header {\n    cursor: default;\n}\n.row-header .state-name{\n    font-weight: 600;\n}\n.state-list-row .name {\n    flex: 3 1;\n}\n.state-list-row .rates {\n    flex: 3 1;\n}\n.state-list-row .tips {\n    flex: 3 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
