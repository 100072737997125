// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.normal{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    color: #010C12;
}

.normal.disabled{
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    color: #010C12;
    opacity: 0.5;
}

.header{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    color: #010C12;
}

.header.disabled{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    color: #010C12;
    opacity: 0.5;
}

.medium{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    color: #010C12;
}

.medium.disabled{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    color: #010C12;
    opacity: 0.5;
}

`, "",{"version":3,"sources":["webpack://./src/components/Label/PenaltyLabel.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,YAAY;AAChB","sourcesContent":[".normal{\n    font-family: 'Inter';\n    font-size: 14px;\n    font-weight: 400;\n    color: #010C12;\n}\n\n.normal.disabled{\n    font-family: 'Inter';\n    font-size: 14px;\n    font-weight: 400;\n    color: #010C12;\n    opacity: 0.5;\n}\n\n.header{\n    font-family: 'Inter';\n    font-size: 16px;\n    font-weight: 500;\n    color: #010C12;\n}\n\n.header.disabled{\n    font-family: 'Inter';\n    font-size: 16px;\n    font-weight: 500;\n    color: #010C12;\n    opacity: 0.5;\n}\n\n.medium{\n    font-family: 'Inter';\n    font-size: 12px;\n    font-weight: 500;\n    color: #010C12;\n}\n\n.medium.disabled{\n    font-family: 'Inter';\n    font-size: 12px;\n    font-weight: 500;\n    color: #010C12;\n    opacity: 0.5;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
