// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#clerk-forms input, #clerk-forms select, #clerk-forms checkbox {
    border: 1px solid #e8e8e8;
    padding: 10px;
    margin-top:10px;
    margin-bottom: 10px;
    margin-left:10px;
    width: 25%;
}
#clerk-forms {
    margin-left: 30px;
}
.sample-chec {
    width:20px;
    padding-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/ClerkForms/ClerkForms.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,UAAU;AACd;AACA;IACI,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,mBAAmB;AACvB","sourcesContent":["#clerk-forms input, #clerk-forms select, #clerk-forms checkbox {\n    border: 1px solid #e8e8e8;\n    padding: 10px;\n    margin-top:10px;\n    margin-bottom: 10px;\n    margin-left:10px;\n    width: 25%;\n}\n#clerk-forms {\n    margin-left: 30px;\n}\n.sample-chec {\n    width:20px;\n    padding-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
