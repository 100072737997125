import React, { useState, useRef, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PenaltyCloseIcon from '../../assets/penalty-close-icon.svg';
import CustomTextField from '../TextField/CustomTextField';

export default function FileNameAllDialog(props) {
  const { handleDialogSubmit, handleDialogClose, oldName } = props;
  const [newName, setNewName] = useState(oldName);
  const inputRef = useRef(null);
  
  useEffect(() => {
    const timeout = setTimeout(() => {
    if (props.open && inputRef.current) {
        inputRef.current.select();
      //inputRef.current.focus();
      
    }
}, 0);
    return () => clearTimeout(timeout);
  }, [props.open]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      console.log("enter here");
      if (e.key === 'Enter') {
        e.preventDefault(); // Prevent default behavior for Enter key
        handleDialogSubmit(newName);
      }
    };

    if (props.open && inputRef.current) {
      inputRef.current.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [props.open, newName, handleDialogSubmit]);
    
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={handleDialogClose}
        PaperProps={{
          sx: {
            borderRadius: "15px",
            width: "30%"
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="title-section">
            <span style={{ fontFamily: 'Inter', fontSize: 20, fontWeight: 500, display: 'flex', justifyContent: 'space-between' }}>
              {props.title}
            </span>
            <img className='penalty-close-icon' onClick={handleDialogClose} width={30} height={30} src={PenaltyCloseIcon} alt="close-icon" />
          </div>
        </DialogTitle>
        <DialogContent>
          <CustomTextField
            label={"Name: "}
            value={newName}
            placeholder={"Type here"}
            onChange={(e) => { setNewName(e.target.value) }}
            ref={inputRef}  // Pass the ref to CustomTextField
            maxLength = "48"
          />
          <div className="action-buttons">
            <button className='action-button cancel' type="button" onClick={handleDialogClose}>Cancel</button>
            <button className='action-button save' type="button" onClick={() => handleDialogSubmit(newName)}>Save</button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
