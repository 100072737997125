import React from 'react'
import './Sheets.css'
import Sheet from './Sheet'
import { useEffect } from 'react'
const Sheets = (props) => {
  
 const {onSave, onSaveAll, onClose,files, onAddNewFile, onOpenTable, onDelete, onDuplicate, onRename, onSelect, selectedIndex, hasChange} = props;
console.log("sheets props", props);

  return (
    <div className='sheet-bar-container'>
      <div className="sheets-container">
        {
            files.map((file, index) => <Sheet index = {index} onSelect = {onSelect} onAddNewFile={onAddNewFile} selectedIndex = {selectedIndex} name={file.fileName} fileinfo={file.fileInfo} filesaveinfo={file.isSaved} onSave = {onSave} onClose = {onClose} onDelete = {(index)=>{onDelete(index)}} onDuplicate = {(index)=>{onDuplicate(index)}} onRename ={(index)=>onRename(index)} hasChange={hasChange}/> )
        }
      </div>
      <div className='save-all'><button className='action-button save secondary' onClick={onSaveAll}>Save All</button></div>
    </div>
  )
}

export default Sheets