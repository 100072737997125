import React, { useState, useEffect } from 'react'
import '../TaxInterest/TaxInterest.css'
import Topbar from '../Topbar/Topbar'
import Sidebar from '../Sidebar/UserSidebar'
import UserSidebar from '../Sidebar/UserSidebar'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast';
import useClerk from '../../hooks/userClerk'
import CreateOrganization from '../ClerkForms/CreateOrganization';
import CreateUser from '../ClerkForms/CreateUser';
import UserList from '../ClerkForms/UserList';
import { UserProfile, useSession } from "@clerk/clerk-react";
import ErrorBoundary from '../ErrorBoundary'
const UserSettings = (props) => {
  const [showErroMessage, setShowErroMessage] = useState(false);
  const [showCloseMessage, setShowCloseMessage] = useState(false);
  const [showCreateOrganization, setShowCreateOrganization] = useState(false);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [showUserList, setShowUserList] = useState(true);
  const [showLicense, setShowLicense] = useState(false);
  const [showDefaults, setShowDefaults] = useState(false);
  
  const {getClerkToken} = useClerk();
  const { isLoaded, session, isSignedIn } = useSession();
  console.log("session from");
  console.log(session);
  const onSelectedMenu = (index) => {
    switch (index) {
      case 0:
        setShowCreateOrganization(false);
        setShowUserList(true);
        setShowCreateUser(false);
        setShowLicense(false);
        setShowDefaults(false);
        break
      case 1:
        setShowCreateOrganization(false);
        setShowUserList(false);
        setShowCreateUser(true);
        setShowLicense(false);
        setShowDefaults(false);
        break
      case 2:
        setShowCreateOrganization(false);
        setShowCreateUser(false);
        setShowUserList(false);
        setShowLicense(true);
        setShowDefaults(false);
        break
      case 3:
        setShowCreateOrganization(false);
        setShowCreateUser(false);
        setShowUserList(false);
        setShowLicense(false);
        setShowDefaults(true);
        break
      default:
        break;
    }
  }


  return (
    <div className='tvalue-contrainer'>
      <div className="dashboard-container">
        <div className="sidebar-container">
          <UserSidebar onSelectedMenu={onSelectedMenu} />
        </div>
        <div className="vr-line-user"></div>
        <div className="user-container">
          <div className="grid-container">
          <ErrorBoundary>
          {showCreateOrganization && <CreateOrganization />}
          {showCreateUser && <CreateUser />}  
          {showUserList && <UserList />}   
          {showLicense && <div>Number of License : 50</div>}   
          {showDefaults && <div><input className='sample-chec' type="checkbox" value="Disable cloud storage. Users will not be allowed to save TValue files into their TValue Online account." />Disable cloud storage. Users will not be allowed to save TValue files into their TValue Online account.</div>}   
          </ErrorBoundary>
          </div>
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false} />
    </div>

  )
}

export default UserSettings