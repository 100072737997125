import React, { useEffect, useState } from 'react'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';

const LoadTablePicker = (props) => {

    const [files, setFiles] = useState([]);

    useEffect(() => {
        const fetchFiles = async () => {
            // try {
            //     const response = await fetch('http://localhost:4002/api/files');
            //     const data = await response.json();
            //     setFiles(data.files);
            // } catch (error) {
            //     console.error('Error fetching files:', error);
            // }
        };

        fetchFiles();
    }, []);


    return (
        <div>
            <Dialog
                open={props.open}
                maxWidth={'sm'}
                scroll={'paper'}
                PaperProps={{
                    sx: {
                        width: "50%",
                        maxHeight: "80%",
                        minHeight: "80%"
                    }
                }}>

                <DialogContent dividers={true}>
                    <div>
                        <h2>File List</h2>
                        <ul>
                            {files.map((file, index) => (
                                <li key={index}>{file}</li>
                            ))}
                        </ul>
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button sx={{ border: 1, width: 75, height: 30 }}>Edit</Button>
                    <Button onClick={props.onClose} sx={{ border: 1, width: 75, height: 30 }}>Close</Button>
                </DialogActions>
            </Dialog>


        </div>
    )
}

export default LoadTablePicker