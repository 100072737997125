import React, { useEffect } from "react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "./FormComponent.css";
import {
  SignedIn,
  SignedOut,
  SignIn,
  SignUp,
  useSession,
  useUser,
  UserProfile,
} from "@clerk/clerk-react";
import "./App.css";
import { useLocation, useNavigate } from "react-router-dom";
import Products from "./components/Products/Products";
import { createContext, useContext } from "react";
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component
const UserContext = createContext(null);
// Import your publishable key
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const signInAppearance = {
  elements: {
    card: {
      signUpLink: {
        display: "none",
      },
    },
  },
};

const App = () => {
  // useEffect(() => {
  //   // Set the document title here
  //   document.title = 'TaxInterest Web Beta';
  // }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const signUpUrl = process.env.REACT_APP_CLERK_SIGNUP_URL;
  const isSignUp = location.pathname === "/" + signUpUrl;

  return (
    <div className="app">
      <SignedOut>
        <div className="signin-container">
          {isSignUp ? (
            <SignUp signInUrl="/" />
          ) : (
            <SignIn
              signUpUrl={signUpUrl}
              appearance={signInAppearance}
              needs_second_factor
            />
          )}
        </div>
      </SignedOut>
      <SignedIn>
      <ProtectedRoute>
        <Products />
        </ProtectedRoute>
      </SignedIn>
    </div>
  );
};

export default App;
