// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sheet-container{
    display: flex;
    gap: 10px;
    border-radius: 6px;
    border: 1px #E8E8E8 solid;
    align-items: center;
    color: #010C12;
    font-weight: 500;
    font-size: 14px;
    font-style: normal;
    cursor: pointer;
    flex-wrap: nowrap;
    word-wrap: none;
    white-space: nowrap;
}

.sheet-container.selected{
    border: 1px #534F86 solid;
    background: #534F86;
    color: #fff;
}
.sheet-container:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sheet-holder{
    display: flex;
    gap: 10px;
    padding: 5px;
}
.sheet-pop-over-menu{
   width: 100%;
   padding: 12px;
}

/* .MuiPopover-paper{
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05) !important;
    border-radius: 15px !important;
    margin: 0px ;
    width: 182px;
    height: 148px; 
} */

.sheet-name{
    white-space: nowrap;
}
span{
    white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/Sheets/Sheet.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,SAAS;IACT,YAAY;AAChB;AACA;GACG,WAAW;GACX,aAAa;AAChB;;AAEA;;;;;;GAMG;;AAEH;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".sheet-container{\n    display: flex;\n    gap: 10px;\n    border-radius: 6px;\n    border: 1px #E8E8E8 solid;\n    align-items: center;\n    color: #010C12;\n    font-weight: 500;\n    font-size: 14px;\n    font-style: normal;\n    cursor: pointer;\n    flex-wrap: nowrap;\n    word-wrap: none;\n    white-space: nowrap;\n}\n\n.sheet-container.selected{\n    border: 1px #534F86 solid;\n    background: #534F86;\n    color: #fff;\n}\n.sheet-container:hover {\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n\n.sheet-holder{\n    display: flex;\n    gap: 10px;\n    padding: 5px;\n}\n.sheet-pop-over-menu{\n   width: 100%;\n   padding: 12px;\n}\n\n/* .MuiPopover-paper{\n    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05) !important;\n    border-radius: 15px !important;\n    margin: 0px ;\n    width: 182px;\n    height: 148px; \n} */\n\n.sheet-name{\n    white-space: nowrap;\n}\nspan{\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
