import React, {
    Component,
    forwardRef,
    useState,
    useRef,
    useEffect,
    useImperativeHandle
  } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

 const MaterialSelect = forwardRef((props, ref) => {
  const { onValueChange, api, column, node, rowIndex } = props;
  console.log("MaterialSelect", props);
  const [value, setValue] = React.useState(props.value);
    const selectRef = useRef(null);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      }
    };
  });
  useEffect(() => {

    const handleKeyPress = (event) => {
      console.log("hello" );
      let checkeventval = event.srcElement.getAttribute('id');
      if(checkeventval=="demo-simple-select-standard") {
        let eventVal = ""
      if (event.key === 's' || event.key === 'S') {
        eventVal = "Suspend Interest";
      }
      if (event.key === 't' || event.key === 'T') {
        eventVal = "Tax";
      }
      if (event.key === 'p' || event.key === 'P') {
        eventVal = "Payment";
      }
      if (event.key === 'd' || event.key === 'D') {
        eventVal = "Deposit as Bond";
      }
      if (event.key === 'r' || event.key === 'R') {
        eventVal = "Resume Interest";
      }
      if (event.key === 'i' || event.key === 'I') {
        eventVal = "IRS Check";
      }
      if (event.key === 'e' || event.key === 'E') {
        eventVal = "Entered Penalty";
      }
      if (event.key === 'm' || event.key === 'M') {
        eventVal = "Motivated Tax";
      }
      if(eventVal!='') {
        setValue(eventVal);
      }
      if (event.key === 'Enter') {
        console.log("hello");
      }
      }
      
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  });
  const handleBlur = (event) => {
console.log("testing");
setTimeout(() => {
  api.startEditingCell({
    rowIndex: rowIndex,
    colKey: "eventDate",
  });
});
  };
  const handleChange = (event) => {
    console.log(event);
    console.log("handle change",event.key);
    setValue(event.target.value);
    //api.stopEditing();
    setTimeout(() => {
    api.startEditingCell({
      rowIndex: rowIndex,
      colKey: "eventDate",
    });
  });
  };
  const handleKeyDown = (event) => {
    console.log("event.key",event.key);
    if (event.key === 'Enter') {
      // Move to the next cell
      api.stopEditing(); // End editing on the current cell
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
    <Select
    ref={selectRef}
    autoFocus = {true}
    labelId="demo-simple-select-standard-label"
    id="demo-simple-select-standard"
    fullWidth
    sx={{ padding: '10px 2px', height: '41px', letterSpacing:0, lineHeight:'1.4375em', fontSize:'14px', fontFamily: 'Inter', fontWeight: '500', backgroundColor: 'white', outline: 'none', border: 'none', borderColor:'transparent', outlineColor: 'red'}}
    value={value}
    onBlur={handleBlur}
    onChange={handleChange}
     onKeyDown={handleKeyDown}
  >

    {props.values.map((e, index)=> <MenuItem sx={{fontFamily: 'Inter', fontSize:'14px',fontWeight: '500',}} key={e} value={e}>{e}</MenuItem>)}

  </Select>
  </div>
  );
});

export default MaterialSelect;
