import React, { forwardRef } from 'react';
import './CustomTextField.css';

const CustomTextField = forwardRef((props, ref) => {
  const inputId = props.id || `text-field`;
  const { onTabPress } = props;
  const handleKeyDown = (event) => {
    if (event.key === 'Tab' && !event.shiftKey && onTabPress) {
      onTabPress(event);
    }
  };
  return (
    <div className={'custom-text-field-container custom_' + inputId}>
      <div className="label-text">
        {props.label}
      </div>
      <div className="custom-text-field">
        <input
          ref={ref}  // Apply the ref here
          value={props.value}
          type="text"
          className='text-field'
          name="text-field"
          id={inputId}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onKeyDown={handleKeyDown}
          maxLength={props.maxLength !== undefined ? props.maxLength : 255}
        />
      </div>
    </div>
  );
});

export default CustomTextField;
