import React from 'react'

import './PopoverMenuItem.css'

const PopoverMenuItem = (props) => {
    const { title, icon, onClick } = props;
     
  return (
    <div className='popover-menu-item'  onClick={onClick}>
        <img src={icon} alt="icon" />
        <span className='pop-over-menu-item-title'>{title}</span>
    </div>
  )
}

export default PopoverMenuItem