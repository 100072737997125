import React from 'react'
import './Tips.css'
const Tips = (props) => {
    return (
        <div className='tips-container'>
            <div className="tips-text">
                <p>Here’s how TaxInterest uses this information. </p>
				<p>The penalty runs from the return due date until the IRS receives the return.</p>
				<p>Generally, the penalty is 5% per month up to a maximum of 25%. If fraudulent,</p>
				<p>the penalty is 15% per month, up to a maximum of 75%</p>
				<p>For income tax returns filed more than 60 days late, the minimum penalty is the</p>
				<p>smaller of 100% of the tax, and</p>
				<p>$450 for returns due on or after 1/1/2023,</p>
				<p>$435 for returns due between 1/1/2020 and 12/31/2022,</p>
				<p>$210 for returns due between 1/1/2018 and 12/31/2019,</p>
				<p>$205 for returns due between 1/1/2016 and 12/31/2017,</p>
				<p>$135 for returns due between 1/1/2009 and 12/31/2015, or</p>
				<p>$100 for returns due before 1/1/2009</p>
            </div>
        </div>
    )
}

export default Tips