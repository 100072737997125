import React, {
    useState,
    useRef,
    useEffect,
  } from 'react';
  
  import DatePicker from 'react-date-picker';
  import 'react-date-picker/dist/DatePicker.css';
  import 'react-calendar/dist/Calendar.css';
  import moment from 'moment';
  import './ReactDatePicker.css'
  import CalendarIcon from '../assets/calendar.svg';
  
  const RDatePicker = (props) => {
    const [date, setDate] = useState(props.value !== '' ? moment(props.value, 'MM DD YYYY').toDate(): '');
    const [isOpen, setOpen] = useState(false);
    var oldDate = date !== '' ? moment(date).format('MM/DD/YYYY') : '' ;
    const inputRef = useRef(null);
    const today = new Date();
    const minDate = new Date('1/1/1600');
    const maxDate = new Date('12/30/2500');
  
    useEffect(() => {
      if(date !== ''){
        inputRef.current.value =  moment(date).format('MM/DD/YYYY');
        //inputRef.current.select();
      }
    }, [inputRef, oldDate, date])
  
  
    const onChange = selectedDate => {
      const d = moment(selectedDate).format('MM/DD/YYYY')
      setDate(d);
      props.onChange(d);
    };
  
    const onClick = e => {
      if (isOpen) {
        setOpen(!isOpen);
      }
    };
  
    const onValueChanged = (e) => {
      var text = e.target.value;
      if (!text.includes("/")) {
        text = convertToDate(text);
      }
      constructDate(text);
    }
  
    const constructDate = (d) => {
      if (moment(d, 'MM/DD/YYYY', true).isValid()) {
        if (!isNaN(new Date(d))) {
          var selectedDate = new Date(d);
          if (selectedDate < maxDate && selectedDate > minDate) {
          } else {
            const year = selectedDate > maxDate ? maxDate.getFullYear() : minDate.getFullYear()
            selectedDate.setFullYear(year);
          }
        } 
        else {
          alert(`${d} is not valid one 1`);
          props.onError('Please note: Dates must be between 1/1/1600 and 1/1/2500');
        }
      }
    }
  
    const convertToDate = (number) => {
      const m = getMonth(number);
      const d = getDay(number);
      const y = getYear(number);
      return `${m}/${d}/${y}`
    };
  
    const getMonth = (number) => {
      if (number.length === 0) { return }
      if (number.length === 1) {
        return number
      }
      else if (number.length >= 2) {
        let subNumber = number.substring(0, 2);
        return subNumber <= 12 ? subNumber : subNumber[0];
      }
    }
  
    const getDay = (number) => {
      if (number.length === 1) {
        return today.getDate();
      }
      else if (number.length === 2) {
        return number <= 12 ? today.getDate() : number[1];
      }
      else if (number.length === 3) {
        let subNumber = number.substring(0, 2);
        let day = subNumber <= 12 ? number.substring(2) : number.substring(1);
        return day > 31 ? day[0] : day
      }
      else if (number.length > 3) {
        const month = getMonth(number).length;
        const day = number.substring(month, month + 2);
        return day > 31 ? day[0] : day
      }
    }
  
    const getYear = (number) => {
      if (number.length >= 3) {
        let monthLength = getMonth(number).length;
        let dayLength = getDay(number).length;
        let totalLength = monthLength + dayLength;
        let remainingLength = number.length - totalLength
        switch (remainingLength) {
          case 0:
            return today.getFullYear()
          case 1:
          case 2:
          case 3:
            const yearToAdd = parseInt(number.substring(totalLength));
            const baseYear = yearToAdd <= 60 ? 2000 : 1900;
            return baseYear + yearToAdd;
          case 4:
            return number.substring(totalLength)
          default:
            return maxDate.getFullYear();
        }
      }
      return today.getFullYear();
    }
    
    function formatDate(text) {
      const formats = ['M/D/YY', 'MM/D/YY', 'M/DD/YY', 'MM/DD/YY']; // Handle all format variations
      let parsedDate = null;
    
      // Try parsing the input against each possible format
      for (const format of formats) {
        if (moment(text, format, true).isValid()) {
          parsedDate = moment(text, format, true);
          break;
        }
      }
    
      if (!parsedDate) {
        // If no valid format matches, return an error or a default value
        return 'Invalid Date';
      }
    
      // Handle two-digit year logic
      const year = parsedDate.year(); // Get the full year from Moment.js
      const twoDigitYear = parsedDate.format('YY'); // Extract the two-digit year
    
      // Determine the century for two-digit year
      const baseYear = parseInt(twoDigitYear) <= 60 ? 2000 : 1900; // Adjust this cutoff as needed
      const fullYear = baseYear + parseInt(twoDigitYear);
    
      // Replace the year in the parsed date with the computed full year
      parsedDate.year(fullYear);
      const d = parsedDate.format('MM/DD/YYYY')
      setDate(d);
      // Format the result as MM/DD/YYYY
      return d;
    }
    function containsOnlyNumbers(inputStr) {
      // Use a regular expression to check if the string contains only numbers
      return /^\d+$/.test(inputStr);
    }
    const getConvertedDate = (value) => {
      var text = value;
      if (!text.includes("/")) {
        text = convertToDate(text);
      }
      console.log(constructDate(text));
      return constructDate(text);
    }
    const onKeyDown = (e) => {
      const shiftKey = e.shiftKey || (e.key === 'Shift');
      
      if (e.shiftKey && e.key) {
        console.log("testv");
      }
      const allowedKeys = [
        "ArrowLeft",
        "ArrowRight",
        "Backspace",
        "Delete",
        "Tab",
      ];
      if (e.target.value.length >= 10 && e.key !== 'Backspace') {
        const { selectionStart, selectionEnd } = e.target;
        const selectedTextLength = selectionEnd - selectionStart;
        const remainingLength = e.target.value.length - selectedTextLength;
        if (remainingLength < 10) {
          return; // Allow input because it won't exceed the limit after replacement
        }
        // Otherwise, prevent additional input
        e.preventDefault();
      }
      if (allowedKeys.includes(e.key) || containsOnlyNumbers(e.key) || ['/', 'm', 'y', 'd', 'Y'].includes(e.key) || e.key === 'Backspace' || (e.shiftKey && e.key)) {
        
        if (['m', 'M', 'y', 'D', 'd', 'Y'].includes(e.key)) {
          e.preventDefault();
          const convertedDate = getConvertedDate(e.target.value);
          //const valueToAddOrSubtract = 1; //(shiftKey === null) ? -1 : 1
          let valueToAddOrSubtract = 1;
          if (e.shiftKey && e.key) {
            valueToAddOrSubtract = -1;
          }
  
          if (convertedDate !== undefined && convertedDate !== "") {
            switch (e.key) {
              case 'm':
                convertedDate.setMonth(convertedDate.getMonth() + valueToAddOrSubtract);
                break;
              case 'M':
                convertedDate.setMonth(convertedDate.getMonth() + valueToAddOrSubtract);
                break;
              case 'y':
                convertedDate.setYear(convertedDate.getFullYear() + valueToAddOrSubtract);
                break;
              case 'Y':
                convertedDate.setYear(convertedDate.getFullYear() + valueToAddOrSubtract);
                break;
              case 'd':
                convertedDate.setDate(convertedDate.getDate() + valueToAddOrSubtract);
                break;
              case 'D':
                convertedDate.setDate(convertedDate.getDate() + valueToAddOrSubtract);
                break;
            }
            e.target.value = moment(convertedDate).format('MM/DD/YYYY');
          }
        }
      } else {
        e.preventDefault();
      }
    }
    const onBlur = (e) => {
      const text = e.target.value;
      if(text === ''){return}
      var newDate = '';
      if (text.includes("/")) {
        if (moment(text, 'MM/DD/YYYY', true).isValid() || moment(text, 'M/D/YYYY', true).isValid() || moment(text, 'MM/D/YYYY', true).isValid() || moment(text, 'M/DD/YYYY', true).isValid()) {
          console.log("text2"+text)
          newDate = text;
        } 
        if (moment(text, 'MM/DD/YY', true).isValid() || moment(text, 'M/D/YY', true).isValid() || moment(text, 'MM/D/YY', true).isValid() || moment(text, 'M/DD/YY', true).isValid()) {
          newDate = formatDate(text);
        }
        else {
          // onChange({ target: { value: oldDate.current } });
          //alert(`${text} is not valid one`);
          newDate = convertToDate(text);
          //props.onError('Please note: Dates must be between 1/1/1600 and 1/1/2500');
          //return;
        }
      } else {
        newDate = convertToDate(text);
      }
      if (!isNaN(new Date(newDate))) {
        const selectedDate = new Date(newDate);
        if (selectedDate < maxDate && selectedDate > minDate) {
          props.onChange(newDate);
          setDate(newDate);
        } else {
          const date = selectedDate > maxDate ? maxDate : minDate
          //alert(`Please note: Dates must be between 1/1/1600 and 1/1/2500`);
          props.onError('Please note: Dates must be between 1/1/1600 and 1/1/2500');
        }
      } else {
        //onChange({ target: { value: oldDate.current } });
       // alert(`${newDate} is not valid one`);
        props.onError('Please note: Dates must be between 1/1/1600 and 1/1/2500');
      }
    }
  
    const shouldCloseCalendar = (e) => {
      console.log();
      return e.reason === 'select';
    }
  
    const onCalendarIconClick = () => {
      if(props.enabled){
        console.log();
        setOpen(!isOpen);
      }else{
        setOpen(false);
      }
    }
  
    return (
      <div className='parent-react-date-picker'>
        <div className="child">
          <DatePicker
          disabled = {true}
            value={oldDate}
            calendarIcon={null}
            isOpen={isOpen}
            clearIcon={null}
            selected={date}
            format="MM/dd/yyyy"
            onChange={onChange}
            onCalendarOpen={() => setOpen(true)}
            onCalendarClose={() => setOpen(false)}
            shouldCloseCalendar={shouldCloseCalendar}
            minDate={minDate}
            maxDate={maxDate}
            calendarType="gregory"
            className={"react-date-picker"}
            calendarClassName={"ag-custom-component-popup"}
          />
        </div>
  
        <div className="child input-container">
          <input
            disabled= {!props.enabled} 
            ref={inputRef}
            className='date-field'
            type="text"
            onBlur={onBlur}
            onClick={onClick}
            onKeyDown={onKeyDown}
            onChange={onValueChanged} />
  
          <div
            className= {props.enabled ? 'date-picker-calendar-icon' : 'date-picker-calendar-icon-disabled'} 
            onClick={onCalendarIconClick}>
              <img src={CalendarIcon} alt="" srcset="" />
          </div>
        </div>
  
      </div>
  
    );
  };
  
  export default RDatePicker;
  