import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mui/material'
import RDatePicker from '../DatePicker';
import './Compute.css'
import Report from './Report';
import axios from 'axios';
import useToday from '../../hooks/useToday';
import AlertDialog from '../AlertDialog';
const Compute = (props) => {
    console.log(props);
    console.log("viji");
    const [openReport, setOpenReport] = useState(false);
    const [value, setValue] = useState(props.value);
    const [payload, setPayload] = useState(props.payload);
    const [computeDate, setComputeDate] = useState(useToday());
    const [showErroMessage, setShowErroMessage] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const onCloseReport = () => {
        setOpenReport(false);
    }
    function formatCurrency(amount) {
        const absoluteValue = Math.abs(amount);
        return parseFloat(absoluteValue).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }
    const reCompute = () => {
        const p = {...payload,
            computeDate: computeDate
        }
        
        const computeurl = BASE_URL+"/api/compute";
        axios.post(
            computeurl, p)
            .then(res => {
              console.log(res.data);
              //setComputeData(res.data);
              const data = res.data.data;
              const formattedData = data.report.replace(/\n/g, '<br>');
              const formattedDataVal = `<pre>${formattedData}</pre>`;
              setValue({
                ...data,
                report: formattedDataVal
              });
            })
            .catch(err => { 
                setErrorTitle('API Error');
                setErrorMessage('Oops! Something went wrong. Please try again or contact your administrator');
                setShowErroMessage(true)
             })
    };

   
    const onDateChange = (d) => {
        setComputeDate(d)
    };

    const onCloseErrorAlert = () => {
        setShowErroMessage(false);
      }
    

    return (
        <div className="report">

            <Dialog
                open={props.openComputeDialog}
                onClose = {props.onCloseComputeDialog}
                maxWidth={'sm'}
                scroll={'paper'}
                PaperProps={{
                    sx: {
                        width: "30%",
                        maxHeight: "50%",
                        minHeight: "60%"
                    }
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent dividers={true}>
                    <div className="compute-dialog">
                        <div className="result-title">Compute Results</div>
                        <div className="re-compute-section">
                            <div className="compute-date-label">Compute date:</div>
                            <RDatePicker  enabled={true} value={computeDate} onChange={onDateChange} />
                            <button className="action-button save" onClick={reCompute}>Recompute</button>
                        </div>
                        <hr />
                        <div className="compute-result">
                            <ComputeResultRow title={'Tax'} value={formatCurrency(value.tax)} />
                            <ComputeResultRow title={'Penalties'} value={formatCurrency(value.penalties)} />
                            <ComputeResultRow title={'Interest'} value={formatCurrency(value.interest)} />
                            <ComputeResultRow title={'Debit and IRS Check'} value="0.00" />
                            <ComputeResultRow title={'Refund interest'} value={formatCurrency(value.refund)} />
                            <ComputeResultRow title={'Payments and Credits'} value={'<'+ formatCurrency(value.payments) +'>'} />
                            <hr />
                            <ComputeResultRow title={'Total'} value={formatCurrency(value.balance)} />
                        </div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <button className="action-button save" onClick={() => setOpenReport(true)}>Report...</button>
                    <button onClick={props.onCloseComputeDialog} className="action-button cancel">Close</button>
                </DialogActions>

            </Dialog>
            {
                openReport && <Report value={value.report} openReport={openReport} onCloseReport={onCloseReport} />
            }
            
            {
                showErroMessage && <AlertDialog title = {errorTitle} message = {errorMessage} open={showErroMessage} onClose={onCloseErrorAlert} />
            }

        </div>

    );
}

export default Compute


const ComputeResultRow = (props) => {
    return (
        <div className="compute-result-row">
            <div className="compute-result-label">{props.title}</div>
            <div className="compute-result-value">{props.value}</div>
        </div>
    );
}
