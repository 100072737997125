import { Checkbox, Dialog, DialogContent, Button } from '@mui/material'
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react'
import RDatePicker from '../../DatePicker';
import './Penalty6662G.css'
import useToday from '../../../hooks/useToday';
import AmountTextField from '../../AmountTextField/AmountTextField';
import Tips from '../Tips/6662g';
import PenaltyLabel from '../../Label/PenaltyLabel';
import IOSSwitch from '../../IOSSwitch/IOSSwitch';
import PenaltyCloseIcon from '../../../assets/penalty-close-icon.svg';
import PenaltyDiscardAlert from '../../PenaltyDiscardAlert';
import AccordionPlus from '../../../assets/accordion-plus.svg';
import AccordionMinus from '../../../assets/accordion-minus.svg';
const Penalty6662G = forwardRef((props, ref) => {
    const [penalty, setPenalty] = useState(props.value);
    const [showAlert, setShowAlert] = useState(false);
    let [initialPenalty, setInitialPenalty] = useState(props.value);
    const today = useToday();
    const options = [
        "20",
        "40"
    ];
    const [isOpen, setIsOpen] = useState(penalty.showTips);
    useImperativeHandle(ref, () => ({
        getValue: () => penalty // Allow the parent to access this value
      }));
      const toggleAccordion = () => {
        setPenalty({
            ...penalty,
            showTips: !isOpen
        })
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOff = (event) => {
            console.log(event.srcElement.classList );
            if (event.srcElement.classList.contains('MuiDialog-container')) {
                if (JSON.stringify(initialPenalty) !== JSON.stringify(props.value)) {
                    setShowAlert(true);
                  } else {
                    props.onClose();
                  }
            }
        }
        document.addEventListener('click', handleClickOff);
    return () => {
      document.removeEventListener('click', handleClickOff);
    };
    }, [props.value, initialPenalty, penalty]);

    const onClickOk = () => {
        setInitialPenalty(penalty);
        props.onSubmit(penalty);
        // props.onClose()
    }

    const onClickCancel = () => {
        if (JSON.stringify(initialPenalty) !== JSON.stringify(props.value)) {
            setShowAlert(true);
          } else {
            props.onClose();
          }
        setShowAlert(true);
    }

    const onCheckBoxChanged = (e) => {
        const p = {
            ...penalty,
            enabled: e.target.checked,
        }
        setPenalty(p);
    }

    const onOptionChangeHandler = (event) => {
        const p = {
            ...penalty,
            percentage: event.target.value,
        }
        setPenalty(p);
    };
    const onSave = () => {
        onClickOk()
        props.onClose()
    }

    const onDiscard = () => {
        props.onClose()
    }
    const onCloseErrorAlert = () => {
        setShowAlert(false)
    }
    return (
            <div>
                 {/* <div className="title-section-close">
                <img className='penalty-close-icon'  width={30} height={30} src={PenaltyCloseIcon} onClick={onClickCancel} alt="close-icon" />
            </div> */}
            <div className="penalty-6651a-main-container">
                <div className="negligence-penanlty-form">
                    <div className="activation-container">
                        <div className="activation-left-container">
                    {/* <div className="penalty-activation-conatiner">
                        <PenaltyLabel value={`Activate ${penalty.name}`} enabled={true} type="header" />
                        <IOSSwitch checked={penalty.enabled ? penalty.enabled : false} onChange={onCheckBoxChanged} />

                    </div> */}

                    <div className="due-date-container">
                        <div className={!penalty.enabled && "disabled-text"}>Due date (with extensions)</div>

                        <RDatePicker enabled={penalty.enabled ? penalty.enabled : false} value={penalty.dueDate !== null ? penalty.dueDate : today} onChange={d => setPenalty({
                            ...penalty,
                            dueDate: d
                        })} />
                    </div>

                    <div className="net-tax-container">
                        <div className={!penalty.enabled && "disabled-text"}>Underpayment due to valuation understatement</div>
                        {/* <input type='text' className='amount-text-box' value={penalty.amount} onChange={e => setPenalty({
                                ...penalty,
                                amount: e.target.value
                            })} disabled={!penalty.enabled} /> */}
                        <AmountTextField value={penalty.amount} disabled={!penalty.enabled} onChange={v => setPenalty({
                            ...penalty,
                            amount: v
                        })} />
                    </div>

                    <div className="net-tax-container percentage-container">
                        <div className={!penalty.enabled && "disabled-text"}>Applicable Penalty Percentage</div>
                        <select className='percentage-select' disabled={!penalty.enabled} onChange={onOptionChangeHandler}>
                            {options.map((option, index) => {
                                return (
                                    <option key={index} selected={penalty.percentage !== null && penalty.percentage === option}>
                                        {option}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    </div>
                    {/* <div className="show-tips-container">
                            <div className="show-tips">
                                <PenaltyLabel value={'Show tips?'} enabled={penalty.enabled} type={"header"} />
                                <IOSSwitch disabled={!penalty.enabled} checked={penalty.showTips} onChange={e => setPenalty({
                                    ...penalty,
                                    showTips: e.target.checked
                                })} />
                            </div>
                        </div> */}
                    </div>
                </div>
                    {penalty.showTips && <Tips />}
            </div>

            <div className="action-buttons">
                <button className='action-button cancel' type="Cancel" value={"Cancel"} onClick={onClickCancel}>Cancel</button>
                <button className='action-button save' type="Save" value={"Save"} onClick={onClickOk}>Save</button>
            </div>
            <div className="accordion">
                <div className="accordion-header" onClick={toggleAccordion}>
                    <PenaltyLabel value={'Penalty Details'} enabled={true} type="header" />
                    <div className="icon">
                    {isOpen ? <img src={AccordionMinus} alt="Close Icon" /> : <img src={AccordionPlus} alt="Close Icon" />}
                    </div>
                </div>
                {isOpen && (
                    <div className="accordion-content">
                        <Tips />
                    </div>
                )}
            </div>
            <PenaltyDiscardAlert title={'Discard Unsaved Changes'} message={'If you navigate away from this page, you will lose your changes.'} open={showAlert} onClose={onCloseErrorAlert} onSave={onSave} onDiscard={onDiscard} />

            </div>
    )
});

export default Penalty6662G;