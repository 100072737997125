import React, { useState, useRef, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PenaltyCloseIcon from '../../assets/penalty-close-icon.svg';
import FolderIcon from '../../assets/folder.svg';
import MoreIcon from '../../assets/policies-dot.svg';
import FilesIcon from '../../assets/files.svg';
import CustomTextField from '../TextField/CustomTextField';
import useClerk from '../../hooks/userClerk';
import axios from "axios";
import DescriptionIcon from '@mui/icons-material/Description';
import moment from 'moment';
import { Popover } from '@mui/material';
import PopoverMenuItem from '../PopoverMenuItem/PopoverMenuItem'; 
import DeleteIcon from '../../assets/delete-pop-over-icon.svg';
import EditIcon from '../../assets/edit-pop-over-icon.svg';

export default function FileNameDialog(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activePopover, setActivePopover] = useState(null);
  const open = Boolean(anchorEl);
  const {getClerkToken} = useClerk();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const id = open ? 'popover' : undefined;
  const { onCancel, onSave, onCreateFolder, onCreateSubFolder, onFolderDelete, oldData, onListFolders, folderNames, oldFolderName, filesList, onUpdateFolder, fetchFiles, onSelect, onFileValueChange } = props;
  console.log("oldData");console.log(oldData);
  console.log("oldFolderName");console.log(oldFolderName);
  console.log(folderNames);
  const [name, setName] = useState(oldData?oldData.fileName:"");
  const [fNames, setFNames] = useState(folderNames);
  //console.log(fNames.files.length);
  const [folderName, setFolderName] = useState('');
  const [subFolderName, setSubFolderName] = useState('');
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [showCreateSubFolder, setShowCreateSubFolder] = useState(false);
  const [activeFolderIndex, setActiveFolderIndex] = useState(0); 
  const [activeFolderName, setActiveFolderName] = useState(oldData && oldData.fileInfo ? oldData.fileInfo.fileKey.substring(0, oldData.fileInfo.fileKey.lastIndexOf('/')) : '');
  const [clickableFolderName, setClickableFolderName] = useState();
  const [clickableFolderId, setClickableFolderId] = useState();
  const lastPart = oldFolderName.split('/').pop();
  const getParent = folderNames && folderNames.find(folder => folder.folderName === lastPart) || null;
  const [activeInput, setActiveInput] = useState(null);
  const [parentId, setParentId] = useState(oldData && oldData.fileInfo ? oldData.fileInfo.folderId :null);
  
  console.log("activeFolderName");console.log(activeFolderName);
  const [fList, setFList] = useState(filesList);
  console.log("filesList");console.log(filesList);
  const OldDataFileKey = oldData && oldData.fileInfo ? oldData.fileInfo.fileKey : "";
  let filteredFiles = [];
  if(fList.length>0){
     filteredFiles = fList.filter((file) => file.folderId === parentId  && file.fileKey != OldDataFileKey);
  }
  
  const filterByFileKey = (key) => {
    console.log("filterByFileKey",parentId);
    console.log("filterByFileKey",parentId);
    if(key=="cf") {
      key = "/";
    }
    if (fNames && fNames.length > 0) {
      if(key == "/") {
        return fNames.filter(folder => folder.parentId === null);
      } else {
        console.log("filtered", fNames.filter(folder => folder.parentId === parentId));
        return fNames.filter(folder => folder.parentId === parentId);
      }
      
    } else {
      return [];
    }
   
  };
  const filteredFolders = filterByFileKey(activeFolderName);
  console.log("filteredFolders", filteredFolders);
  const [filesArr, setFilesArr] = useState('');
  //const [subFolderArr, setSubFolderArr] = useState();
  console.log("test me here");console.log(activeFolderName);
  const [currentPath, setCurrentPath] = useState(
    activeFolderName ? activeFolderName.split('/') : []
  );
  console.log("currentPath", currentPath);
  const [isEditing, setIsEditing] = useState(false);
  const [editingFolderName, setEditingFolderName] = useState('');
  function getFolderKeys(obj, path) {
    console.log("getFolderKeys"); console.log(obj);console.log(path);console.log(activeFolderName);
    if (path=="cf" || path=='') {
        // Return top-level keys if path is empty
        return Object.keys(obj);
    }

    // Split the path into an array of keys
    const pathArr = path.split('/').filter(value => value !== '');;
    console.log(pathArr)
    // Traverse the object to reach the folder
    const folderData = pathArr.reduce((acc, key) => acc && acc[key], obj);

    // Return the keys of the folder, or an empty array if the folder is not found
    return folderData ? Object.keys(folderData) : [];
}
  //const folderData = getFolderKeys(fNames, activeFolderName);
  //const [subFolderArr, setSubFolderArr] = useState(folderData);
//console.log("folderData");console.log(folderData);

const handleSave = () => {
    if (activeInput === 'file-name') {
      handleFileSubmit();
    } else if (activeInput === 'sub-folder-name') {
      handleFolderSubmit();
    } else if (activeInput === 'folder-rename') {
      handleFolderRename();
    }
}
const handleFileSubmit = () => {
  const existingFile = fList.filter((file) => 
    file.folderId === parentId && file.fileName === name
  );
  if (existingFile.length > 0) {
    const proceed = window.confirm("A file with this name already exists. Do you want to proceed?");
    
    if (proceed) {
      if(oldData.fileInfo.folderId != parentId) {
        console.log("if here");
        onSave(name, activeFolderName,1,existingFile[0], parentId);
      } else {
        console.log("else here");
        onSave(name, activeFolderName,0,existingFile[0], parentId);
      }
    } else {
      return;
    }
  } else {
   // console.log(oldData.fileInfo.folderId);console.log(parentId);return;
    if(oldData.fileInfo && oldData.fileInfo.folderId != parentId) {

      console.log("if here");
      onSave(name, activeFolderName,1,'',parentId);
    } else {
      console.log("else here");
      onSave(name, activeFolderName,0,'',parentId);
    }
    return;
  }
}
const handleFolderSubmit = async () => {
  let pathToPass = '';
        if(activeFolderName!='' && activeFolderName!="cf" && activeFolderName!==null) {
          console.log("if");
          console.log(activeFolderName+"/"+subFolderName);
          pathToPass = activeFolderName+"/"+subFolderName;
          onCreateFolder(pathToPass, parentId, subFolderName);
        } else {
          pathToPass = subFolderName;
          console.log("path to pass", pathToPass);
          onCreateFolder(pathToPass, parentId, subFolderName);
        }
        setSubFolderName('');
        setShowCreateSubFolder(false);
        setTimeout(() => {
          fetchFolders();
        }, 2000);
        // setFNames(fetchFolder);
        // const folderData = getFolderKeys(fetchFolder, subFolderName);
       // setSubFolderArr(folderData);
        //setSubFolderArr(prevArray => [...prevArray, subFolderName]);
        // const fetchFolder = await fetchFolders();  
        // console.log("fetchFolder", fetchFolder);
        // setFNames(fetchFolder);
       // handleFolderClick(pathToPass);
}
const handleFolderRename = async () => {
  if(activeFolderName != editingFolderName) {
    onUpdateFolder(clickableFolderName, clickableFolderId, editingFolderName);
    //setEditingFolderName(editingFolderName);
    setTimeout(() => {
      fetchFolders();
    }, 4000);
   // const fetchFolder = fetchFolders();
  // const folderData = getFolderKeys(fetchFolder, editingFolderName);
  // setSubFolderArr(folderData);
  }
  setIsEditing(false);
}
const handleFolderDelete = () => {
  console.log(activeFolderName);
  handleClose();
  const confirmDelete = window.confirm(`Are you sure you want to delete the folder?`);
  if (confirmDelete)  {
    // if(activeFolderName != '' && activeFolderName !='cf') {
    //   onFolderDelete(activeFolderName+"/"+clickableFolderName);
    // } else {
    //   onFolderDelete(clickableFolderName);
    // }
    onFolderDelete(clickableFolderId);
  }  
};

const handleFileClick = (name) => {
  setName(name);
  inputRef.current.select();
}
  const handleDoubleClick = () => {
    handleClose();
    setEditingFolderName(clickableFolderName);
    setIsEditing(true);
  };
  
  const handleBreadcrumbClick = (index, pathToPass) => {
    console.log("pathToPass"+pathToPass);
    console.log("currentPath",currentPath);
    // Go to the folder up to the clicked breadcrumb level
    setCurrentPath(currentPath.slice(0, index + 1));
    const lastPart = pathToPass.split('/').pop();
    const getParent = fNames && fNames.find(folder => folder.folderName === lastPart) || null;
    const pId = getParent ? getParent._id : null
    //setParentId(pId);
    console.log("pId",pId);
    handleFolderClick(pathToPass, pId);
  };

  const handleFolderActive = (subfolname, pId) => {
    console.log("paid", pId);
      setClickableFolderName(subfolname);
      setClickableFolderId(pId);
     // setParentId(parentId, pId);
  };

  const handleFolderClick = (fName='', pId=null) => {
    console.log(fName);
    console.log("pId", pId);
    if(fName===''){
      console.log("if");
      fName = activeFolderName && activeFolderName !== 'cf' ? activeFolderName + '/' : '' + clickableFolderName;
      console.log(fName);
    }
    const newPath = fName.split('/').filter(value => value !== ''); // This will create an array like ['vijay', 'vijay-sub']
    const updatedPath = [...currentPath, ...newPath];
    setCurrentPath(newPath);
    setParentId(pId);
    setActiveFolderName(fName); // Set the clicked folder as active
    //const s = getFolderKeys(fNames, fName);
    //setSubFolderArr(s);
  };
  
  const handleFolderOpen = () => {
    handleClose();
      console.log("if");
      console.log(activeFolderName);console.log("if here");console.log(clickableFolderName);
      console.log("if end");
      let fName = (activeFolderName && activeFolderName !== 'cf' ? activeFolderName + '/' : '') + clickableFolderName;
      console.log(fName);
    
    const newPath = fName.split('/').filter(value => value !== ''); // This will create an array like ['vijay', 'vijay-sub']
    const updatedPath = [...currentPath, ...newPath];
    setCurrentPath(newPath);
    setActiveFolderName(fName); // Set the clicked folder as active
    // const s = getFolderKeys(fNames, fName);
    // setSubFolderArr(s);
  };
  const fileKeyExists = (data, key) => {
    const index = data.findIndex(item => item.fileInfo && item.fileInfo.fileKey === key);
    return index !== -1 ? index : null;
};
  const downloadFile = async (file) => {
    const getIndex = fileKeyExists(props.data,file.fileKey);
    
    if(getIndex!==null) {
        console.log(getIndex)
        onSelect(getIndex);
        console.log("already exists");
    } else if(props.data.length >=12) {
        alert("You have reached the maximum number of files to open. Please close unused files.");
        return;
      } else {
        try {
            const token = await getClerkToken();
            console.log(token)
            const fileurl = BASE_URL+"/api/files/"+file._id+"/url";
            const response = await axios.get(encodeURI(fileurl), {headers:{'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}});
            const table = response.data;
            const eventNames = {
                'Tax': 'TXIEventTypeTax',
                'Payment': 'TXIEventTypePayment',
                'Deposit as Bond': 'TXIEventTypeDeposit',
                'Suspend Interest': 'TXIEventTypeStopInterest',
                'Resume Interest': 'TXIEventTypeStartInterest',
                'IRS Check': 'TXIEventTypeIRSCheck',
                'Entered Penalty': 'TXIEventTypeUserPenalty',
                'Motivated Tax': 'TXIEventTypeMotivatedTax',
              };
              const eventDataArray = table.events;
              eventDataArray.forEach(eventData => {
                for (const key in eventNames) {
                  eventData.eventDate = moment(eventData.eventDate).format('MM/DD/YYYY')
                  if (eventNames.hasOwnProperty(key) && eventData.eventType === eventNames[key]) {
                    eventData.eventType = key;
                    break; // Stop loop if a match is found
                  }
                  
                }
              });
              
            props.onFileValueChange(file, table);
        } catch (error) {
            console.log(error);
        }
    }
}
  const getFiles = () => {
    // If it's a top-level folder, just return the files
    console.log("getFiles");
    console.log(folderNames[activeFolderName]?.cf);
    if (folderNames[activeFolderName]?.cf) {
      return folderNames[activeFolderName].cf;
    }
    // If no files exist in the folder, return an empty array
    return [];
  };
  const handleBlur = () => {
    console.log("blurring");
    setShowCreateFolder(false);
    setShowCreateSubFolder(false); // Hide the input when it loses focus
  };
  const inputRef = useRef(null);
  const inputFolderRef = useRef(null);
  const inputFolderRenameRef = useRef(null);
  const inputSubFolderRef = useRef(null);
  
  const handleClose = () => {
    setAnchorEl(null);
    setActivePopover(null);
  };
  const handleRightClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setActivePopover("popover");
  }
  const onShowFolder  = () => {
    console.log(activeFolderName.split('/'))
    if(activeFolderName.split('/').length>1){
      setShowCreateFolder(false);
      setShowCreateSubFolder(true);
    } else {
      setShowCreateFolder(true);
      setShowCreateSubFolder(false);
    }
    
  }
  const onShowSubFolder  = () => {
    setShowCreateSubFolder(true);
    setShowCreateFolder(false);
    handleClose();
  }
  const fetchFolders = async () => {
    try {
      const foldernames1 = await onListFolders();
      if(foldernames1[0]){
        //(foldernames1[0]);
        //setActiveFolderName(foldernames1[foldernames1.length - 1])
      } else {
       // setOldFolderName();
        setActiveFolderName('')
      }
      console.log("foldernames: ", foldernames1);
      setFNames(foldernames1); // Save to state if needed
    } catch (error) {
      console.error("Error fetching folder names: ", error);
    }
  };
  const fetchFilesList = async () => {
    try {
      const filesList = await fetchFiles();
      console.log("filesList: ", filesList);
      props.setFilesList(filesList)
      setFList(filesList); // Save to state if needed
    } catch (error) {
      console.error("Error fetching files names: ", error);
    }
  };
  useEffect(() => {
    //handleFolderClick(activeFolderName);
    const timeout = setTimeout(() => {
      if (props.open && inputRef.current) {
          inputRef.current.select();
        //inputRef.current.focus();
      }
      if(showCreateFolder){
        inputFolderRef.current.focus();
      }
      if(isEditing){
        inputFolderRenameRef.current.select();
      }
      if(showCreateSubFolder){
        inputSubFolderRef.current.focus();
      }
    }, 0);
      return () => clearTimeout(timeout);
  }, [showCreateFolder, showCreateSubFolder, isEditing]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.target.id=="folder-name" && e.key === 'Enter') {
        e.preventDefault();
        console.log("folderName"+folderName);
        onCreateFolder(folderName)
        setShowCreateFolder(false);
        const fetchFolder = fetchFolders();
        // const folderData = getFolderKeys(fetchFolder, folderName);
        // setSubFolderArr(folderData);
      } else if(e.target.id=="sub-folder-name" && e.key === 'Enter') {
        e.preventDefault();
        console.log("subfolderName heere"+subFolderName);
        console.log("activeFolderName here");
        console.log(activeFolderName);
        handleFolderSubmit();
        
      } else if (e.target.id=="file-name" && e.key === 'Enter') {
        e.preventDefault(); // Prevent default behavior for Enter key
        console.log("name"+name);
        console.log("activeFolderName"+activeFolderName);
        console.log(oldData);
        console.log("parentId",parentId)
        
        handleFileSubmit();
      } else if (e.target.id=="folder-rename" && e.key === 'Enter') {
        e.preventDefault();
        console.log("activeFolderName"+activeFolderName);
        console.log("editingFolderName"+editingFolderName);
        handleFolderRename();
        
      } 
    };

    if (props.open && inputRef.current) {
      console.log("inputRef");
      inputRef.current.addEventListener('keydown', handleKeyDown);
    }
    if (inputFolderRef.current) {
      console.log("inputFolderRef");
      inputFolderRef.current.addEventListener('keydown', handleKeyDown);
    }
    if (inputFolderRenameRef.current) {
      console.log("inputFolderRef");
      inputFolderRenameRef.current.addEventListener('keydown', handleKeyDown);
    }
    if (inputSubFolderRef.current) {
      console.log("inputSubFolderRef");
      inputSubFolderRef.current.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('keydown', handleKeyDown);
      }
      if (inputFolderRef.current) {
        inputFolderRef.current.removeEventListener('keydown', handleKeyDown);
      }
      if (inputFolderRenameRef.current) {
        inputFolderRenameRef.current.removeEventListener('keydown', handleKeyDown);
      }
      if (inputSubFolderRef.current) {
        inputSubFolderRef.current.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [props.open, name, fList, folderName, subFolderName, onSave, showCreateFolder, showCreateSubFolder, isEditing, editingFolderName]);
    
  return (
    
    <React.Fragment>
       <Popover
        id={id}
        open={open}
        elevation={0}
        slotProps={{
          paper: {
             sx:{
              width: '210px',
              borderRadius: '15px',
              marginLeft: '5px',
              boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.05)'
             }
          }
      }}
        anchorEl={anchorEl}
        
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'left',
          horizontal: 'right',
        }}
      >

        <div className="sheet-pop-over-menu">
          <PopoverMenuItem icon={FolderIcon} title={'Open'} onClick= {handleFolderOpen} />
          <PopoverMenuItem icon={EditIcon} title={'Rename'} onClick= {handleDoubleClick} />
          <PopoverMenuItem icon={DeleteIcon} title={'Delete'} onClick= {handleFolderDelete} />
        </div>
        {/* <Typography className="settings-menu-item" onClick={handleOptionClick} sx={{ p: 1.5 }}>Rename</Typography>
        <Typography className="settings-menu-item" onClick={handleOptionClick} sx={{ p: 1.5 }}>Close</Typography> */}
      </Popover>
      <Dialog
        open={props.open}
        onClose={onCancel}
        PaperProps={{
          sx: {
            borderRadius: "15px",
            width: "47%",
            maxWidth: "700px",
            height:"70%",
            minHeight: "30%"
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="title-section">

            <div class="title-name">
              {props.title}
            </div>
          <div class="title-close">
            <img className='penalty-close-icon' onClick={onCancel} width={30} height={30} src={PenaltyCloseIcon} alt="close-icon" />
          </div>
          </div>
        </DialogTitle>
        <DialogContent>
        <nav>
            <span key="cf">
                  <a href="#" onClick={() => handleBreadcrumbClick("","cf")}>
                    Company Share /&nbsp;
                  </a>
                </span>
            {currentPath && currentPath.map((folder, index) => {
              {if(folder!="cf") {
                // Create the path to pass to the onClick handler
              const pathToPass = currentPath.slice(0, index + 1).join('/');
              
              return (
                <span key={index}>
                  <a href="#" onClick={() => handleBreadcrumbClick(index, pathToPass)}>
                    {folder=="cf"?"Company Share":folder}
                  </a>
                  {index < currentPath.length - 1 && " / "}
                </span>
              );
              }}
              
            })}
      </nav>
          <div className='file-folder-container'>
            <div className='file-folder-section'>
              <div className='file-folder-inside'>
              <div className='files-section'>
                {showCreateSubFolder && ( <div className='folder-name'><img className='folder-icon' src={FolderIcon} alt="folder-icon" />
                <span>
                  <CustomTextField
                    value={subFolderName}
                    id = "sub-folder-name"
                    placeholder={"Type here"}
                    onChange={(e) => { setSubFolderName(e.target.value); setActiveInput('sub-folder-name');console.log(folderName); }}
                    onBlur={(e) => { setShowCreateSubFolder(false) }}
                    ref={inputSubFolderRef}  // Pass the ref to CustomTextField
                    maxLength = "48"
                  />
                </span>
                </div>
                )}
                {
                                        filteredFolders.map((folder)=>(
                                          <div
      className={`folder-name ${folder.parentId} ${folder.folderName === clickableFolderName ? 'active' : ''}`}
      onClick={() => handleFolderActive(folder.folderName, folder._id)}
      onDoubleClick={() => handleFolderClick((activeFolderName && activeFolderName !== 'cf' ? activeFolderName + '/' : '') + folder.folderName, folder._id)} 
      onContextMenu={(event) => {
        if (folder.folderName === clickableFolderName) {
          handleRightClick(event); // Only trigger on right-click for active folders
        } else {
          event.preventDefault(); // Prevent context menu for non-active folders
        }
      }}
    >
      <img 
        className={activeFolderName} 
        src={FolderIcon} 
        alt="folder-icon" 
        // onContextMenu={(event)=> handleRightClick(event)}
        //onClick={() => handleFolderClick((activeFolderName && activeFolderName !== 'cf' ? activeFolderName + '/' : '') + subfolname)} 
      />
      {(isEditing && folder.folderName === clickableFolderName) ? (
      <CustomTextField
        id="folder-rename"
        value={editingFolderName}
        ref={inputFolderRenameRef}
        onBlur={(e) => { setIsEditing(false) }}
        onChange={(e) => { setEditingFolderName(e.target.value); setActiveInput("folder-rename")} }
      />
      ) : (
        <span className='folder-name-single'>
          {folder.folderName === '/' ? 'Company Share' : folder.folderName}
        </span>
      )}
    </div>
                                        ))
                                    }
                {/* {subFolderArr && subFolderArr
  .filter(subfolname => subfolname !== 'cf')  // Filter out 'cf'
  .map((subfolname, index) => (
    <div
      key={subfolname}
      className={`folder-name ${subfolname === clickableFolderName ? 'active' : ''}`}
      onClick={() => handleFolderActive(subfolname)}
      onDoubleClick={() => handleFolderClick((activeFolderName && activeFolderName !== 'cf' ? activeFolderName + '/' : '') + subfolname)} 
      onContextMenu={(event) => {
        if (subfolname === clickableFolderName) {
          handleRightClick(event); // Only trigger on right-click for active folders
        } else {
          event.preventDefault(); // Prevent context menu for non-active folders
        }
      }}
    >
      <img 
        className={activeFolderName} 
        src={FolderIcon} 
        alt="folder-icon" 
        // onContextMenu={(event)=> handleRightClick(event)}
        //onClick={() => handleFolderClick((activeFolderName && activeFolderName !== 'cf' ? activeFolderName + '/' : '') + subfolname)} 
      />
      {(isEditing && subfolname === clickableFolderName) ? (
      <CustomTextField
        id="folder-rename"
        value={editingFolderName}
        ref={inputFolderRenameRef}
        onBlur={(e) => { setIsEditing(false) }}
        
        onChange={(e) => setEditingFolderName(e.target.value)}
      />
      ) : (
        <span className='folder-name-single'>
          {subfolname === '/' ? 'Company Share' : subfolname}
        </span>
      )}
    </div>
  ))
} */}

                                    {
                                        filteredFiles.map((file) => <FileContainer file={file} handleFileClick={handleFileClick} downloadFile={downloadFile}/>)
                                    }
                                   

              </div>
            </div>
            </div>
            <div className="file-folder-action">
              <div className="action-buttons">
                <div className='create-new-section'>

                  {/* <button className='action-button create-new' type="button" onClick={() => onCreateFolder(folderName)}>Create New Folder</button> */}
                  <button className='action-button create-new' type="button" onClick={onShowSubFolder}>Create New Folder</button>&nbsp;
                  {/* <button className='action-button' type="button" onClick={handleDoubleClick}>Rename</button>&nbsp;
                  <button className='action-button' type="button" onClick={handleFolderDelete}>Delete</button> */}
                </div>
                <div className='files-name-form'>
              <div className='file-text-field'>
                <CustomTextField
                  id="file-name"
                  value={name}
                  placeholder={"Type here"}
                  onChange={(e) => { setName(e.target.value);setActiveInput('file-name'); console.log(name) }}
                  ref={inputRef}  // Pass the ref to CustomTextField
                  maxLength = "48"
                />
                </div>
            </div>
                <button className='action-button cancel' type="button" onClick={onCancel}>Cancel</button>
                <button className='action-button save' type="button" onClick={handleSave}>Save</button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
 

}


const FileContainer = ({ file, handleFileClick, downloadFile }) => {
    
  return (
      <div>
     
      <div className="file-container" onClick={() => handleFileClick(file.fileName)} onDoubleClick={() => downloadFile(file)}>
          <DescriptionIcon sx={{ width: '26px',  color: 'lightGray' }} />
          <div className="file-info-section">
              <div className='file-name'>{file.fileName}</div>
              {/* <div className="file-creation-date"><span>Created At:</span><span>{moment(file.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</span></div>
              <div className="file-updattion-date"><span>Updated At:</span><span>{moment(file.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</span></div> */}
          </div>
      </div>
      </div>

  )
}