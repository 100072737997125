import { Dialog, DialogContent, Button, Paper, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RDatePicker from '../../DatePicker';
import './Penalty6621C.css'
import '../Penalties.css'
import useToday from '../../../hooks/useToday';
import Tips from '../Tips/6662c';
import PenaltyLabel from '../../Label/PenaltyLabel';
import IOSSwitch from '../../IOSSwitch/IOSSwitch';
import PenaltyDiscardAlert from '../../PenaltyDiscardAlert';
import Draggable from "react-draggable";
import PenaltyCloseIcon from '../../../assets/penalty-close-icon.svg';
import AccordionPlus from '../../../assets/accordion-plus.svg';
import AccordionMinus from '../../../assets/accordion-minus.svg';

const Penalty6621C = (props) => {
    
    const [penalty, setPenalty] = useState(props.value);
    let [initialPenalty, setInitialPenalty] = useState(props.value);
    const [showAlert, setShowAlert] = useState(false);
    const today = useToday();
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        const handleClickOff = (event) => {
            console.log(event.srcElement.classList );
            if (event.srcElement.classList.contains('MuiDialog-container')) {
                if (JSON.stringify(penalty) !== JSON.stringify(initialPenalty)) {
                    setShowAlert(true);
                  } else {
                    props.onClose();
                  }
            }
        }
        document.addEventListener('click', handleClickOff);
    return () => {
      document.removeEventListener('click', handleClickOff);
    };
    }, [props.value, initialPenalty, penalty]);

    const onClickOk = () => {
        props.onSubmit(penalty);
        props.onClose()
    }
    const onClose = () => {
        props.onClose();
    }
    const onClickCancel = () => {
        if (JSON.stringify(penalty) !== JSON.stringify(initialPenalty)) {
            setShowAlert(true);
          } else {
            props.onClose();
          }
        setShowAlert(true);
    }

    const onCheckBoxChanged = (e) => {
        const p = {
            ...penalty,
            enabled: e.target.checked,
        }
        setPenalty(p);
    }
    const onSave = () => {
        onClickOk()
        props.onClose()
    }

    const onDiscard = () => {
        props.onClose()
    }
    const onCloseErrorAlert = () => {
        setShowAlert(false)
    }
    function PaperComponent(props) {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }
    return (
        <Dialog
            open={props.onOpen}
            tabIndex={0}
            maxWidth={'lg'}
            onClose={onClickCancel}
            scroll={'paper'}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={{
                sx: {
                    width: "50%",
                    maxHeight: "66%",
                    borderRadius: "15px"
                }
            }}>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <div className="title-section">
                    <PenaltyLabel value={penalty.name} enabled={true} type={"header"} />
                    <div className="title-section-closen">
                        <div><img className='penalty-close-icon' width={30} height={30} src={PenaltyCloseIcon} onClick={onClickCancel} alt="close-icon" /></div></div>
                </div>
            </DialogTitle>
            <DialogContent>
            
                {/* <div className="penalty-title">{penalty.name} -- IRC &sect;{penalty.section}</div>
                <hr /> */}
                <div className="penalty6621c">
                    <div className="penanlty-5561-a-1-form">
                        <div className="left-container">
                            <div className="penalty-activation-conatiner">
                                <PenaltyLabel value={'Activate 6621(c) "Hot" interest calculation'} enabled={true} type="header" />
                                <IOSSwitch checked={penalty.enabled ? penalty.enabled : false} onChange={onCheckBoxChanged} />
                            </div>

                            <div className="return-date-container">
                                <PenaltyLabel value={'Either enter the "30 day" letter date, or'} enabled={penalty.enabled} type="normal" />
                                <RDatePicker enabled={penalty.enabled ? penalty.enabled : false} value={penalty.lcu30DayLetterDate ?? today} onChange={d => setPenalty({
                                    ...penalty,
                                    lcu30DayLetterDate: d
                                })} />
                            </div>

                            <div className="due-date-container">
                                <PenaltyLabel value={`the ${penalty.section} "applicable date"`} enabled={penalty.enabled} type="normal" />
                                <RDatePicker enabled={penalty.enabled ? penalty.enabled : false} value={penalty.applicableDate ?? today} onChange={d => setPenalty({
                                    ...penalty,
                                    applicableDate: d
                                })} />
                            </div>

                        </div>

                    </div>

                    <div className="minimum-penalty-rule">
                        <div className="left-container">
                            <div className="penalty-activation-conatiner">
                                <PenaltyLabel value={`Stop "Hot" interest on 12-31-97`} enabled={penalty.enabled} type="header" />
                                <IOSSwitch disabled={!penalty.enabled} checked={penalty.restartEnabled ? penalty.restartEnabled : false} onChange={e => setPenalty({
                                    ...penalty,
                                    restartEnabled: e.target.checked
                                })} />
                            </div>

                            <div className="return-date-container">
                                <PenaltyLabel value={`Either enter the "30 day" letter date, or`} enabled={penalty.enabled} type="normal" />
                                <RDatePicker enabled={penalty.enabled ? penalty.enabled : false} value={penalty.lcu30DayRestartLetterDate ?? today} onChange={d => setPenalty({
                                    ...penalty,
                                    lcu30DayRestartLetterDate: d
                                })} />

                            </div>

                            <div className="due-date-container">
                                <PenaltyLabel value={`the ${penalty.section} "applicable date"`} enabled={penalty.enabled} type="normal" />
                                <RDatePicker enabled={penalty.enabled ? penalty.enabled : false} value={penalty.restartApplicableDate ?? today} onChange={d => setPenalty({
                                    ...penalty,
                                    restartApplicableDate: d
                                })} />
                            </div>

                        </div>

                    </div>

                    {/* <div className="show-tips">
                        <PenaltyLabel value={`Show tips?`} enabled={penalty.enabled} type="header" />
                        <div className="show-tips-switch">
                        <IOSSwitch disabled={!penalty.enabled} checked={penalty.showTips} onChange={e => setPenalty({
                            ...penalty,
                            showTips: e.target.checked
                        })} />
                        </div>
                    </div>
                    {penalty.showTips && <Tips />} */}
                </div>

                <div className="action-buttons">
                            <button className='action-button cancel' type="Cancel" value={"Cancel"} onClick={onClickCancel}>Cancel</button>
                            <button className='action-button save'  type="Save" value={"Save"} onClick={onClickOk}>Save</button>
                </div>
                <div className="accordion">
                    <div className="accordion-header" onClick={toggleAccordion}>
                        <PenaltyLabel value={'Penalty Details'} enabled={true} type="header" />
                        <div className="icon">
                        {isOpen ? <img src={AccordionMinus} alt="Close Icon" /> : <img src={AccordionPlus} alt="Close Icon" />}
                        </div>
                    </div>
                    {isOpen && (
                        <div className="accordion-content">
                            <Tips />
                        </div>
                    )}
                </div>
                <PenaltyDiscardAlert title={'Discard Unsaved Changes'} message={'If you navigate away from this page, you will lose your changes.'} open={showAlert} onClose={onCloseErrorAlert} onSave={onSave} onDiscard={onDiscard} />
            </DialogContent>
        </Dialog>
    )
}

export default Penalty6621C