import React from 'react'
import './Sidebar.css'
import NewIcon from '../../assets/plus.svg'
import profileIcon from '../../assets/user-profile.svg'
import OpenIcon from '../../assets/open-icon.svg'
import BalanceIcon from '../../assets/balance-icon.svg'
import PenaltiesIcon from '../../assets/penalties-icon.svg'
import InterestIcon from '../../assets/interest-icon.svg'
import CCorpIcon from '../../assets/c-corp-icon-new.svg'

const UserSidebar = (props) => {

  const sidemenu = [
    {
      name: "User List",
      icon: profileIcon
    },
    {
      name: "Create User",
      icon: profileIcon
    },
    {
      name: "Manage License",
      icon: profileIcon
    },
    {
      name: "Manage Defaults",
      icon: profileIcon
    },
  ]

  return (
    <div className='sidebar-container'>
      <div className="sidebar-menu-list">
      {
        sidemenu.map((item, index) => {
          return (
            <div className={`sidebar-menu`} key={index} onClick={()=>  props.onSelectedMenu(index)}>
              <img className={`sidebar-menu-icon`}  src={item.icon} alt={item.name} color='white' />
              <span className= {`sidebar-menu-name`} > {item.name}</span>
            </div>
          )
        })
      }
       </div>
    </div>
  )
}

export default UserSidebar