// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px #E8E8E8 solid;
  height: 51px;
}

.left-logo{
  padding-left: 30px;
}

.right-settings-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding-right: 30px;
}

.email-id {
  color: "#010C12";
  font-size: 14;
  font-family: "Inter";
  font-weight: 500;
  word-wrap: "break-word";
  margin-bottom: 6px;
  cursor: pointer;
}

.left-logo, .settings-icon{
  cursor: pointer;
}

.email-dropdown-icon img {
  padding-left: 15px;
  width: 12px;
}

.settings-menu-item {
  cursor: pointer;
  color: "#010C12";
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
}

/* .MuiPopover-paper{
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05) !important;
  border-radius: 15px !important;
  margin: 0px ;
  width: 182px ;
  height: 105px ; 
} */`, "",{"version":3,"sources":["webpack://./src/components/Topbar/Topbar.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gCAAgC;EAChC,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,oBAAoB;EACpB,gBAAgB;EAChB,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;;;;;;GAMG","sourcesContent":["\n.header-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px #E8E8E8 solid;\n  height: 51px;\n}\n\n.left-logo{\n  padding-left: 30px;\n}\n\n.right-settings-container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 15px;\n  padding-right: 30px;\n}\n\n.email-id {\n  color: \"#010C12\";\n  font-size: 14;\n  font-family: \"Inter\";\n  font-weight: 500;\n  word-wrap: \"break-word\";\n  margin-bottom: 6px;\n  cursor: pointer;\n}\n\n.left-logo, .settings-icon{\n  cursor: pointer;\n}\n\n.email-dropdown-icon img {\n  padding-left: 15px;\n  width: 12px;\n}\n\n.settings-menu-item {\n  cursor: pointer;\n  color: \"#010C12\";\n  font-size: 14px;\n  font-family: \"Inter\";\n  font-weight: 500;\n}\n\n/* .MuiPopover-paper{\n  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05) !important;\n  border-radius: 15px !important;\n  margin: 0px ;\n  width: 182px ;\n  height: 105px ; \n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
