import React, { useEffect, useState, useRef } from 'react'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import { Button, Paper, DialogTitle } from '@mui/material';
import './Penalties.css'
import Penalty6651A1 from './penalty6651A1/Penalty6651A1';
import Penalty6651A2 from './penalty6651A2/Penalty6651A2';
import Penalty6651A3 from './penalty6651a3/Penalty6651A3';
import Penalty6662C from './penalty6662c/Penalty6662C';
import Penalty6662D from './penalty6662d/Penalty6662D';
import Penalty6662E from './penalty6662e/Penalty6662E';
import Penalty6662F from './penalty6662f/Penalty6662F';
import Penalty6662G from './penalty6662g/Penalty6662G';
import Penalty6663 from './penalty6663/Penalty6663';
import PenaltyDiscardAlert from '../PenaltyDiscardAlert';


import IOSSwitch from '../IOSSwitch/IOSSwitch'

import PenaltyCloseIcon from '../../assets/penalty-close-icon.svg';

import toast, { Toaster } from 'react-hot-toast';
import Draggable from "react-draggable";

const Penalties = (props) => {
    const { index } = props;
    const childRef = useRef();
    const [oldValue, setOldValue]= useState(null);
    const [penalties, setPenalties] = useState(props.values);
    const [penalty, setPenalty] = useState(null);
    const [openPenalty, setOpenPenalty] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState(index);
    const [selectedPenalty, setSelectedPenalty] = useState(null);
    const [selectedPenaltyComponent, setSelectedPenaltyComponent] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [changesAvailable, setChangesAvailable] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [changeIndex, setChangeIndex] = useState(null);
    const [updatedValue, setUpdatedValue]= useState(null);
    
    useEffect(() => {
        updateSelectedPenalty();
    }, [selectedRowIndex, penalties])
    const openPenaltyForm = () => {
        setSelectedPenalty(penalties[selectedRowIndex]);
        setOpenPenalty(true);
    }

    const closePenaltyForm = () => {
        setSelectedPenalty(null);
        setOpenPenalty(false);
        props.onClose();
    }

    const updateSelectedPenalty = () => {
        const r = selectedRowIndex;
        setActiveIndex(r);
        setSelectedPenalty(penalties[r]);
        setPenalty(penalties[r]);
        let selectedPenaltyComponents = null;
        if (r == 0) {
            selectedPenaltyComponents = <Penalty6651A1 value={penalties[r]} onClose={closePenaltyForm} onSubmit={onSubmitPenalty} setHasUnsavedChanges={setHasUnsavedChanges} ref={childRef}/>;
        } else if (r === 1) {
            selectedPenaltyComponents = <Penalty6651A2 value={penalties[r]} onClose={closePenaltyForm} onSubmit={onSubmitPenalty} ref={childRef}/>;
        }
        else if (r === 2) {
            selectedPenaltyComponents = <Penalty6651A3 value={penalties[r]} onClose={closePenaltyForm} onSubmit={onSubmitPenalty} ref={childRef} />;
        }
        else if (r === 3) {
            selectedPenaltyComponents = <Penalty6662C value={penalties[r]} onClose={closePenaltyForm} onSubmit={onSubmitPenalty} ref={childRef} />;
        }
        else if (r === 4) {
            selectedPenaltyComponents = <Penalty6662D value={penalties[r]} onClose={closePenaltyForm} onSubmit={onSubmitPenalty} ref={childRef} />;
        }
        else if (r === 5) {
            selectedPenaltyComponents = <Penalty6662E value={penalties[r]} onClose={closePenaltyForm} onSubmit={onSubmitPenalty} ref={childRef} />;
        }
        else if (r === 6) {
            selectedPenaltyComponents = <Penalty6662F value={penalties[r]} onClose={closePenaltyForm} onSubmit={onSubmitPenalty} ref={childRef} />;
        }
        else if (r === 7) {
            selectedPenaltyComponents = <Penalty6662G value={penalties[r]} onClose={closePenaltyForm} onSubmit={onSubmitPenalty} ref={childRef} />;
        }
        else if (r === 8) {
            selectedPenaltyComponents = <Penalty6663 value={penalties[r]} onClose={closePenaltyForm} onSubmit={onSubmitPenalty} ref={childRef} />;
        }
        setSelectedPenaltyComponent(selectedPenaltyComponents);
    }

    useEffect(() => {
        setPenalty(props.value); // Update penalty state when props.value changes
    }, [props.value]);

    const onSubmitPenalty = (p) => {
        setChangesAvailable(false);
        setHasUnsavedChanges(false);
        const ps = penalties.map(penalty => {
            if (penalty.id === p.id) {
                return p;
            } else {
                return penalty;
            }
        });
        setSelectedPenalty(null);
        setPenalties(ps);
        props.onChangedPenalties(ps);
        toast('Penalty changes updated', { duration: 1500 });
    }
    const onSave  = () => {
        //const updatedValue = childRef.current.getValue();
        console.log("onsave",updatedValue );
        onSubmitPenalty(updatedValue);
        setOldValue(null);
        setShowAlert(false);
        setSelectedRowIndex(changeIndex)
    }
    const onSelectedPenalty = (i) => {
        
        if (childRef.current) {
        const updateValue = childRef.current.getValue();
        console.log("updateValue"+updateValue);
        const ps = penalties.map(penalty => {
            if (penalty.id === updateValue.id) {
                return { ...penalty, showTips: updateValue.showTips };
            } else {
                return penalty;
            }
        });
        setPenalties(ps);
        const { showTips: _, ...restUpdateValue } = updateValue;
        const { showTips: __, ...restPenalty } = penalties[selectedRowIndex];
        if(oldValue!==null) {
            setUpdatedValue(updateValue);
            const ps = penalties.map(penalty => {
                if (penalty.id === updateValue.id) {
                    return updateValue;
                } else {
                    return penalty;
                }
            });
            setPenalties(ps);
            setShowAlert(true);
            setChangeIndex(i);
        } else if (JSON.stringify(restUpdateValue) !== JSON.stringify(restPenalty)) {
            setUpdatedValue(updateValue);
            setOldValue(penalties[selectedRowIndex]);
            const ps = penalties.map(penalty => {
                if (penalty.id === updateValue.id) {
                    return updateValue;
                } else {
                    return penalty;
                }
            });
            setPenalties(ps);
            setShowAlert(true);
            setChangeIndex(i);
            console.log("updatedValue", updateValue);
        } else {
            setSelectedRowIndex(i);
        }
        } else {
            setSelectedRowIndex(i);
        }
       
        // if(hasUnsavedChanges) {
        //     //setChangeIndex(i);
        //     setShowAlert(true);
        //     //toast('Please save your changes before enabling or disabling penalties', { duration: 3000 });
        //     return; 
        // }
        // if(changesAvailable && i !== selectedRowIndex){
        //     toast('Please save your changes before enabling or disabling penalties', { duration: 3000 });
        //     return;
        // }
       // setSelectedRowIndex(i);
    }

    const onDiscard = () => {
        let updateValue = null;
        if (childRef.current) {
            updateValue = childRef.current.getValue(); 
        }
        const ps = penalties.map(penalty => {
            if (penalty.id === oldValue.id) {
                if(updateValue!==null){
                    return { ...oldValue, showTips: updateValue.showTips };
                } else {
                    return oldValue;
                }
            } else {
                if(updateValue!==null){
                    return { ...penalty, showTips: updateValue.showTips };
                } else {
                    return penalty;
                }
            }
        });
       
        
        setPenalties(ps);
        // if (childRef.current) {
        //     const updateValue = childRef.current.getValue(); 
        //     const ps = penalties.map(penalty => {
        //         if (penalty.id === updateValue.id) {
        //             return { ...penalty, showTips: updateValue.showTips };
        //         } else {
        //             return penalty;
        //         }
        //     });
        //     setPenalties(ps);
        // }
        
        setOldValue(null);
        setShowAlert(false);
        setChangesAvailable(false);
        setSelectedRowIndex(changeIndex);
    }

    const onChangePenaltyStatus = (i, checked) => {
        // console.log("setHasUnsavedChanges"+setHasUnsavedChanges);
        // if(changesAvailable && i !== selectedRowIndex){
        //     toast('Please save your changes before enabling or disabling penalties', { duration: 3000 });
        //     return;
        // }
        //setChangesAvailable(true);

        if (childRef.current) {
            console.log("test here");
            const updateValue = childRef.current.getValue();
            
            const { showTips: _, ...restUpdateValue } = updateValue;
            const { showTips: __, ...restPenalty } = penalties[selectedRowIndex];
            if(oldValue!==null) {
                console.log("oldvanull");
                setUpdatedValue(updateValue);
                const ps = penalties.map(penalty => {
                    
                    if (penalty.id === updateValue.id) {
                        return updateValue;
                    } else {
                        return penalty;
                    }
                });
                setPenalties(ps);
                setShowAlert(true);
                setChangeIndex(i);
            } else if (JSON.stringify(restUpdateValue) !== JSON.stringify(restPenalty)) {
                console.log("oldvanull 2");
                setUpdatedValue(updateValue);
                setOldValue(penalties[selectedRowIndex]);
                const ps = penalties.map(penalty => {
                    
                    if (penalty.id === updateValue.id) {
                        return updateValue;
                    } else {
                        return penalty;
                    }
                });
                setPenalties(ps);
                setShowAlert(true);
                setChangeIndex(i);
                console.log("updatedValue", updateValue);
            } 
            else {
                console.log("else oldvanull");
                console.log("i"+i);
                const p = penalties[i];
                const ps = penalties.map(penalty => {
                    if (penalty.id === updateValue.id && penalty.id === p.id) {
                        return { ...penalty, showTips: updateValue.showTips, enabled: checked};
                    }
                    else if (penalty.id === p.id) {
                        return { ...p, enabled: checked };
                    } else {
                        return penalty;
                    }
                });
                setPenalties(ps);
                setSelectedRowIndex(i);
                props.onChangedPenalties(ps);
            }
        } else {
            console.log("i"+i);
            const p = penalties[i];
            const ps = penalties.map(penalty => {
                if (penalty.id === p.id) {
                    return { ...p, enabled: checked };
                } else {
                    return penalty;
                }
            });
            setPenalties(ps);
            setSelectedRowIndex(i);
            props.onChangedPenalties(ps);
        }
       
    }

    const onClose = () => {
        props.onClose();
    }
    const onCloseErrorAlert = () => {
        setShowAlert(false);
    }
    function PaperComponent(props) {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }
    return (
        <div>
            <PenaltyDiscardAlert title={'Discard Unsaved Changes'} message={'If you navigate away from this page, you will lose your changes.'} open={showAlert} onDiscard={onDiscard} onSave={onSave} onClose={onCloseErrorAlert} />
            <Dialog
                hideHeader={true}
                open={props.onOpen}
                onClose={onClose}
                maxWidth={'sm'}
                scroll={'paper'}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                PaperProps={{
                    sx: {
                        width: "80%",
                        maxWidth: "85%",
                        maxHeight: "85%",
                        borderRadius: "15px"
                    }
                }}>
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">

                    <div className="title-section">
                        <div><span>Penalty Selection</span></div>
                        <div className="title-section-closen">
                            <div><img className='penalty-close-icon' width={30} height={30} src={PenaltyCloseIcon} onClick={onClose} alt="close-icon" /></div></div>
                    </div>
                </DialogTitle>
                <DialogContent dividers={true}>

                    <div className="penalty-popup">
                        <div className={"penality-menu"}>

                            <div className="penalties-list">

                                {
                                    penalties.map((penality, index) =>
                                        <PenaltyRow key={index} enabled={penality.enabled} onChangePenaltyStatus={onChangePenaltyStatus} penality={penality} activeIndex={activeIndex} index={index} onSlelect={onSelectedPenalty} />
                                    )
                                }
                            </div>
                        </div>
                        <div className="penalty-content">
                            {selectedPenaltyComponent}
                        </div>
                    </div>
                    { /*<div className="action-buttons">
                            <button className='action-button cancel' type="Cancel" value={"Cancel"} onClick={props.onClose}>Cancel</button>
                            <button className='action-button save'  type="Save" value={"Save"} onClick={openPenaltyForm}>Save</button>
                                </div>*/}
                </DialogContent>

                {/* <DialogActions>
                    <Button onClick={openPenaltyForm} sx={{ border: 1, width: 75, height: 30 }}>Edit</Button>
                    <Button onClick={props.onClose} sx={{ border: 1, width: 75, height: 30 }}>Close</Button>
                </DialogActions> */}
            </Dialog>

            {/* {selectedPenalty && selectedPenalty.id === 1 && <Penalty6651A1 value={selectedPenalty} onOpen={openPenalty} onClose={closePenaltyForm} title={selectedPenalty.name} onSubmit={onSubmitPenalty} />}
            {selectedPenalty && selectedPenalty.id === 2 && <Penalty6651A2 value={selectedPenalty} onOpen={openPenalty} onClose={closePenaltyForm} title={selectedPenalty.name} onSubmit={onSubmitPenalty} />}
            {selectedPenalty && selectedPenalty.id === 3 && <Penalty6651A3 value={selectedPenalty} onOpen={openPenalty} onClose={closePenaltyForm} title={selectedPenalty.name} onSubmit={onSubmitPenalty} />}
            {selectedPenalty && selectedPenalty.id === 4 && <Penalty6662C value={selectedPenalty} onOpen={openPenalty} onClose={closePenaltyForm} title={selectedPenalty.name} onSubmit={onSubmitPenalty} />}
            {selectedPenalty && selectedPenalty.id === 5 && <Penalty6662D value={selectedPenalty} onOpen={openPenalty} onClose={closePenaltyForm} title={selectedPenalty.name} onSubmit={onSubmitPenalty} />}
            {selectedPenalty && selectedPenalty.id === 6 && <Penalty6662E value={selectedPenalty} onOpen={openPenalty} onClose={closePenaltyForm} title={selectedPenalty.name} onSubmit={onSubmitPenalty} />}
            {selectedPenalty && selectedPenalty.id === 7 && <Penalty6662F value={selectedPenalty} onOpen={openPenalty} onClose={closePenaltyForm} title={selectedPenalty.name} onSubmit={onSubmitPenalty} />}
            {selectedPenalty && selectedPenalty.id === 8 && <Penalty6662G value={selectedPenalty} onOpen={openPenalty} onClose={closePenaltyForm} title={selectedPenalty.name} onSubmit={onSubmitPenalty} />}
            {selectedPenalty && selectedPenalty.id === 9 && <Penalty6663 value={selectedPenalty} onOpen={openPenalty} onClose={closePenaltyForm} title={selectedPenalty.name} onSubmit={onSubmitPenalty} />}  */}
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </div>
    )
}

export default Penalties



const PenaltyRow = (props) => {

    const [active, setActive] = useState(props.enabled);

    const checkboxRef = useRef(null);

    const onChange = (e) => {
        e.stopPropagation();
        props.onChangePenaltyStatus(props.index, e.target.checked);
    }

    const onClick = () => {
        props.onSlelect(props.index);
    }

    const penalty = props.penality
    return (
        <div className={`penality-row ${props.activeIndex === props.index ? 'active' : ''} ${props.index === props.selectedRowIndex || penalty.enabled ? 'selected' : ''}`} onClick={onClick}>
            <div className="penality-row-section">
                <div className="penalty-row-title">
                    {penalty.name}
                </div>
                <div className="penalty-irs-section">
                    <div className="penalty-irs-section-value">
                        IRC - {penalty.section}
                    </div>
                    <div className="penalty-selection-switch">
                        <IOSSwitch onClick = {onChange} checked={penalty.enabled}  />
                    </div>
                </div>
            </div>
        </div>
    )
}
