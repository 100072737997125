// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report{
    font-family: 'Inter';
}
.result-title{
    display: flex;
    font-weight: 500;
    padding-bottom: 20px;
    justify-content: center;
}
.re-compute-section{
    display: flex;
    align-items: center;
    gap: 10px;
    
}
.compute-result-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}
.compute-result-label{
    font-weight: 500;
}
.compute-result-value{
    font-weight: 500;
}
.csvLink{
    color: black;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    height: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/components/compute/Compute.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,gBAAgB;IAChB,oBAAoB;IACpB,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;;AAEb;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,iBAAiB;IACjB,oBAAoB;AACxB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,qBAAqB;IACrB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;AAChB","sourcesContent":[".report{\n    font-family: 'Inter';\n}\n.result-title{\n    display: flex;\n    font-weight: 500;\n    padding-bottom: 20px;\n    justify-content: center;\n}\n.re-compute-section{\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    \n}\n.compute-result-row {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n.compute-result-label{\n    font-weight: 500;\n}\n.compute-result-value{\n    font-weight: 500;\n}\n.csvLink{\n    color: black;\n    text-decoration: none;\n    align-items: center;\n    justify-content: center;\n    height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
