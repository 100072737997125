
import useToday from "./useToday";

const usePenalties = ()=> {
    const penalties = [
        {
          id: 1,
          name: "Failure to File Return When Due",
          section: '6651(a)(1)',
          enabled: false,
          amount: 0.0,
          dueDate: useToday(),
          returnDate: useToday(),
          showTips: true,
          useMinimumPenalty: false,
          dueToFraud: false
        },
        {
          id: 2,
          name: "Failure to Pay Tax Shown on Return",
          section: '6651(a)(2)',
          enabled: false,
          amount: 0.0,
          dueDate: useToday(),
          isIndividualReturn: false,
          reducePenaltyAmount: '',
          reInstatePenalty: '',
          showTips: true,
          latePayments: [
            {
              title: 'Date of 1st late payment notice (if any)',
              date:  useToday(),
              was1stNoticeOfLevy: "No",
              was1stJeopardyDemand: "No",
              gracePeriod: '10 Days'
            }
          ]
        },
    
        {
          id: 3,
          name: 'Failure to Pay Amount Assessed',
          section: '6651(a)(3)',
          enabled: false,
          amount: 0.0,
          isIndividualReturn: false,
          reducePenaltyAmount: '',
          reInstatePenalty: '',
          showTips: true,
          latePayments: [
            {
              date:  useToday(),
              was1stNoticeOfLevy: "No",
              was1stJeopardyDemand: "No",
              gracePeriod: '10 Days'
            }
          ]
        },
        {
          id: 4,
          name: 'Negligence',
          section: '6662(c)',
          enabled: false,
          amount: 0.0,
          dueDate: useToday(),
          showTips: true
        },
        {
          id: 5,
          name: 'Substantial Understatement of Income Tax',
          section: '6662(d)',
          enabled: false,
          amount: 0.0,
          dueDate: useToday(),
          showTips: true
        },
        {
          id: 6,
          name: 'Substantial Valuation Misstatement',
          section: '6662(e)',
          enabled: false,
          amount: 0.0,
          dueDate: useToday(),
          showTips: true,
          percentage: 20
        },
        {
          id: 7,
          name: 'Overstatement of Pension Liablities',
          section: '6662(f)',
          enabled: false,
          amount: 0.0,
          dueDate: useToday(),
          showTips: true,
          percentage: 20
        },
        {
          id: 8,
          name: 'Valuation Understatement -- Estate/Gift',
          section: '6662(g)',
          enabled: false,
          amount: 0.0,
          dueDate: useToday(),
          showTips: true,
          percentage: 20
        },
        {
          id: 9,
          name: 'Fraud',
          section: '6663',
          enabled: false,
          amount: 0.0,
          dueDate: useToday(),
          showTips: true
        },
      ];

      const hotInterest = {
        enabled: false,
        name: '"Hot" Interest Information',
        section: '6621(c)',
        restartEnabled: false,
        lcu30DayLetterDate: '',
        applicableDate: '',
        lcu30DayRestartLetterDate: '',
        restartApplicableDate: '',
        showTips: true
      };

      const cCorp= {
        enabled: false,
        name: 'C Corporation Refund Issues',
        thresholdAmount: 0,
        amount: 0,
        date: useToday(),
        showTips: true
      };
      return {penalties, hotInterest, cCorp};
};
  export default usePenalties;



  