// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.penalty-6651a-main-container{
    display: flex;
    flex-direction: column;
    border: 1px #E8E8E8 solid;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 25px;
    border-radius: 10px;
}
.penalty-content .MuiDialogContent-root {
  padding: 0px 24px;
}
.penalty-content .MuiDialogContent-root > .header{
  display: none;
}
.active-penalty-left-container, .bottom-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.penalty-activation-conatiner,.minimum-penalty-rule-activation, .failure-to-fraud-activation-conatiner, .show-tips{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.action-button{
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    height: 37px;
    width: 180px;
    border: none;
    cursor: pointer;
  }
  .action-button.save{
    color: white;
    background-color: #534F86;
  }
  
  .action-button.cancel{
    color: #534F86;
    background-color: #EDEDF0;
  }
  .tips-text p {
    margin-block-start: 1px;
  }
  .tips-text-sub {
    margin-left: 3px;
  }
  .sub-text {
    margin-left: 15px;
    line-height: 23px;
  }
  .tips-text p:last-child {
    margin-block-end: 0px;
  }


`, "",{"version":3,"sources":["webpack://./src/components/penalties/penalty6651A1/Penalty6651A1.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;IACpB,mBAAmB;IACnB,mBAAmB;AACvB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;AACf;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,eAAe;EACjB;EACA;IACE,YAAY;IACZ,yBAAyB;EAC3B;;EAEA;IACE,cAAc;IACd,yBAAyB;EAC3B;EACA;IACE,uBAAuB;EACzB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,iBAAiB;IACjB,iBAAiB;EACnB;EACA;IACE,qBAAqB;EACvB","sourcesContent":[".penalty-6651a-main-container{\n    display: flex;\n    flex-direction: column;\n    border: 1px #E8E8E8 solid;\n    padding-left: 20px;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    padding-right: 25px;\n    border-radius: 10px;\n}\n.penalty-content .MuiDialogContent-root {\n  padding: 0px 24px;\n}\n.penalty-content .MuiDialogContent-root > .header{\n  display: none;\n}\n.active-penalty-left-container, .bottom-container{\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n\n.penalty-activation-conatiner,.minimum-penalty-rule-activation, .failure-to-fraud-activation-conatiner, .show-tips{\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n}\n\n.action-button{\n    font-family: \"Inter\";\n    font-size: 14px;\n    font-weight: 600;\n    border-radius: 8px;\n    height: 37px;\n    width: 180px;\n    border: none;\n    cursor: pointer;\n  }\n  .action-button.save{\n    color: white;\n    background-color: #534F86;\n  }\n  \n  .action-button.cancel{\n    color: #534F86;\n    background-color: #EDEDF0;\n  }\n  .tips-text p {\n    margin-block-start: 1px;\n  }\n  .tips-text-sub {\n    margin-left: 3px;\n  }\n  .sub-text {\n    margin-left: 15px;\n    line-height: 23px;\n  }\n  .tips-text p:last-child {\n    margin-block-end: 0px;\n  }\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
