import React, { useEffect, useRef, useState } from 'react'

import './AmountTextField.css'
import CurrencyInput from 'react-currency-input-field';

const AmountTextField = (props) => {
  const [value, setValue] = useState(props.value);

  return (
    <div>
      <CurrencyInput
        disabled={props.disabled}
        decimalScale={2}
        id="input-example"
        name="input-name"
        // fixedDecimalLength = {2}
        defaultValue={value}
        decimalsLimit={2}
        className='amount-text-field'
        onClick={(e) => e.target.select()}
        onValueChange={(value, name, values) => props.onChange(value)}

      />
    </div>
  )
}

export default AmountTextField