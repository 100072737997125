import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PenaltyCloseIcon from '../assets/penalty-close-icon.svg';

export default function AlertDialogWithClose(props) {


  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        PaperProps={{
          sx: {
             
              borderRadius: "15px"
          }
      }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
                    <div className="title-section">
                        <span style={{ fontFamily: 'Inter', fontSize: 20, fontWeight: 500, display: 'flex', justifyContent: 'space-between' }}> {props.title}</span>
                        <img className='penalty-close-icon' onClick={props.onCancel} width={30} height={30} src={PenaltyCloseIcon} alt="close-icon" />
                    </div>
                </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              props.message
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{
    padding: '16px 24px'
  }}>
          <div style={{ display: 'flex', justifyContent: 'end', gap: 10 }}>
            <button className='action-button save secondary' type="Cancel" value={"Cancel"} onClick={props.onCancel}>Cancel</button>
            <button className='action-button save secondary' type="Save" value={"Save"} onClick={props.onSave}>Save & Close</button>
            <button className='action-button discard' type="Cancel" value={"Cancel"} onClick={props.onAccept}>Close</button>
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}