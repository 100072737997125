import React from 'react'
import './Tips.css'
const Tips = (props) => {
    return (
        <div className='tips-container'>
            <div className="tips-text">
                <p>Here’s how TaxInterest uses this information. </p>
				<p className="tips-text-sub">The negligence penalty is 20% of the amount of an underpayment attributable to negligence.</p>
				<p className="tips-text-sub">Interest on the penalty starts on the due date of the return (with extensions).</p>
				<p className="tips-text-sub">This penalty is one of the accuracy-related penalties. Amounts subject to this penalty will not be subjected to other parts of the accuracy-related penalty.</p>
				<p className="tips-text-sub">No penalty shall be imposed by reason of subsection (b)(3) unless the portion of the underpayment for the taxable year attributable to substantial valuation misstatements under chapter 1 exceeds $5,000 ($10,000 in the case of a corporation other than an S corporation or a personal holding company (as defined in section 542 )).</p>
			</div>
        </div>
    )
}

export default Tips