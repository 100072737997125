import { Dialog, DialogContent, Paper, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './CCorpPenalty.css'
import AmountTextField from '../../AmountTextField/AmountTextField';
import Tips from '../Tips/Tips';
import PenaltyLabel from '../../Label/PenaltyLabel';
import IOSSwitch from '../../IOSSwitch/IOSSwitch';
import PenaltyDiscardAlert from '../../PenaltyDiscardAlert';
import CcorpDisableAlert from '../../CcorpDisableAlert';
import useEvents from '../../../hooks/useEvents';
import Draggable from "react-draggable";
import PenaltyCloseIcon from '../../../assets/penalty-close-icon.svg';
import AccordionPlus from '../../../assets/accordion-plus.svg';
import AccordionMinus from '../../../assets/accordion-minus.svg';
const CCorpPenalty = (props) => {
    const [penalty, setPenalty] = useState(props.value);
    const [rowData, setRowData] = useState([]);
    const [ccorpEnable, setCcorpEnable] = useState(false);
    let [initialPenalty, setInitialPenalty] = useState(props.value);
    const [showAlert, setShowAlert] = useState(false)
    const [showCcorpAlert, setShowCcorpAlert] = useState(false);
    const {eventNames} = useEvents();
    const [isOpen, setIsOpen] = useState(false);
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        console.log("ccorp");
        props.rowValue.stopEditing();
        const allRowDatum = [];
        props.rowValue.forEachNode((rowNode, index) => {
            const rowData = rowNode.data;
            const datum = {}
            datum.eventType = rowData.eventType
            const utcDate = rowData.eventDate
            datum.eventDate = utcDate
            datum.eventDescription = rowData.eventDescription
            datum.eventAmount = rowData.eventAmount
            allRowDatum.push(datum)
          });
          setRowData(allRowDatum)
    }, [props.rowValue]);
    useEffect(() => {
        const handleClickOff = (event) => {
            if (event.srcElement.classList.contains('MuiDialog-container')) {
                if (JSON.stringify(penalty) !== JSON.stringify(initialPenalty)) {
                    setShowAlert(true);
                  } else {
                    props.onClose();
                  }
            }
        }
        document.addEventListener('click', handleClickOff);
    return () => {
      document.removeEventListener('click', handleClickOff);
    };
    }, [props.value, initialPenalty, penalty]);
    const onClickOk = () => {
        props.onSubmit(penalty);
        props.onClose()
    }
    const currencyFormatter = (amount) => {
        return parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      };
    const onClickCancel = () => {
        if (JSON.stringify(penalty) !== JSON.stringify(initialPenalty)) {
            setShowAlert(true);
          } else {
            props.onClose();
          }
    }
    const onSave = () => {
        onClickOk()
        props.onClose()
    }

    const onDiscard = () => {
        props.onClose()
    }
    const onCloseErrorAlert = () => {
        setShowAlert(false)
    }
    const onCloseCorpAlert = () => {
        setShowCcorpAlert(false)
    }
    
    const onCheckBoxChanged = (e) => {
        // {rowData.filter(item => item.eventType === "IRS Check").map((item, index) => (
        //     setCcorpEnable(true)
        // ))}
            const p = {
                ...penalty,
                enabled: e.target.checked,
            }
            setPenalty(p);
       
    }
    function PaperComponent(props) {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }

    return (
        <Dialog
            open={props.onOpen}
            tabIndex={0}
            onClose={onClickCancel}
            maxWidth={'lg'}
            scroll={'paper'}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={{
                sx: {
                    width: "50%",
                    maxHeight: "80%",
                    borderRadius: "15px"
                }
            }}>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <div className="title-section">
                    <PenaltyLabel value={penalty.name} enabled={true} type={"header"} />
                    <div className="title-section-closen">
                        <div><img className='penalty-close-icon' width={30} height={30} src={PenaltyCloseIcon} onClick={onClickCancel} alt="close-icon" /></div></div>
                </div>
            </DialogTitle>
            <DialogContent>
                
                <div className="penalty6621c">
                    <div className="penalty-activation-conatiner">
                        <PenaltyLabel value={'Treat taxpayer as a C Corporation. (1% refund rate reduction.)'} enabled={true} type="header" />
                        <IOSSwitch checked={penalty.enabled ? penalty.enabled : false} onChange={onCheckBoxChanged} />

                    </div>

                    <PenaltyLabel value={` C Corporation refunds in excess of a threshold amount are subject to "GATT" refund rates. The default threshold is $10,000, but you can change that amount here.`} enabled={penalty.enabled} type="normal" />

                    <div className="threshold-container">
                        <PenaltyLabel value={' GATT threshold amount:'} enabled={penalty.enabled} type="header" />
                        <AmountTextField value={penalty.threshHoldAmount} disabled={!penalty.enabled} onChange={v => setPenalty({
                            ...penalty,
                            thresholdAmount: v
                        })} />
                    </div>

                    <div className={!penalty.enabled && "disabled-text"}>
                    </div>

                    <PenaltyLabel value={`When a taxpayer receives a refund or credit, the payment or credit reduces either the GATT rate or the high tate portion of any remaining refund balance. You can use the IRS Check event to allocate the amount that reduces the high rate.
`} enabled={penalty.enabled} type="normal" />
                    <div className="irs-check-container">
                        <div className="irs-header-container">
                            <div className={penalty.enabled ? "event-header" : "event-header-disabled"}>
                                Event
                            </div>
                            <div className={penalty.enabled ? "event-header" : "event-header-disabled"}>
                                Date
                            </div>
                            <div className={penalty.enabled ? "event-header" : "event-header-disabled"}>
                                Total Amount of IRS Check
                            </div>
                            <div className={penalty.enabled ? "event-header" : "event-header-disabled"}>
                                Reduction of high rate portion
                            </div>
                        </div>
                        <hr />
                        {/*
                        <div className="irs-header-container">
                            <div className={penalty.enabled ? "event-value" : "event-value-disabled"}>
                                Check from IRS
                            </div>
                            <div className={penalty.enabled ? "event-value" : "event-value-disabled"}>
                                {penalty.date}
                            </div>
                            <div className={penalty.enabled ? "event-value" : "event-value-disabled"}>
                                {penalty.amount}
                            </div>
                            <div className={penalty.enabled ? "event-value" : "event-value-disabled"}>
                                <AmountTextField value={penalty.amount} disabled={!penalty.enabled} onChange={v => setPenalty({
                                    ...penalty,
                                    amount: v
                                })} />
                            </div>
                        </div>
                            */}
                        {rowData.filter(item => item.eventType === "IRS Check").map((item, index) => (
                                <div className="irs-header-container">
                                    <div className={penalty.enabled ? "event-value" : "event-value-disabled"}>
                                        Check from IRS
                                    </div>
                                    <div className={penalty.enabled ? "event-value" : "event-value-disabled"}>
                                        {item.eventDate}
                                    </div>
                                    <div className={penalty.enabled ? "event-value" : "event-value-disabled"}>
                                        {currencyFormatter(item.eventAmount)}
                                    </div>
                                    <div className={penalty.enabled ? "event-value" : "event-value-disabled"}>
                                        <AmountTextField value={penalty.amount} disabled={!penalty.enabled} onChange={v => setPenalty({
                                            ...penalty,
                                            amount: v
                                        })} />
                                    </div>
                                </div>
                        ))}
                    </div>

                    {/* <div className="show-tips">
                        <PenaltyLabel value={`Show tips?`} enabled={penalty.enabled} type="header" />
                        <div className="show-tips-switch">
                            <IOSSwitch disabled={!penalty.enabled} checked={penalty.showTips} onChange={e => setPenalty({
                                ...penalty,
                                showTips: e.target.checked
                            })} />
                        </div>
                    </div> */}
                </div>

                <div className="action-buttons">
                    <button className='action-button cancel' type="Cancel" value={"Cancel"} onClick={onClickCancel}>Cancel</button>
                    <button className='action-button save' type="Save" value={"Save"} onClick={onClickOk}>Save</button>
                </div>
                <div className="accordion">
                    <div className="accordion-header" onClick={toggleAccordion}>
                        <PenaltyLabel value={'Penalty Details'} enabled={true} type="header" />
                        <div className="icon">
                        {isOpen ? <img src={AccordionMinus} alt="Close Icon" /> : <img src={AccordionPlus} alt="Close Icon" />}
                        </div>
                    </div>
                    {isOpen && (
                        <div className="accordion-content">
                            <Tips />
                        </div>
                    )}
                </div>
                <PenaltyDiscardAlert title={'Discard Unsaved Changes'} message={'If you navigate away from this page, you will lose your changes.'} open={showAlert} onClose={onCloseErrorAlert} onSave={onSave} onDiscard={onDiscard} />
                <CcorpDisableAlert title={'C Coporation Refund Issues'} message={'IRS Check is not available.'} open={showCcorpAlert} onClose={onCloseCorpAlert}  />
            </DialogContent>
        </Dialog>
    )
}

export default CCorpPenalty