import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mui/material'
import ExportAs from './ExportAs';
import './Compute.css';
const Report = (props) => {

  const [openExportDialog, setOpenExportDialog] = useState(false);

  const onClose = () => {
    setOpenExportDialog(false);
  }
  const onOpenExportDialog = () => {
    setOpenExportDialog(true);
  }
  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="report-container">
      <Dialog
        open={props.openReport}
        onClose={props.onCloseReport}
        maxWidth={'lg'}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" >Report</DialogTitle>
        <DialogContent dividers={true}>
          <div dangerouslySetInnerHTML={{ __html: props.value }} />
        </DialogContent>
        <DialogActions class="no-print">
        <button className='action-button save' onClick={handlePrint}>Print</button>
        {/* <button className='action-button save' onClick={onOpenExportDialog}>Export</button> */}
          <button className='action-button cancel' onClick={props.onCloseReport}>Cancel</button>
        </DialogActions>

      </Dialog>
      {
        openExportDialog && <ExportAs report = {props.value} open={openExportDialog}  onClose = {onClose}/>
      }
    </div>


  )
}

export default Report