// useLocalStorage.js
import { useState } from 'react';

const useLocalStorage = (key, initialValue) => {
  // Initialize state with the value from Local Storage or the initial value
 
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue && storedValue!=='undefined' ? JSON.parse(storedValue) : initialValue;
  });

  // Update Local Storage whenever the state value changes
  const setStoredValue = (newValue) => {
    setValue(newValue);
    localStorage.setItem(key, JSON.stringify(newValue));
  };

  return [value, setStoredValue];
};

export default useLocalStorage;