import React, { useState } from 'react'
import './Topbar.css'
import TimeValueLogo from '../../assets/time-value-logo.svg'
import TaxInterestLogo from '../../assets/tax-interest-logo.svg'
import DropDwon from '../../assets/drop-down.svg'
import { Popover } from '@mui/material';
import SettingsIcon from '../../assets/settings-pop-over-icon.svg';
import LogoutIcon from '../../assets/logout-pop-over-icon.svg';
import { useNavigate } from 'react-router-dom';
import PopoverMenuItem from '../PopoverMenuItem/PopoverMenuItem';
import useClerk from '../../hooks/userClerk';
import PenaltyCloseIcon from '../../assets/penalty-close-icon.svg';
import UserList from '../../components/ClerkForms/UserList'
import UserSettings from '../../components/UserSettings/UserSettings';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { UserProfile, OrganizationProfile, OrganizationList } from "@clerk/clerk-react";
import ErrorBoundary from '../..//components/ErrorBoundary';
const Topbar = ({ showTaxInterestLogo=true }) => {
    const navigate = useNavigate();
    const {signedOut, emailAddress, } = useClerk();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [openProfile, setOpenProfile] = useState(false);
    const [openOrganization, setOpenOrganization] = useState(false);
    const [openUsers, setOpenUsers] = useState(false);
    
  const handleSettingsClick = () => {
    setOpenProfile(true);
    setAnchorEl(null);
  };
  const handleOrganizationClick = () => {
    setOpenOrganization(true);
    setAnchorEl(null);
  };
  const handleUsersClick = () => {
    setOpenUsers(true);
    setAnchorEl(null);
  };
  
  
  const handleSettingsClose = () => {
    setOpenProfile(false);
  };
  const handleOrganizationClose = () => {
    setOpenOrganization(false);
  };
  const handleUsersClose = () => {
    setOpenUsers(false);
  };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOptionClick = (option) => {
        setAnchorEl(null);
        navigate("/settings");
        // Handle different options here, e.g., navigate to account settings or log out
        console.log("Selected option:", option);
    };

    const handleLogout = async() => {
        localStorage.clear();
        await  signedOut();
        setAnchorEl(null);
    }

    return (
        <div className='header-container'>
            <a href="/">
            <div className="left-logo">
                <img src={TimeValueLogo} alt="" />
            </div>
            </a>
            {showTaxInterestLogo && (
                <div className="center-logo">
                <img src={TaxInterestLogo} alt="Tax Interest Logo" />
                </div>
            )}
           
            <div className="right-settings-container">
                <div className="email-id" onClick={handleClick}>
                    {emailAddress}
                    <span className="email-dropdown-icon">
                        <img src={DropDwon} alt="dropdown icon" />
                    </span>
                </div>
                <Popover
                    id={id}
                    open={open}
                    elevation={0}
                    slotProps={{
                        paper: {
                           sx:{
                            borderRadius: '15px',
                            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.05)'
                           }
                        }
                    }}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >


                    <div className="sheet-pop-over-menu">
                        <PopoverMenuItem icon={SettingsIcon} title={'Account'} onClick={handleSettingsClick} />
                        {/* <PopoverMenuItem icon={SettingsIcon} title={'Organization Settings'} onClick={handleOrganizationClick} />
                        <PopoverMenuItem icon={SettingsIcon} title={'Manage Users'} onClick={handleUsersClick} /> */}
                        <PopoverMenuItem icon={LogoutIcon} title={'Logout'} onClick={handleLogout} />
                    </div>
                    {/* <Typography className="settings-menu-item" onClick={handleOptionClick} sx={{ p: 2 }}>Account Settings</Typography>
                    <Typography className="settings-menu-item" onClick={handleOptionClick} sx={{ p: 2 }}>Logout</Typography> */}
                </Popover>

            </div>
            <Dialog open={openProfile} onClose={handleSettingsClose} Pscroll={'paper'}
                PaperProps={{
                    sx: {
                        width: "57%",
                        maxWidth: "58%",
                        padding: 0,
                    }, 
                }} sx={{ '& .MuiDialogContent-root': {  padding:0} }}>
                <DialogContent >
                <UserProfile />
                </DialogContent>
            </Dialog>
            <Dialog open={openOrganization} onClose={handleOrganizationClose} Pscroll={'paper'}
                PaperProps={{
                    sx: {
                        width: "57%",
                        maxWidth: "58%",
                        padding: 0,
                    }, 
                }} sx={{ '& .MuiDialogContent-root': {  padding:0} }}>
                <DialogContent >

                <OrganizationProfile />

                </DialogContent>
            </Dialog>
            <Dialog open={openUsers} onClose={handleUsersClose} Pscroll={'paper'}
                PaperProps={{
                    sx: {
                        width: "57%",
                        maxWidth: "58%",
                        padding: 0,
                    }, 
                }} sx={{ '& .MuiDialogContent-root': {  padding:0} }}>
                <DialogContent >
                <UserSettings />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Topbar