// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TaxInterest from './components/TaxInterest/TaxInterest';
import UserSettings from './components/UserSettings/UserSettings'; 
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ClerkProvider } from "@clerk/clerk-react";
import { UserProvider } from './components/UserContext';
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component

import { createTheme } from '@mui/material/styles';
import FavIcon from './assets/favicon.ico';
import Favicon from "react-favicon";
import ErrorBoundary from './components/ErrorBoundary';
import { stopReportingRuntimeErrors } from "react-error-overlay";
const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
  },
});


const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const root = ReactDOM.createRoot(document.getElementById('root'));
const signUpUrl = process.env.REACT_APP_CLERK_SIGNUP_URL;

root.render(
  <React.StrictMode>
    <Favicon url={FavIcon} />
    <ErrorBoundary>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
    <UserProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path={signUpUrl} element={<App />} />
          <Route path="tax-interest" element={<ProtectedRoute><TaxInterest /></ProtectedRoute>} />
          <Route path="settings" element={<ProtectedRoute><UserSettings /></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
      </UserProvider>
    </ClerkProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
