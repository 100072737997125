import React, { useEffect, useRef, useState } from 'react'
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import axios from "axios";
import moment from 'moment';
import './FilesModal.css';
import useClerk from '../../hooks/userClerk'
import FolderIcon from '../../assets/folder.svg';
import PenaltyCloseIcon from '../../assets/penalty-close-icon.svg';
import DialogTitle from '@mui/material/DialogTitle';
import DescriptionIcon from '@mui/icons-material/Description';
import { Popover } from '@mui/material';
import PopoverMenuItem from '../PopoverMenuItem/PopoverMenuItem'; 
import DeleteIcon from '../../assets/delete-pop-over-icon.svg';
import EditIcon from '../../assets/edit-pop-over-icon.svg';
import CustomTextField from '../TextField/CustomTextField';
const FilesModal = (props) => {
    const {onSelect, oldFolderName, onListFolders, folderNames, filesList, onFolderDelete, onUpdateFolder, oldData} = props;
    console.log(oldData)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [activePopover, setActivePopover] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'popover' : undefined;
    const [activeFolderIndex, setActiveFolderIndex] = useState(0); 
    const [activeFolderName, setActiveFolderName] = useState(null);
    const [clickableFolderName, setClickableFolderName] = useState();
    const [currentPath, setCurrentPath] = useState(
        activeFolderName ? activeFolderName.split('/') : []
    );
    const [isEditing, setIsEditing] = useState(false);
    const [editingFolderName, setEditingFolderName] = useState('');
    //const [files, setFiles] = useState([]);
    const {getClerkToken} = useClerk();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const inputFolderRenameRef = useRef(null);
    const [fNames, setFNames] = useState(folderNames);
    const [parentId, setParentId] = useState(null);
    const [clickableFolderId, setClickableFolderId] = useState();
    const [showCreateSubFolder, setShowCreateSubFolder] = useState(false);
    const fetchFolders = async () => {
    try {
      const foldernames1 = await onListFolders();
      if(foldernames1[0]){
        //(foldernames1[0]);
        //setActiveFolderName(foldernames1[foldernames1.length - 1])
      } else {
       // setOldFolderName();
        setActiveFolderName('')
      }
      console.log("foldernames: ", foldernames1);
      setFNames(foldernames1); // Save to state if needed
    } catch (error) {
      console.error("Error fetching folder names: ", error);
    }
  };
    useEffect(() => {
        fetchFiles();
    }, [])
    useEffect(() => {
        //handleFolderClick(activeFolderName);
        const timeout = setTimeout(() => {
          if(isEditing){
            inputFolderRenameRef.current.select();
          }
        }, 0);
          return () => clearTimeout(timeout);
      }, [isEditing]);
      useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.target.id=="folder-rename" && e.key === 'Enter') {
                e.preventDefault();
                console.log("activeFolderName"+activeFolderName);
                console.log("editingFolderName"+editingFolderName);
                if(activeFolderName != editingFolderName) {
                    onUpdateFolder(clickableFolderName, editingFolderName);
                    const fetchFolder = fetchFolders();
                    setFNames(fetchFolder);
                }
                setIsEditing(false);
            }
        }
        if (inputFolderRenameRef.current) {
            console.log("inputFolderRef");
            inputFolderRenameRef.current.addEventListener('keydown', handleKeyDown);
          }
          return () => {
            if (inputFolderRenameRef.current) {
              inputFolderRenameRef.current.removeEventListener('keydown', handleKeyDown);
            }
          };
      });

    // useEffect(() => {
    //     const fetchFolders = async () => {
    //       try {
    //         const foldernames1 = await onListFolders();
    //         console.log("foldernames: ", foldernames1);
    //         setFolderNames(foldernames1); // Save to state if needed
    //       } catch (error) {
    //         console.error("Error fetching folder names: ", error);
    //       }
    //     };
    //     fetchFolders();
    //   },[]);
    const filterByFileKey = (key) => {
        console.log("key",key);
        if(key=="cf"){
          key = "/";
        }
        if (fNames && fNames.length > 0) {
            if(key == "/") {
                return fNames.filter(folder => folder.parentId === null);
              } else {
                console.log("filtered", fNames.filter(folder => folder.parentId === parentId));
                return fNames.filter(folder => folder.parentId === parentId);
              }
          return fNames.filter(folder => folder.parentId === key);
        } else {
          return [];
        }
       
      };

      const onShowSubFolder  = () => {
        setShowCreateSubFolder(true);
        handleClose();
      }

    const filteredFolders = filterByFileKey(activeFolderName);

    const handleBreadcrumbClick = (index, pathToPass) => {
        console.log("pathToPass"+pathToPass);
        // Go to the folder up to the clicked breadcrumb level
        setCurrentPath(currentPath.slice(0, index + 1));
        const lastPart = pathToPass.split('/').pop();
        const getParent = fNames.find(folder => folder.folderName === lastPart) || null;
        const pId = getParent ? getParent._id : null
        handleFolderClick(pathToPass, pId);
    };
    const handleFolderActive = (subfolname, pId) => {
        console.log("paid", pId);
          setClickableFolderName(subfolname);
          setClickableFolderId(pId);
         // setParentId(parentId, pId);
      };
    const handleFolderClick = (fName='',pId=null) => {
        console.log(fName);
        if(fName===''){
          console.log("if");
          fName = activeFolderName && activeFolderName !== 'cf' ? activeFolderName + '/' : '' + clickableFolderName;
          console.log(fName);
        }
        const newPath = fName.split('/').filter(value => value !== ''); // This will create an array like ['vijay', 'vijay-sub']
        const updatedPath = [...currentPath, ...newPath];
        setCurrentPath(newPath);
        setParentId(pId);
        setActiveFolderName(fName); 
      };
      const handleRightClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setActivePopover("popover");
      }
    const fetchFiles = async () => {
        const token = await getClerkToken();
        console.log(token)
        const fileurl = BASE_URL+"/api/files";
        axios.get(
            fileurl, {headers:{'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}}
        ).then((response) => {
            const updatedFiles = response.data.map(file => {
                const folName = file.fileKey.includes('/') ? file.fileKey.substring(0, file.fileKey.lastIndexOf('/')) : "cf";
                return { ...file, folName }; // Add folderName to each file object
              });
             // setFiles(updatedFiles);
        }).catch((error) => { });
    }
    const fileKeyExists = (data, key) => {
        const index = data.findIndex(item => item.fileInfo && item.fileInfo.fileKey === key);
        return index !== -1 ? index : null;
    };
    const downloadFile = async (file) => {
        const getIndex = fileKeyExists(props.data,file.fileKey);
        
        if(getIndex!==null) {
            console.log(getIndex)
            onSelect(getIndex);
            console.log("already exists");
        } else if(props.data.length >=12) {
            alert("You have reached the maximum number of files to open. Please close unused files.");
            return;
          } else {
            try {
                const token = await getClerkToken();
                console.log(token)
                const fileurl = BASE_URL+"/api/files/"+file._id+"/url";
                const response = await axios.get(encodeURI(fileurl), {headers:{'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}});
                const table = response.data;
                const eventNames = {
                    'Tax': 'TXIEventTypeTax',
                    'Payment': 'TXIEventTypePayment',
                    'Deposit as Bond': 'TXIEventTypeDeposit',
                    'Suspend Interest': 'TXIEventTypeStopInterest',
                    'Resume Interest': 'TXIEventTypeStartInterest',
                    'IRS Check': 'TXIEventTypeIRSCheck',
                    'Entered Penalty': 'TXIEventTypeUserPenalty',
                    'Motivated Tax': 'TXIEventTypeMotivatedTax',
                  };
                  const eventDataArray = table.events;
                  eventDataArray.forEach(eventData => {
                    for (const key in eventNames) {
                      eventData.eventDate = moment(eventData.eventDate).format('MM/DD/YYYY')
                      if (eventNames.hasOwnProperty(key) && eventData.eventType === eventNames[key]) {
                        eventData.eventType = key;
                        break; // Stop loop if a match is found
                      }
                      
                    }
                  });
                  
                props.onFileValueChange(file, table);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const onClickCancel = () => {
        props.onClose();
    }
    console.log(activeFolderName);
    //console.log(files);
    console.log("testing")
    console.log(filesList);

    const handleClose = () => {
        setAnchorEl(null);
        setActivePopover(null);
    };
    const handleFolderOpen = () => {
        handleClose();
          console.log("if");
          console.log(activeFolderName);console.log("if here");console.log(clickableFolderName);
          console.log("if end");
          let fName = (activeFolderName && activeFolderName !== 'cf' ? activeFolderName + '/' : '') + clickableFolderName;
          console.log(fName);
        
        const newPath = fName.split('/').filter(value => value !== ''); // This will create an array like ['vijay', 'vijay-sub']
        const updatedPath = [...currentPath, ...newPath];
        setCurrentPath(newPath);
        setActiveFolderName(fName); // Set the clicked folder as active
        // const s = getFolderKeys(fNames, fName);
        // setSubFolderArr(s);
    };
    const handleDoubleClick = () => {
        handleClose();
        setEditingFolderName(clickableFolderName);
        setIsEditing(true);
    };
    const handleFolderDelete = () => {
        console.log(activeFolderName);
        handleClose();
        const confirmDelete = window.confirm(`Are you sure you want to delete the folder: ${clickableFolderName}?`);
        if (confirmDelete)  {
          if(activeFolderName != '' && activeFolderName !='cf') {
            onFolderDelete(activeFolderName+"/"+clickableFolderName);
          } else {
            onFolderDelete(clickableFolderName);
          }
        }  
    };
    const filteredFiles = filesList.filter((file) => file.folderId == parentId);
    console.log("filteredFiles here", filteredFiles);
    console.log("filesList", filesList);
    console.log("parentId",parentId);
    return (

        <div>
            <Popover
        id={id}
        open={open}
        elevation={0}
        slotProps={{
          paper: {
             sx:{
              width: '210px',
              borderRadius: '15px',
              marginLeft: '5px',
              boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.05)'
             }
          }
      }}
        anchorEl={anchorEl}
        
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'left',
          horizontal: 'right',
        }}
      >

        <div className="sheet-pop-over-menu">
          <PopoverMenuItem icon={FolderIcon} title={'Open'} onClick= {handleFolderOpen} />
          <PopoverMenuItem icon={EditIcon} title={'Rename'} onClick= {handleDoubleClick} />
          <PopoverMenuItem icon={DeleteIcon} title={'Delete'} onClick= {handleFolderDelete} />
        </div>
        {/* <Typography className="settings-menu-item" onClick={handleOptionClick} sx={{ p: 1.5 }}>Rename</Typography>
        <Typography className="settings-menu-item" onClick={handleOptionClick} sx={{ p: 1.5 }}>Close</Typography> */}
      </Popover>
            <Dialog
                hideHeader={true}
                open={props.onOpen}
                onClose={onClickCancel}
                maxWidth={'sm'}
                scroll={'paper'}
                PaperProps={{
                    sx: {
                        width: "50%",
                        maxWidth: "75%",
                        maxHeight: "75%",
                        minHeight: "45%",
                        borderRadius: "15px"
                    }
                }}>
                 <DialogTitle id="alert-dialog-title">
                    <div className="title-section">
                        <span style={{ fontFamily: 'Inter', fontSize: 20, fontWeight: 500, display: 'flex', justifyContent: 'space-between' }}>
                            TaxInterest Documents
                        </span>
                        <img className='penalty-close-icon' onClick={onClickCancel} width={30} height={30} src={PenaltyCloseIcon} alt="close-icon" />
                    </div>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <IconButton
                        aria-label="close"
                        onClick={onClickCancel}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        {/* <CloseIcon /> */}
                    </IconButton>
                    <div className="files-container">
                       
                    </div>

                    <nav>
            <span key="cf">
                  <a href="#" onClick={() => handleBreadcrumbClick("","cf")}>
                    Company Share /&nbsp;
                  </a>
                </span>
            {currentPath && currentPath.map((folder, index) => {
              {if(folder!="cf") {
                // Create the path to pass to the onClick handler
              const pathToPass = currentPath.slice(0, index + 1).join('/');
              
              return (
                <span key={index}>
                  <a href="#" onClick={() => handleBreadcrumbClick(index, pathToPass)}>
                    {folder=="cf"?"Company Share":folder}
                  </a>
                  {index < currentPath.length - 1 && " / "}
                </span>
              );
              }}
              
            })}
      </nav>

                    <div className='file-folder-container'>
                        <div className='file-folder-section'>
                            <div className='file-folder-inside'>
                                
                                <div className='files-section'>
                                <div className='folder-section'>
                                    
                                    {filteredFolders.length > 0 ? ( // Check if folderNames is not empty
                                        filteredFolders.map((folder, index) => (
                                            <div
                                                key={index}
                                                className={`folder-name ${folder.folderName === clickableFolderName ? 'active' : ''}`} // Conditional class
                                                
                                                onDoubleClick={() => handleFolderClick((activeFolderName && activeFolderName !== 'cf' ? activeFolderName + '/' : '') + folder.folderName, folder._id)} 
                                                onClick={() => handleFolderActive(folder.folderName, folder._id)}
                                                onContextMenu={(event) => {
                                                    if (folder.folderName === clickableFolderName) {
                                                      handleRightClick(event); // Only trigger on right-click for active folders
                                                    } else {
                                                      event.preventDefault(); // Prevent context menu for non-active folders
                                                    }
                                                  }}
                                            >
                                                <img className='folder-icon' src={FolderIcon} alt="folder-icon" />
                                                {(isEditing && folder.folderName === clickableFolderName) ? (
      <CustomTextField
        id="folder-rename"
        value={editingFolderName}
        ref={inputFolderRenameRef}
        onBlur={(e) => { setIsEditing(false) }}
        
        onChange={(e) => setEditingFolderName(e.target.value)}
      />
      ) : (
       
        <span>{folder.folderName === '/' ? 'Company Share' : folder.folderName}</span>
      )}
                                                
                                            </div>
                                        ))
                                        ) : (
                                        <div></div> // Message when folderNames is empty
                                    )}
                                </div>
                                    {
                                        filteredFiles.map((file) => <FileContainer file={file} downloadFile={downloadFile} />)
                                    }
                                </div>
                            </div>
                         </div>
                         <div className="file-folder-action">
                            <div className="action-buttons">
                                <div className='create-new-section'>

                                {/* <button className='action-button create-new' type="button" onClick={() => onCreateFolder(folderName)}>Create New Folder</button> */}
                                <button className='action-button create-new' type="button" onClick={onShowSubFolder}>Create New Folder</button>&nbsp;
                                {/* <button className='action-button' type="button" onClick={handleDoubleClick}>Rename</button>&nbsp;
                                <button className='action-button' type="button" onClick={handleFolderDelete}>Delete</button> */}
                                </div>
                            </div>
                        </div>
                                
                    </div>
                </DialogContent>

            </Dialog>


        </div>
    )
}


const FileContainer = ({ file, downloadFile }) => {
    
    return (
        <div>
       
        <div className="file-container" onClick={() => downloadFile(file)} >
            <DescriptionIcon sx={{ width: '75px', height: '100px', color: 'lightGray' }} />
            <div className="file-info-section">
                <div className='file-name'>{file.fileName}</div>
                <div className="file-creation-date"><span>Created At:</span><span>{moment(file.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</span></div>
                <div className="file-updattion-date"><span>Updated At:</span><span>{moment(file.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</span></div>
            </div>
        </div>
        </div>

    )
}

export default FilesModal