// ProtectedRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useUser, useAuth } from '@clerk/clerk-react';
import { UserContext } from './UserContext';
import Topbar from './Topbar/Topbar'
const ProtectedRoute = ({ children }) => {
  const { isSignedIn, user } = useUser();
  const { currentUser, isLoaded } = useContext(UserContext);
  const isAuthorized = user && user.organizationMemberships.some(
    (org) => org.organization.id === "org_2oSiN55KgziAFkwuYEb3HVuiHbc"
    
  );
    if (!isLoaded) {
        // Optionally, show a loading spinner or similar while Clerk checks the auth status
        return <div>Loading...</div>;
      }
  if (!isSignedIn) {
    // Redirect to the root or sign-in page if the user is not authenticated
    return <Navigate to="/" />;
  }
  if (!isAuthorized) {
    return (<> <Topbar /> <h2>Access Denied</h2> </>);
  }


  return children;
};

export default ProtectedRoute;
