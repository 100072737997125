// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this to your CSS file */
.highlightable-cell {
  display: flex;
  align-items: center;
}

.highlightable-input {
  border: none;
  padding: 0.2rem;
  width: 100%;
}

.highlightable-cell.focused {
  outline: 2px solid blue; /* Customize the outline color as needed */
}

/* Add this to your CSS file */
.highlightable-input {
  border: none;
  padding: 0.2rem;
  width: 100%;
}

.ag-cell-focus .highlightable-input {
  outline: 2px solid blue; /* Customize the outline color as needed */
}

.custom-amount-input, input, select {
  border: none;
  background: transparent;
  outline: none;
  width: 100%; 
  padding: 2px;
  margin: 0; 
}

.ag-theme-alpine, .ag-theme-alpine-dark {
  --ag-selected-row-background-color: transparent;
  --ag-row-hover-color: transparent;
}
.add-row-text {
  color: #007bff;
  cursor: pointer;
}
.react-calendar__month-view__days__day--weekend {
  color: black !important;
}
`, "",{"version":3,"sources":["webpack://./src/FormComponent.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,WAAW;AACb;;AAEA;EACE,uBAAuB,EAAE,0CAA0C;AACrE;;AAEA,8BAA8B;AAC9B;EACE,YAAY;EACZ,eAAe;EACf,WAAW;AACb;;AAEA;EACE,uBAAuB,EAAE,0CAA0C;AACrE;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,+CAA+C;EAC/C,iCAAiC;AACnC;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,uBAAuB;AACzB","sourcesContent":["/* Add this to your CSS file */\n.highlightable-cell {\n  display: flex;\n  align-items: center;\n}\n\n.highlightable-input {\n  border: none;\n  padding: 0.2rem;\n  width: 100%;\n}\n\n.highlightable-cell.focused {\n  outline: 2px solid blue; /* Customize the outline color as needed */\n}\n\n/* Add this to your CSS file */\n.highlightable-input {\n  border: none;\n  padding: 0.2rem;\n  width: 100%;\n}\n\n.ag-cell-focus .highlightable-input {\n  outline: 2px solid blue; /* Customize the outline color as needed */\n}\n\n.custom-amount-input, input, select {\n  border: none;\n  background: transparent;\n  outline: none;\n  width: 100%; \n  padding: 2px;\n  margin: 0; \n}\n\n.ag-theme-alpine, .ag-theme-alpine-dark {\n  --ag-selected-row-background-color: transparent;\n  --ag-row-hover-color: transparent;\n}\n.add-row-text {\n  color: #007bff;\n  cursor: pointer;\n}\n.react-calendar__month-view__days__day--weekend {\n  color: black !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
