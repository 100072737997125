import React from 'react'
import './Tips.css'
const Tips = (props) => {
    return (
        <div className='tips-container'>
            <div className="tips-text">
                <p>Here’s how TaxInterest uses this information. </p>
				<p className="tips-text-sub">This penalty runs at 0.5% per month (to a maximum of 25%) from the payment due date until the tax is paid.</p>
				<p className="tips-text-sub">Interest on the penalty starts on the date of notice and demand to pay the penalty. There is no interest if the penalty is paid within the applicable grace period.</p>
				<p className="tips-text-sub">The grace period is 10 calendar days for notices before 1997. For notices after 1996, the grace period is 21 calendar days for amounts less than $100,000, and 10 business days for larger amounts.</p>
				<p className="tips-text-sub">A notice of intent to levy (or a jeopardy demand) increases the penalty from 0.5% to 1.0%. Enter a date here even if you already entered the date as a late payment notice</p>
				<p className="tips-text-sub">When an Installment Agreement is terminated, the penalty rate in effect immediately prior to the installment period (0.5% or 1.0%) will take effect for any month starting after the reinstatement date.</p>
            </div>
        </div>
    )
}

export default Tips