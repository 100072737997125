// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.amount-text-field{
    max-height: 37px;
    max-width: 112px;
    min-width: 50px;
    min-height: 37px;
    border-radius: 5px;
    text-align: right;  
    padding-right: 10px;
    background-color: #fafafa;
    border-radius: 6px !important;
    border: 1px #E8E8E8 solid !important;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/AmountTextField/AmountTextField.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;IACzB,6BAA6B;IAC7B,oCAAoC;IACpC,oBAAoB;IACpB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".amount-text-field{\n    max-height: 37px;\n    max-width: 112px;\n    min-width: 50px;\n    min-height: 37px;\n    border-radius: 5px;\n    text-align: right;  \n    padding-right: 10px;\n    background-color: #fafafa;\n    border-radius: 6px !important;\n    border: 1px #E8E8E8 solid !important;\n    font-family: \"Inter\";\n    font-size: 14px;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
