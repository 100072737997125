const useEvents = () => {

    const eventNames = {
    'Tax': 'TXIEventTypeTax',
    'Payment': 'TXIEventTypePayment',
    'Deposit as Bond': 'TXIEventTypeDeposit',
    'Suspend Interest': 'TXIEventTypeStopInterest',
    'Resume Interest': 'TXIEventTypeStartInterest',
    'IRS Check': 'TXIEventTypeIRSCheck',
    'Entered Penalty': 'TXIEventTypeUserPenalty',
    'Motivated Tax': 'TXIEventTypeMotivatedTax',
    }

    return {eventNames}
    
  };

  export default useEvents;
