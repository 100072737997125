import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle
} from 'react';

import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import './ReactDatePicker.css'
import CalendarIcon from '../assets/calendar.svg';
import { every } from 'lodash';

const ReactDatePicker = forwardRef((props, ref) => {
  const { onValueChange, api, column, node, rowIndex } = props;
  const today = new Date();
  const [date, setDate] = useState(props.value !== '' ? moment(props.value, 'MM DD YYYY').toDate() : '');
  const [editing, setEditing] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isOpen, setOpen] = useState(false);
  var oldDate = date !== '' ? moment(date).format('MM/DD/YYYY') : '';
  const inputRef = useRef(null);

  const minDate = new Date('1/1/1600');
  const maxDate = new Date('12/30/2500');

  useEffect(() => {
    console.log('useEffect coming ===>');
    if (oldDate !== null) {
      inputRef.current.value = oldDate;
      inputRef.current.select();
    }
    
    if (!editing) {
      
      moveToNextCell();
    }
  }, [editing])


  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return date && date !== '' ?  moment(date).format('MM/DD/YYYY') : '';
      }
    };
  });

  const onChange = selectedDate => {
    const d = moment(selectedDate).format('MM/DD/YYYY')
    setDate(d);
    setEditing(false);
    console.log("test date");
    
  };

  const onClick = e => {
    if (isOpen) {
      setOpen(!isOpen);
    }
  };

  const onValueChanged = (e) => {
    console.log('onValueChanged')
    var text = e.target.value;
    if (!text.includes("/")) {
      text = convertToDate(text);
    }
    constructDate(text);
  }

  const constructDate = (d) => {
    if (moment(d, 'MM/DD/YYYY', true).isValid()) {
      if (!isNaN(new Date(d))) {
        var selectedDate = new Date(d);
        if (selectedDate < maxDate && selectedDate > minDate) {
          setDate(selectedDate);
          return selectedDate
        } else {
          const year = selectedDate > maxDate ? maxDate.getFullYear() : minDate.getFullYear()
          selectedDate.setFullYear(year);
          setDate(selectedDate);
          return selectedDate
        }
      } else {
        //return ''
        props.onError(`${d} is not valid one`);
      }
    }
    return ''
  }

  const convertToDate = (number) => {
    const m = getMonth(number);
    const d = getDay(number);
    const y = getYear(number);
    return `${m}/${d}/${y}`
  };

  const getMonth = (number) => {
    if (number.length === 0) { return }
    if (number.length === 1) {
      return number
    }
    else if (number.length >= 2) {
      let subNumber = number.substring(0, 2);
      return subNumber <= 12 ? subNumber : subNumber[0];
    }
  }

  const getDay = (number) => {
    if (number.length === 1) {
      return today.getDate();
    }
    else if (number.length === 2) {
      return number <= 12 ? today.getDate() : number[1];
    }
    else if (number.length === 3) {
      let subNumber = number.substring(0, 2);
      let day = subNumber <= 12 ? number.substring(2) : number.substring(1);
      return day > 31 ? day[0] : day
    }
    else if (number.length > 3) {
      const month = getMonth(number).length;
      const day = number.substring(month, month + 2);
      return day > 31 ? day[0] : day
    }
  }

  const getYear = (number) => {
    if (number.length >= 3) {
      let monthLength = getMonth(number).length;
      let dayLength = getDay(number).length;
      let totalLength = monthLength + dayLength;
      let remainingLength = number.length - totalLength
      switch (remainingLength) {
        case 0:
          return today.getFullYear()
        case 1:
        case 2:
        case 3:
          const yearToAdd = parseInt(number.substring(totalLength));
          const baseYear = yearToAdd <= 60 ? 2000 : 1900;
          return baseYear + yearToAdd;
        case 4:
          return number.substring(totalLength)
        default:
          return maxDate.getFullYear();
      }
    }
    return today.getFullYear();
  }

  const onBlur = (e) => {
    const text = e.target.value;
    console.log("text"+text)
    var newDate = '';
    if (text.includes("/")) {
      console.log("text1"+text)
      if (moment(text, 'MM/DD/YYYY', true).isValid()) {
        console.log("text2"+text)
        newDate = text;
      } else {
        console.log("text3"+text)
      }
    } else {
      console.log("text4"+text)
      newDate = convertToDate(text);
    }
    if (!isNaN(new Date(newDate))) {
      const selectedDate = new Date(newDate);
      if (selectedDate < maxDate && selectedDate > minDate) {
        setDate(date);
        const relatedTarget = e.relatedTarget; 
        if (relatedTarget && relatedTarget.classList.contains('ag-cell')) {
          console.log("muruga");
          moveToNextCell();
        }
      } else {
        const date = selectedDate > maxDate ? maxDate : minDate
        onChange({ target: { value: date } })
        props.onError('Please note: Dates must be between 1/1/1600 and 1/1/2500');
        moveToNextCell();
      }
    } else {
      props.onError(`${newDate} is not valid one`);
    }
    
    
  }

  const onCalendarClose = (e) => {
    setEditing(false);
  }

  const shouldCloseCalendar = () => {
    return false;
  }

  const moveToNextCell = () => {
    props.api.stopEditing();
    props.api.clearFocusedCell();
    props.api.startEditingCell({ rowIndex: props.rowIndex, colKey: 'eventAmount' });
    return;
  }

  const onCalendarIconClick = () => {
    setOpen(!isOpen);
  }

  const onKeyDown = (e) => {
    const shiftKey = e.shiftKey || (e.key === 'Shift');
    if (e.shiftKey && e.key) {
      console.log("testv");
    }
    const allowedKeys = [
      "ArrowLeft",
      "ArrowRight",
      "Backspace",
      "Delete",
      "Tab",
    ];
    if (allowedKeys.includes(event.key) || containsOnlyNumbers(e.key) || ['/', 'm', 'y', 'd', 'Y'].includes(e.key) || e.key === 'Backspace' || (e.shiftKey && e.key)) {

      if (['m', 'M', 'y', 'D', 'd', 'Y'].includes(e.key)) {
        e.preventDefault();
        const convertedDate = getConvertedDate(e.target.value);
        //const valueToAddOrSubtract = 1; //(shiftKey === null) ? -1 : 1
        let valueToAddOrSubtract = 1;
        if (e.shiftKey && e.key) {
          valueToAddOrSubtract = -1;
        }

        if (convertedDate !== undefined && convertedDate !== "") {
          switch (e.key) {
            case 'm':
              convertedDate.setMonth(convertedDate.getMonth() + valueToAddOrSubtract);
              break;
            case 'M':
              convertedDate.setMonth(convertedDate.getMonth() + valueToAddOrSubtract);
              break;
            case 'y':
              convertedDate.setYear(convertedDate.getFullYear() + valueToAddOrSubtract);
              break;
            case 'Y':
              convertedDate.setYear(convertedDate.getFullYear() + valueToAddOrSubtract);
              break;
            case 'd':
              convertedDate.setDate(convertedDate.getDate() + valueToAddOrSubtract);
              break;
            case 'D':
              convertedDate.setDate(convertedDate.getDate() + valueToAddOrSubtract);
              break;
          }
          e.target.value = moment(convertedDate).format('MM/DD/YYYY');
        }
      }
    } else {
      e.preventDefault();
    }
  }

  const getConvertedDate = (value) => {
    var text = value;
    if (!text.includes("/")) {
      text = convertToDate(text);
    }
    console.log(constructDate(text));
    return constructDate(text);
  }

  function containsOnlyNumbers(inputStr) {
    // Use a regular expression to check if the string contains only numbers
    return /^\d+$/.test(inputStr);
  }

  return (
    <div className='parent'>
      <div className="child">
        <DatePicker
          value={oldDate}
          calendarIcon={null}
          isOpen={isOpen}
          clearIcon={null}
          selected={date}
          format="MM/dd/yyyy"
          onChange={onChange}
          onCalendarOpen={() => setOpen(true)}
          onCalendarClose={onCalendarClose}
          shouldCloseCalendar={shouldCloseCalendar}
          minDate={minDate}
          maxDate={maxDate}
          calendarType="gregory"
          className={"react-date-picker"}
          calendarClassName={"ag-custom-component-popup"}
        />
      </div>

      <div className="child input-container">
        <input
          ref={inputRef}
          className='date-field'
          type="text"
          onBlur={onBlur}
          autoFocus={true}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onChange={onValueChanged} />

        <div
          className='date-picker-calendar-icon'
          tabIndex="0"
          onClick={onCalendarIconClick}>
          <img className='calendar-icon' src={CalendarIcon} alt="" srcset="" />
        </div>
      </div>

    </div>

  );
});

export default ReactDatePicker;
