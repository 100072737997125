import React from 'react'
import './Tips.css'
const Tips = (props) => {
    return (
        <div className='tips-container'>
            <div className="tips-text">
                <p>Here’s how TaxInterest uses this information. </p>
				<p className="tips-text-sub">This penalty is based on the amount of an underpayment that is due to a pension liability overstatement.</p>
				<p className="tips-text-sub">Interest on the penalty starts on the due date of the return (with extensions).</p>
				<p className="tips-text-sub">This penalty is one of the accuracy-related penalties. Amounts subject to this penalty will not be subjected to other parts of the accuracy-related penalty.</p>
				<p className="tips-text-sub">There are no penalties if the overstatement led to a tax understatement of $1,000 or less. Do note though that other negligence penalties can still apply.</p>
			</div>
        </div>
    )
}

export default Tips