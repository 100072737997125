import React, { useState } from 'react';
import axios from 'axios';
import { useUser, useAuth } from '@clerk/clerk-react';
import './ClerkForms.css';
import toast, { Toaster } from 'react-hot-toast';
import useClerk from '../../hooks/userClerk'
const CreateOrganization = () => {
    const {getClerkToken} = useClerk();
    // State variables to hold form data and validation errors
    const {user} = useUser();
    const [formData, setFormData] = useState({
        name: '',
        createdBy: 'user_2hBpxC33WIZszQOpZp1rpwWCnF4',
        maxAllowedMemberships: 0,
        licenseKey: ''
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        createdBy: '',
        maxAllowedMemberships: '',
        licenseKey: ''
    });

    // Handle form submit
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate form fields
        let isValid = true;
        const newErrors = { ...formErrors };

        if (formData.name.trim() === '') {
            newErrors.name = 'Name is required';
            isValid = false;
        } else {
            newErrors.name = '';
        }

        if (formData.createdBy.trim() === '') {
            newErrors.createdBy = 'Created By is required';
            isValid = false;
        } else {
            newErrors.createdBy = '';
        }

        if (formData.maxAllowedMemberships.trim() === '') {
            newErrors.maxAllowedMemberships = 'Max Allowed Memberships is required';
            isValid = false;
        } else {
            newErrors.maxAllowedMemberships = '';
        }

        if (formData.licenseKey.trim() === '') {
            newErrors.licenseKey = 'License Key is required';
            isValid = false;
        } else {
            newErrors.licenseKey = '';
        }

        setFormErrors(newErrors);

        if (isValid) {
            try {
                const token = await getClerkToken();
                const response = await axios.post(`http://localhost:4002/api/organizations`, formData, {headers:{'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}});
                console.log('Response:', response.data); // Assuming response includes created organization details
                // Reset form or perform any other actions on successful creation
                setFormData({
                    name: '',
                    createdBy: 'user_2hBpxC33WIZszQOpZp1rpwWCnF4',
                    maxAllowedMemberships: '',
                    licenseKey: ''
                });
                toast('Organization created successfully');
            } catch (error) {
                console.error('Error submitting form:', error);
                alert('Failed to submit form. Please try again later.');
            }
        }
    };

    // Handle input changes
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <form id="clerk-forms" onSubmit={handleSubmit}>
            <div>
                <label>
                    Name:
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </label>
                {formErrors.name && <span style={{ color: 'red' }}>{formErrors.name}</span>}
            </div>
            <div>
                <label>
                    Created By:
                    <input
                        type="text"
                        name="createdBy"
                        value={formData.createdBy}
                        onChange={handleChange}
                        required
                    />
                </label>
                {formErrors.createdBy && <span style={{ color: 'red' }}>{formErrors.createdBy}</span>}
            </div>
            <div>
                <label>
                    Max Allowed Memberships:
                    <input
                        type="number"
                        name="maxAllowedMemberships"
                        value={formData.maxAllowedMemberships}
                        onChange={handleChange}
                        required
                    />
                </label>
                {formErrors.maxAllowedMemberships && <span style={{ color: 'red' }}>{formErrors.maxAllowedMemberships}</span>}
            </div>
            <div>
                <label>
                    License Key:
                    <input
                        type="text"
                        name="licenseKey"
                        value={formData.licenseKey}
                        onChange={handleChange}
                        required
                    />
                </label>
                {formErrors.licenseKey && <span style={{ color: 'red' }}>{formErrors.licenseKey}</span>}
            </div>
            <br />
            <button type="submit">Submit</button>
        </form>
    );
};

export default CreateOrganization;
