import { useUser, useAuth } from '@clerk/clerk-react';
import { jwtDecode } from "jwt-decode";
import { useSession } from '@clerk/clerk-react';

const useClerk = ()=>{
    const { isLoaded, session, isSignedIn } = useSession();
    
    const {user} = useUser();
    const {signOut, getToken} = useAuth();
    let user_role;
    //let user_role = user && user.organizationMemberships && user.organizationMemberships[0].role;
    const emailAddress =  user && user.primaryEmailAddress && user.primaryEmailAddress.emailAddress;
//let user_role = "org:tax_interest";
    const userProducts = ()=>{
        if(user_role == "org:tax_interest") {
            return ['TaxInterest', 'TValue'];
        } else {
            return ['TaxInterest', 'TValue'];
        }
        
        const licenseKey = user.publicMetadata.licenseKey || ""
        const userInfo = jwtDecode(licenseKey);
        return userInfo.products || [];
    }

    const signedOut = async()=>{
        await signOut(); 
    }

    const getClerkToken = async () => {
        const token = await getToken();
        // Add logic to fetch your data
        return token;
      }

    return {userProducts, signedOut, emailAddress, getClerkToken, user};
       
}

export default useClerk;