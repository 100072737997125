import React, { createContext, useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';

// Create a context for user session
export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  
  const { user, isLoaded } = useUser();
  const [currentUser, setCurrentUser] = useState(null);
  console.log("user");
  console.log(user);
  useEffect(() => {
    // Check if the user data has been set previously
    const hasUserBeenSet = localStorage.getItem('hasUserBeenSet');

    if (isLoaded && user && !currentUser && !hasUserBeenSet) {
      // Set the user only if it hasn't been set before
      setCurrentUser(user);
      // Mark that the user has been set
      localStorage.setItem('hasUserBeenSet', 'true');
    } else if (!user) {
      // Reset the state if the user is not available (i.e., logged out)
      setCurrentUser(null);
      localStorage.removeItem('hasUserBeenSet');
    }
  }, [isLoaded, user, currentUser]);

  return (
    <UserContext.Provider value={{ currentUser, isLoaded }}>
      {children}
    </UserContext.Provider>
  );
};
