import { Checkbox, Dialog, DialogContent,DialogActions, Button } from '@mui/material'
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react'
import RDatePicker from '../../DatePicker';
import './Penalty6651A2.css'
import '../Penalties.css'
import useToday from '../../../hooks/useToday';
import AmountTextField from '../../AmountTextField/AmountTextField';
import PenaltyLabel from '../../Label/PenaltyLabel';
import Tips from '../Tips/6651a2';
import IOSSwitch from '../../IOSSwitch/IOSSwitch';
import PenaltyCloseIcon from '../../../assets/penalty-close-icon.svg';
import PenaltyDiscardAlert from '../../PenaltyDiscardAlert';
import AccordionPlus from '../../../assets/accordion-plus.svg';
import AccordionMinus from '../../../assets/accordion-minus.svg';
const Penalty6651A2 = forwardRef((props, ref) => {
    const [openTips, setOpenTips] = useState(false);
    let [initialPenalty, setInitialPenalty] = useState(props.value);
    const [penalty, setPenalty] = useState(props.value);
    const [showAlert, setShowAlert] = useState(false);
    const today = useToday();
    const [isOpen, setIsOpen] = useState(penalty.showTips);
    useImperativeHandle(ref, () => ({
        getValue: () => penalty // Allow the parent to access this value
      }));
    const toggleAccordion = () => {
        setPenalty({
            ...penalty,
            showTips: !isOpen
        })
        setIsOpen(!isOpen);
    };
    const titles = [
        "Date of 1st late payment notice (if any)",
    ]
    const options = [
        "Yes",
        "No"]
    const daysOptions = [
        "10 Days",
        "21 Days",
    ]

    useEffect(() => {
        const handleClickOff = (event) => {
            console.log(event.srcElement.classList );
            if (event.srcElement.classList.contains('MuiDialog-container')) {
                if (JSON.stringify(penalty) !== JSON.stringify(initialPenalty)) {
                    setShowAlert(true);
                  } else {
                    props.onClose();
                  }
            }
        }
        document.addEventListener('click', handleClickOff);
    return () => {
      document.removeEventListener('click', handleClickOff);
    };
    }, [props.value, initialPenalty, penalty]);

    const onClickOk = () => {
        setInitialPenalty(penalty);
        console.log(penalty);
        props.onSubmit(penalty);
        // props.onClose()
    }
    const addNewDueRow = () => {

        const latePayments = penalty.latePayments
        const latePaymentsLength = latePayments.length + 1;
        if(latePayments.length >= 6) {
            alert("Only 6 rows can be added");
            return;
        }
        let titleindex = '';
        if (latePaymentsLength == 2) {
            titleindex = "Date of " + latePaymentsLength + "nd late payment notice (if any)"
        } else if (latePaymentsLength == 3) {
            titleindex = "Date of " + latePaymentsLength + "rd late payment notice (if any)"
        } else {
            titleindex = "Date of " + latePaymentsLength + "th late payment notice (if any)"
        }
        const newDueRow = {
            title: titleindex,
            date: "",
            was1stNoticeOfLevy: "No",
            was1stJeopardyDemand: "No",
            gracePeriod: "10 Days"
        };
        latePayments.push(newDueRow);
        console.log(latePayments)
        const p = {
            ...penalty,
            latePayments: latePayments
        }
        setPenalty(p);

    }
    const showTip= () => {
        setOpenTips(true);
      };
      const tipClose = () => {
        console.log("closing")
        setOpenTips(false);
      };
      const onSave = () => {
        setInitialPenalty(penalty);
        onClickOk()
        props.onClose()
    }

    const onDiscard = () => {
        props.onClose()
    }
    const onCloseErrorAlert = () => {
        setShowAlert(false)
    }
    const deleteDueRow = () => {
        const latePayments = penalty.latePayments;
        const latePaymentsLength = latePayments.length;
        if(latePaymentsLength > 1) {
            latePayments.pop();
            const p = {
                ...penalty,
                latePayments: latePayments
            }
            setPenalty(p);
        } else {
            alert("Date of notice to pay tax is the default and cannot be deleted");
        }
    }
    const onDeleteRow = (index, v) => {
       
        let latePayments = penalty.latePayments;
        const latePaymentsLength = latePayments.length;
        let newLatePayment = [];
        const p = {
            ...penalty,
            latePayments: []
        }
        setPenalty(p);
        if(latePaymentsLength > 1) {
            //latePayments.pop(index);
            latePayments.splice(index, 1);
            
            const updatedLatePayments = latePayments.map((payment, i) => {
                const position = i + 1;
                let titleindex = '';
                if (position == 1) {
                    titleindex = "Date of " + position + "st late payment notice (if any)"
                } else if (position == 2) {
                    titleindex = "Date of " + position + "nd late payment notice (if any)"
                } else if (position == 3) {
                    titleindex = "Date of " + position + "rd late payment notice (if any)"
                } else {
                    titleindex = "Date of " + position + "th late payment notice (if any)"
                }
                return {
                    ...payment, // spread to create a new reference
                    title: titleindex,
                    was1stNoticeOfLevy: payment.was1stNoticeOfLevy,
                    was1stJeopardyDemand: payment.was1stJeopardyDemand,
                    gracePeriod: payment.gracePeriod,
                    date: payment.date // create new Date instance
                };
            })
            setPenalty({ ...penalty, latePayments: [] });
            const p = {
                ...penalty,
                latePayments: updatedLatePayments
            }
            setPenalty(p);
        } else {
            alert("Date of notice to pay tax is the default and cannot be deleted");
        }
    }
    const onClickCancel = () => {
        if (JSON.stringify(penalty) !== JSON.stringify(initialPenalty)) {
            setShowAlert(true);
          } else {
            props.onClose();
          }
        setShowAlert(true);
    }

    const onCheckBoxChanged = (e) => {
        const p = {
            ...penalty,
            enabled: e.target.checked,
        }
        setPenalty(p);
    }

    const onChangeDate = (index, v) => {
        const latePayments = penalty.latePayments
        latePayments[index].date = v
        const p = {
            ...penalty,
            latePayments: latePayments
        }
        setPenalty(p);
    }

    const onChangeLeviy = (index, v) => {
        const latePayments = penalty.latePayments
        latePayments[index].was1stNoticeOfLevy = v
        for (let i = 0; i < latePayments.length; i++) {
            if(i!=index) {
                latePayments[i].was1stNoticeOfLevy = "No"
            }
        }
        const p = {
            ...penalty,
            latePayments: latePayments
        }
        setPenalty(p);
    }


    const onChangeJeodary = (index, v) => {
        const latePayments = penalty.latePayments
        latePayments[index].was1stJeopardyDemand = v
        const p = {
            ...penalty,
            latePayments: latePayments
        }
        setPenalty(p);
    }


    const onChangeGracePeriod = (index, v) => {
        const latePayments = penalty.latePayments
        for (let i = 0; i < latePayments.length; i++) {
            latePayments[i].gracePeriod = v
        }
        const p = {
            ...penalty,
            latePayments: latePayments
        }
        setPenalty(p);
    }



    return (
        <div>
            {/* <div className="title-section-close">
                <img className='penalty-close-icon'  width={30} height={30} src={PenaltyCloseIcon} onClick={onClickCancel} alt="close-icon" />
            </div> */}
            <div className="penalty-6651a-main-container">
                <div className="penalty-irc6651a2-form">
                    <div className="activation-container">
                        <div className="activation-left-container">
                            {/* <div className="penalty-activation-conatiner">
                                <PenaltyLabel value={'Activate penalty failure to pay tax shown on return'} enabled={true} type="header" />
                                <IOSSwitch checked={penalty.enabled ? penalty.enabled : false} onChange={onCheckBoxChanged} />
                            </div> */}
                            <div className="tax-amount">
                                <PenaltyLabel value={'Tax shown on return but not paid'} enabled={penalty.enabled} type={"normal"} />
                                <AmountTextField value={penalty.amount} disabled={!penalty.enabled} onChange={v => setPenalty({
                                    ...penalty,
                                    amount: v
                                })} />
                            </div>
                            <div className="tax-date">
                                <PenaltyLabel value={'Tax due date not including extensions of time to file'} enabled={penalty.enabled} type={"normal"} />

                                <div className="date-picker-bottom">
                                    <RDatePicker enabled={penalty.enabled ? penalty.enabled : false} value={penalty.dueDate ?? today} onChange={d => setPenalty({
                                        ...penalty,
                                        dueDate: d
                                    })} />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="penalty-payments-container">
                        <div className="late-payment-row header">
                            <div className={penalty.enabled ? "payment-placeholder-text" : "payment-placeholder-text-disabled"}>
                                Notice
                            </div>
                            <div className={penalty.enabled ? "date-container" : "date-container-disabled"}>
                                Date
                            </div>
                            <div className={penalty.enabled ? "notice-of-delay" : "notice-of-delay-disabled"}>
                                Was this the 1st notice of levy?
                            </div>
                            <div className={penalty.enabled ? "jeopardy-demand" : "jeopardy-demand-disabled"}>
                                Was this the 1st jeopardy demand?
                            </div>
                            <div className={penalty.enabled ? "grace-period" : "grace-period-disabled"}>
                                Grace Period
                            </div>
                            <div className={penalty.enabled ? "remove-button" : "remove-button-disabled"}>
                                    
                            </div>
                        </div>

                        <div className="late-payment-info-container">
                            {
                                penalty.latePayments.map(
                                    (p, index) =>
                                        <PenaltyRow
                                            index={index}
                                            key={`row-${index}-${p.date}-${p.was1stNoticeOfLevy}-${p.was1stJeopardyDemand}-${p.gracePeriod}`}
                                            value={p}
                                            title={titles[index]}
                                            options={options}
                                            disabled={!penalty.enabled}
                                            daysOptions={daysOptions}
                                            onChangeDate={onChangeDate}
                                            onChangeLeviy={onChangeLeviy}
                                            onChangeJeodary={onChangeJeodary}
                                            onChangeGracePeriod={onChangeGracePeriod}
                                            onDeleteRow={onDeleteRow}
                                        />)
                            }
                            <div className="add-new-row-button">
                                <Button disabled = {!penalty.enabled} onClick={addNewDueRow} className='addnewrowbtn'><span className='addnewrowtext'>+ Add  Row</span></Button>
                            </div>
                        </div>
                    </div>

                    {/* <div className="tips-container">
                        <div className="divider-line">_____</div> <div className={!penalty.enabled && "disabled-text"}> Installment Agreement Reduction (affects post 12-31-99 periods for timely returns)  </div> <div className="divider-line">______</div>
                    </div>
                    <hr /> */}
                    <div className="">
                        <div className="timely-filed">
                            <div className="show-tips">
                                <PenaltyLabel value={'Individual return timely field?'} enabled={penalty.enabled} type={"header"} />
                                <IOSSwitch disabled={!penalty.enabled} checked={penalty.isIndividualReturn} onChange={e => setPenalty({
                                    ...penalty,
                                    isIndividualReturn: e.target.checked
                                })} />
                            </div>
                        </div>
                        <div >
                            <div className="reduce-penalty">
                                <PenaltyLabel value={'Reduce penalty to 0.25% for any month starting after'} enabled={penalty.enabled} type={"normal"} />
                                <RDatePicker enabled={penalty.isIndividualReturn ? penalty.isIndividualReturn : false} value={penalty.reducePenaltyAmount ?? today} onChange={d => setPenalty({
                                    ...penalty,
                                    reducePenaltyAmount: d
                                })} />
                            </div>
                            <div className="penalty-to-previous">
                                <PenaltyLabel value={'Reinstate penalty to previous 0.25% for any month starting after'} enabled={penalty.enabled} type={"normal"} />
                                <RDatePicker enabled={penalty.isIndividualReturn ? penalty.isIndividualReturn : false} value={penalty.reInstatePenalty ?? today} onChange={d => setPenalty({
                                    ...penalty,
                                    reInstatePenalty: d
                                })} />
                            </div>
                        </div>
                        {/* <div className="show-tips">
                            <PenaltyLabel value={'Show tips?'} enabled={penalty.enabled} type={"header"} />
                            <IOSSwitch disabled={!penalty.enabled} checked={penalty.showTips} onChange={e => setPenalty({
                                ...penalty,
                                showTips: e.target.checked
                            })} />

                        </div> */}
                    </div>
                </div>
                </div>
                <div className="action-buttons">
                <button className='action-button cancel' type="Cancel" value={"Cancel"} onClick={onClickCancel}>Cancel</button>
                <button className='action-button save' type="Save" value={"Save"} onClick={onClickOk}>Save</button>
            </div>

            
                <div className="accordion">
                <div className="accordion-header" onClick={toggleAccordion}>
                    <PenaltyLabel value={'Penalty Details'} enabled={true} type="header" />
                    <div className="icon">
                    {isOpen ? <img src={AccordionMinus} alt="Close Icon" /> : <img src={AccordionPlus} alt="Close Icon" />}
                    </div>
                </div>
                {isOpen && (
                    <div className="accordion-content">
                        <Tips />
                    </div>
                )}
            </div>
            
            <Dialog open={openTips}>
                                {penalty.title}
                                <DialogContent>
                                    <Tips />
                                </DialogContent>
                                <DialogActions>
                                   <Button onClick={tipClose}>Close</Button>
                                 </DialogActions>
                            </Dialog>
                            <PenaltyDiscardAlert title={'Discard Unsaved Changes'} message={'If you navigate away from this page, you will lose your changes.'} open={showAlert} onClose={onCloseErrorAlert} onSave={onSave} onDiscard={onDiscard} />
        </div>
        
    )
});

export default Penalty6651A2;


export const PenaltyRow = (props) => {
    console.log(props)
    const [latePayment, setLatePayment] = useState(props.value);

    return <div className="late-payment-row">
        <div className={props.disabled ? "payment-placeholder-disabled-text" : "payment-placeholder-text"}>
            {latePayment.title}
        </div>
        <div className="date-container">
            <RDatePicker enabled={!props.disabled} value={latePayment.date} onChange={(d) => {
                props.onChangeDate(props.index, d)
            }
            } />
        </div>
        <div className="notice-of-delay">
            <Select value={latePayment.was1stNoticeOfLevy} options={props.options} disabled={props.disabled} onChange={(e) => {
                props.onChangeLeviy(props.index, e.target.value);
            }
            } />
        </div>
        <div className="jeopardy-demand">
            <Select value={latePayment.was1stJeopardyDemand} options={props.options} disabled={props.disabled} onChange={(e) => {
                props.onChangeJeodary(props.index, e.target.value);
            }
            } />
        </div>
        <div className="grace-period">
            <Select value={latePayment.gracePeriod} options={props.daysOptions} disabled={props.disabled}  onChange={(e) => {
                props.onChangeGracePeriod(props.index, e.target.value)
            }
            } />
        </div>
                <div className="remove-button">
                    <Button onClick={(e) => {
                        props.onDeleteRow(props.index, e.target.value)
                    }} className='deleterowbtn'><span className='addnewrowtext'>- </span></Button>
                </div>
    </div>
}

const TextField = (props) => {
    return <input type='text' className='text-box' value={props.value} onChange={props.onChange}
        disabled={props.disabled} />
}

const Select = (props) => {
    return <select className='select-box' disabled={props.disabled} onChange={props.onChange}>
        {props.options.map((option, index) => {
            return (
                <option key={index} selected={props.value !== null && props.value === option}>
                    {option}
                </option>
            );
        })}
    </select>
}
