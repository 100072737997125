import React from 'react'
import './Products.css'
import { useNavigate } from 'react-router-dom';
import Topbar from '../Topbar/Topbar'
import Product from './Product';
import TaxInterestLogo from '../../assets/tax-interest.svg';
import TvalueOnlineLogo from '../../assets/tvalue-online.svg';
import TaxInterestFin18Logo from '../../assets/tax-interest-fin18.svg';
const Products = () => {
    const navigate = useNavigate();

    const products = [
        {
            "name": "TaxInterest",
            "title": "IRS Interest and Penalty Calculator",
            "description": "Accurate IRS interest and penalty calculation for state and federal jurisdictions",
            "logo": TaxInterestLogo,
        },
        {
            "name": "TaxInterest FIN18",
            "title": "Uncertain Tax Position Calculator",
            "description": "IRS Uncertain Tax Position calculations for both state and federal jurisdictions",
            "logo": TaxInterestFin18Logo,
        },
        {
            "name": "Tvalue Online",
            "title": "Time Value of Money Calculator",
            "description": "Structure any loan, price any lease or solve any time value of money calculation with confidence",
            "logo": TvalueOnlineLogo,
        },
    ]

    const onClikProduct = (p) => {
        if(p.name === "TaxInterest"){
            navigate('/tax-interest');
        }
    }
    const showTaxInterestLogo = false;
    return (
        <div className='tvalue-contrainer'>
            <Topbar showTaxInterestLogo={showTaxInterestLogo} />
            <div className="products" >
                {
                    products.map((product) => {
                        return (
                            <Product value={product} onClick = {onClikProduct}/>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Products