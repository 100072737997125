import { Checkbox, Dialog, DialogContent, Button } from '@mui/material'
import React, { useEffect, useState, useImperativeHandle, forwardRef  } from 'react'
import RDatePicker from '../../DatePicker';
import './Penalty6651A1.css'
import useToday from '../../../hooks/useToday';
import AmountTextField from '../../AmountTextField/AmountTextField';
import Tips from '../Tips/6651a1';
import PenaltyLabel from '../../Label/PenaltyLabel';
import IOSSwitch from '../../IOSSwitch/IOSSwitch';
import AlertDialogWithCancel from '../../AlertDialogWithCancel';
import PenaltyDiscardAlert from '../../PenaltyDiscardAlert';
import PenaltyCloseIcon from '../../../assets/penalty-close-icon.svg';
import AccordionPlus from '../../../assets/accordion-plus.svg';
import AccordionMinus from '../../../assets/accordion-minus.svg';
const Penalty6651A1 = forwardRef((props, ref) => {
    const [penalty, setPenalty] = useState(props.value);
    console.log("props.value", props.value);
    const [penaltySaved, setPenaltySaved] = useState(false);
    let [initialPenalty, setInitialPenalty] = useState(props.value);
    const today = useToday();
    const [showAlert, setShowAlert] = useState(false)
    const [isOpen, setIsOpen] = useState(penalty.showTips);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showErroMessage, setShowErroMessage] = useState(false);
    useImperativeHandle(ref, () => ({
        getValue: () => penalty // Allow the parent to access this value
      }));
    const toggleAccordion = () => {
        setPenalty({
            ...penalty,
            showTips: !isOpen
        })
        setIsOpen(!isOpen);
    };
    const handleDateError = (error) => {
        setErrorTitle("Invalid Date");
        setErrorMessage(error);
        setShowErroMessage(true);
      }
    // useEffect(() => {
    //     const handleClickOff = (event) => {
    //         if (event.srcElement.classList.contains('MuiDialog-container')) {
    //             if (JSON.stringify(penalty) !== JSON.stringify(initialPenalty)) {
    //                 setShowAlert(true);
    //             } else {
    //                 props.onClose();
    //             }
    //         }
    //     }
    //     document.addEventListener('click', handleClickOff);
    //     return () => {
    //         document.removeEventListener('click', handleClickOff);
    //     };
    // }, [props.value, initialPenalty, penalty]);
    let prevProp = props;
    const onClickOk = () => {
        setPenaltySaved(true);
        setInitialPenalty(penalty);
        props.onSubmit(penalty);
        
       // props.onClose()
    }

    const onSave = () => {
        onClickOk();
        props.onClose();
    }

    const onDiscard = () => {
        props.onClose();
    }

    const onClickCancel = () => {
        if (JSON.stringify(penalty) !== JSON.stringify(initialPenalty)) {
            setShowAlert(true);
        } else {
            props.onClose();
        }
    }

    const onCheckBoxChanged = (e) => {
        const p = {
            ...penalty,
            enabled: e.target.checked,
        }
        setPenalty(p);
    }

    const onCloseErrorAlert = () => {
        setShowAlert(false)
    }
    const onCloseDateErrorAlert = () => {
        setShowErroMessage(false)
    }

    const onOk = () => {
        setShowAlert(false)
        props.onClose()
    }


    return (
        <div>
            {/* <div className="title-section-close">
                <img className='penalty-close-icon' width={30} height={30} src={PenaltyCloseIcon} onClick={onClickCancel} alt="close-icon" />
            </div> */}
            <div className="penalty-6651a-main-container">
                <div className="active-penalty-left-container">
                    {/* <div className="penalty-activation-conatiner">
                        <PenaltyLabel value={'Activate the penalty for failure to file'} enabled={true} type="header" />
                        <IOSSwitch checked={penalty.enabled ? penalty.enabled : false} onChange={onCheckBoxChanged} />
                    </div> */}
 {penaltySaved && (
                <div className="penalty-saved-message">
                Penalty has been successfully saved!
                </div>
            )}
                    <div className="return-date-container">
                        <PenaltyLabel value={'Date return was received by IRS'} enabled={penalty.enabled} type={"normal"} />
                        <RDatePicker enabled={penalty.enabled ? penalty.enabled : false} value={penalty.returnDate ?? today} onError={handleDateError} onChange={(d) => {
    setPenalty({
        ...penalty,
        returnDate: d
    });
}} />
                    </div>

                    <div className="due-date-container">
                        {/* <div className={`${!penalty.enabled &&  "disabled-text"}`}>Due date (with extensions)</div> */}
                        <PenaltyLabel value={'Due date (with extensions)'} enabled={penalty.enabled} type={"normal"} />
                        <RDatePicker enabled={penalty.enabled ? penalty.enabled : false} value={penalty.dueDate ?? today} onError={handleDateError}  onChange={d => {setPenalty({
                            ...penalty,
                            dueDate: d
                        });
                        }} />
                    </div>

                    <div className="net-tax-container">
                        <PenaltyLabel value={'Net tax due'} enabled={penalty.enabled} type={"normal"} />

                        <AmountTextField value={penalty.amount} disabled={!penalty.enabled} onChange={v => setPenalty({
                            ...penalty,
                            amount: v
                        })} />
                    </div>
                    <div className="net-tax-container">
                        <PenaltyLabel value={'Was failure-to-file due to fraud?'} enabled={penalty.enabled} type={"normal"} />
                        <IOSSwitch disabled={!penalty.enabled} checked={penalty.dueToFraud} onChange={e => setPenalty({
                            ...penalty,
                            dueToFraud: e.target.checked
                        })} />

                        {/* <div className="check-box"><Checkbox disabled = {!penalty.enabled} tabIndex={0} checked={penalty.dueToFraud} onChange={e=>setPenalty({
                                ...penalty,
                                dueToFraud: e.target.checked
                            })} /></div> */}
                    </div>

                    <div className="minimum-penalty-rule-activation net-tax-container">
                        <PenaltyLabel value={'Use minimum penalty rule for income taxes'} enabled={penalty.enabled} type={"normal"} />

                        {/* <div className="check-box"><Checkbox disabled = {!penalty.enabled} tabIndex={0} checked={penalty.useMinimumPenalty} onChange={e=>setPenalty({
                                ...penalty,
                                useMinimumPenalty: e.target.checked
                            })} /></div> */}
                        <IOSSwitch disabled={!penalty.enabled} checked={penalty.useMinimumPenalty} onChange={e => setPenalty({
                            ...penalty,
                            useMinimumPenalty: e.target.checked
                        })} />
                        {/* <div className={!penalty.enabled &&  "disabled-text"}>Use minimum penalty rule for income taxes</div> */}
                    </div>
                </div>
   {/*               <div className="bottom-container">
                    
                       
                    <div className="show-tips">
                        <PenaltyLabel value={'Show tips?'} enabled={penalty.enabled} type={"header"} />
                        <IOSSwitch disabled={!penalty.enabled} checked={penalty.showTips} onChange={e => setPenalty({
                            ...penalty,
                            showTips: e.target.checked
                        })} />

                    </div> 
                </div>*/}
                
                {/* {penalty.showTips && <Tips />} */}
            </div>
           
            <div className="action-buttons">
                <button className='action-button cancel' type="Cancel" value={"Cancel"} onClick={onClickCancel}>Cancel</button>
                <button className='action-button save' type="Save" value={"Save"} onClick={onClickOk}>Save</button>
            </div>
            
			<div className="accordion">
                <div className="accordion-header" onClick={toggleAccordion}>
                    <PenaltyLabel value={'Penalty Details'} enabled={true} type="header" />
                    <div className="icon">
                    {penalty.showTips ? <img src={AccordionMinus} alt="Close Icon" /> : <img src={AccordionPlus} alt="Close Icon" />}
                    </div>
                </div>
                {penalty.showTips && (
                    <div className="accordion-content">
                        <Tips />
                    </div>
                )}
            </div>
            <PenaltyDiscardAlert title={'Discard Unsaved Changes'} message={'If you navigate away from this page, you will lose your changes.'} open={showAlert} onClose={onCloseErrorAlert} onSave={onSave} onDiscard={onDiscard} />
            {
          showErroMessage && <AlertDialogWithCancel title={errorTitle} message={errorMessage} open={onCloseDateErrorAlert} hideAcceptButton={true} cancelButtonTitle={"OK"} onCancel={onCloseDateErrorAlert} />
        }
        </div>
    )
});

export default Penalty6651A1;
