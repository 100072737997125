import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'react-datepicker/dist/react-datepicker.css';
import axios from "axios";
import moment from 'moment';
import ReactDatePicker from './ReactDatePicker';
import MaterialSelect from './MaterialSelect';
import Penalties from './penalties/Penalties';
import LoadTablePicker from './LoadTablePicker';
import { LicenseManager } from 'ag-grid-enterprise';
import StatesList from './StatesList';
import './Styles.css'
import Penalty6621C from './penalties/penalty6621C/Penalty6621C';
import './penalties/Penalties.css'
import CCorpPenalty from './penalties/ccorp/CCorpPenalty';
import Compute from './compute/Compute';
import CustomTextField from './TextField/CustomTextField';
import EditIcon from '../assets/edit-icon.svg'
import PopupCellRenderer from './Popup/PopupCellRenderer';
import useEvents from '../hooks/useEvents';
import AlertDialogWithCancel from './AlertDialogWithCancel'
import _, { isEmpty } from 'lodash';

LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-050648 )in_excess_of_the_licence_granted_is_not_permitted_Please_report_misuse_to( legal@ag-grid.com )__For_help_with_changing_this_key_please_contact( info@ag-grid.com )_( TimeValue Software )is_granted_a( Single Application )Developer_License_for_the_application( TimeValue Software )only_for( 1 )Front-End_JavaScript_developer_All_Front-End_JavaScript_developers_working_on( TimeValue Software )need_to_be_licensed_( TimeValue Software )_has_not_been_granted_a_Deployment_License_Add-on_This_key_works_with_AG_Grid_Enterprise_versions_released_before( 12 November 2024 )__[v2]_MTczMTM2OTYwMDAwMA==77ac3c43384b5d91db4d31ed2467517a");

const TaxInterestForm = (props) => {
  const { tValue, fileIndex, filedata } = props;
  
  console.log("tValue");
  const [curData, setCurData] = useState(_.cloneDeep(filedata[fileIndex]));
  
  const { penaltyIndex } = props;
  const [gridApi, setGridApi] = useState(null);
  const [openTabs, setOpenTabs] = useState(props.openPenalty || false);
  const [openComputeDialog, setOpenComputeDialog] = useState(false);
  const [computePayload, setComputePayload] = useState({});
  const [openTablesPicker, setopenTablesPicker] = useState(false);
  const [showErroMessage, setShowErroMessage] = useState(false);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [amountError, setAmountError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [computeData, setComputeData] = useState('');
  const gridRef = useRef(null);
  const todayDate = new Date();
  const undoRedoCellEditing = true;
  const undoRedoCellEditingLimit = 20;
  const [states, setStates] = useState([]);
  const [state, setState] = useState(props.tValue.state);
  const [openStateList, setopenStateList] = useState(false);
  const [user, setUser] = useState(tValue.user);
  const [selectedPenalty, setSelectedPenalty] = useState(null);
  const [openPenalty, setOpenPenalty] = useState(false);
  const [hotInterest, setHotInterest] = useState(props.tValue.hotInterest);
  const [cCorp, setcCorp] = useState(props.tValue.cCorp);
  const [openHotInterest, setOpenHotInterest] = useState(false);
  const [openCCorp, setopenCCorp] = useState(false);

  const [selectedPenaltyIndex, setSelectedPenaltyIndex] = useState(penaltyIndex);


  const [penalties, setPenalties] = useState(props.tValue.penalties);


  const getContextMenuItems = useCallback((params) => {
    var result = [
      {
        name: 'Add New',
        action: () => {
          addRow(params.api);
        }
      },

      {
        name: 'Insert',
        disabled: params.node === null,
        action: () => {
          if (params.node !== null) {
            insertRow(params.api, params);
          }
        }
      },

      {
        name: 'Delete Row',
        disabled: params.node === null,
        action: () => {
          if (params.node !== null) {
            console.log("delete");
            deleteRow(params.api, params.node.data);
          }

        }

      },
      'separator',
      {
        name: 'Undo',
        disabled: params.api.getCurrentUndoSize() < 1,
        action: () => {
          undo(params.api);
        }
      },
      {
        name: 'Redo',
        disabled: params.api.getCurrentRedoSize() < 1,
        action: () => {
          redo(params.api);
        }
      },

      'separator',
      'copy',


    ];
    return result;
  }, [props]);


  const { eventNames } = useEvents();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const eventTypes = Object.keys(eventNames).map((key, i) => {
    return key;
  })

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      singleClickEdit: true,
      lockPosition: true,
      resizable: false,
    };
  }, []);


  const formattedNumber = number => {
    return number.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
  }

  const initialRow = {
    eventType: eventTypes[0],
    eventDate: `${formattedNumber(todayDate.getMonth() + 1)}/${formattedNumber(todayDate.getDate())}/${formattedNumber(todayDate.getFullYear())}`, // Set initial date value
    eventAmount: '',
    eventDescription: '',
  };

  const [rowData, setRowData] = useState([initialRow]);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const nextEventDate = params => {
    const date = new Date(params);
    date.setDate(date.getDate() + 1);
    let formattedDay = formattedNumber(date.getDate());
    let formattedMonth = formattedNumber(date.getMonth() + 1);
    return `${formattedMonth}/${formattedDay}/${date.getFullYear()}`;
  }

  const nextEventType = params => {
    const lastRowEventIndex = eventTypes.indexOf(params);
    const index = lastRowEventIndex === (eventTypes.length - 1) ? 0 : (lastRowEventIndex + 1)
    return eventTypes[1];
  }
  const handleDocumentKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
    }
  };
  setTimeout(function () {
    // Add a keydown event listener to the document body
    var agGridTable = document.querySelector('#aggrid-table');
    if (agGridTable) {
      agGridTable.addEventListener('keydown', handleDocumentKeyDown);
    }
  }, 2000);
  // Rest of your component code

  const handleCellKeyDown = (event) => {
    const { rowIndex, column, api } = event;
    console.log(event.event.key);
    const displayedRowCount = api.getDisplayedRowCount();
    console.log("123 me here");
    if ((event.event.key === 'Tab' || event.event.key === 'Enter') && !event.event.shiftKey) {
      console.log("123 me here");
      if (rowIndex === displayedRowCount - 1 && column.getColDef().field === columnDefs[columnDefs.length - 1].field) {
        event.event.preventDefault();
        const { data } = api.getDisplayedRowAtIndex(api.getLastDisplayedRow());
        if (data === null) return;
        const { eventType, eventDate } = data;
        const nextDate = nextEventDate(eventDate);
        const nextName = nextEventType(eventType);
        const newRow = { eventType: nextName, eventDate: '', eventAmount: '', eventDescription: '' };
        api.applyTransaction({ add: [newRow] });
        api.redrawRows();
        event.event.preventDefault();
        setTimeout(() => {
          const newRowIndex = rowIndex + 1;
          const newColId = columnDefs[1].field;
          event.api.setFocusedCell(newRowIndex, newColId);
          api.startEditingCell({ rowIndex: newRowIndex, colKey: newColId });
        });
        onAgGridValueChanged();
      }
      else {
        const nextColumn = findNextColumn(column.colId)
        if (nextColumn != null) {
          api.startEditingCell({
            rowIndex: rowIndex,
            colKey: nextColumn.field
          });
        } else {
          api.startEditingCell({
            rowIndex: rowIndex + 1,
            colKey: columnDefs[1].field
          });
        }

      }
    }
  }

  const currencyFormatter = (params) => {
    console.log("Value of the currency field ");
    console.log("amt", params.value);
    if(params.value === null){
      return '';
    }
    if(params.value === ''){
      return '';
    }
    const amt = parseFloat(params.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return amt;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Backspace' && event.target.type !== 'text') {
      const selectedRows = gridApi.getSelectedRows();
      if (selectedRows.length > 0) {
        gridApi.applyTransaction({ remove: selectedRows });
        gridApi.refreshCells();
        onAgGridValueChanged();
      }
    }
  };



  const getCustomAmountEditor = () => {
    function CustomAmountEditor() { }

    CustomAmountEditor.prototype.init = function (params) {
      console.log("params.value", params.value);
      this.input = document.createElement('input');
      this.input.type = 'text';
      this.input.value = params.value;
      this.input.className = 'custom-amount-input';

      // Store the original value for reference
      this.originalValue = params.value;
      let alertShown = false;
      this.input.addEventListener('focus', () => {
        // Clear the input when it's focused if the original value is 0
        if (this.originalValue === 0) {
          this.input.value = '';
        }
        this.input.select();
      });

      this.input.addEventListener('blur', (event) => {
        const inputValue = this.input.value.trim();
        
        // Check if the entered value is within the valid range
        const numericValue = parseFloat(inputValue.replace(/[$,]/g, ''));
        if (inputValue === '' || inputValue == 0) {
          this.input.value = 0;
        } 
        else if (inputValue < 0) {
          console.log("this.input.value",this.input.value)
          const s = Math.abs(this.input.value);
          this.input.value = s;
          //this.input.value = Math.abs(this.input.value);
          console.log(this.input.value)
          setAmountError(true);
          setErrorTitle('Invalid Amount');
          setErrorMessage('Negative numbers are not allowed here.');
          setShowErroMessage(true);
        } 
        else if (/-/.test(this.input.value.slice(1))) {
          console.log("test kl");
          this.input.value = Math.abs(this.input.value.replace(/-/g, ''));
          setAmountError(true);
          setErrorTitle('Invalid Amount');
          setErrorMessage('Negative numbers are not allowed here.');
          setShowErroMessage(true);
        }
        else if ((isNaN(numericValue) || numericValue < -9999999999.99 || numericValue > 9999999999.99) && (numericValue !== 0 || numericValue !== '')) {
          // this.input.focus();
          setAmountError(true);
          setErrorTitle('Invalid Amount');
          setErrorMessage('Entered value is not within the range of 9,999,999,999.99');
          setShowErroMessage(true);
        } else {
          // If the input is empty, set it back to the original value
          //if (inputValue === '') {
          //this.input.value = this.originalValue.toString();
          //}
        }
      });

      this.input.addEventListener('input', () => {
        const inputValue = this.input.value.trim();

        if (inputValue.endsWith('k') || inputValue.endsWith('m') || inputValue.endsWith('h') || inputValue.endsWith('b') || inputValue.endsWith('t')) {
          // Handle key presses for conversion
          const lastChar = inputValue.slice(-1).toLowerCase();
          let numericValue = parseFloat(inputValue);
          if (!isNaN(numericValue)) {
            if (lastChar === 'k') {
              // Convert to thousands (multiply by 1000)
              numericValue *= 1000;
            } else if (lastChar === 'h') {
              // Convert to millions (multiply by 1000000)
              numericValue *= 100000;
            } else if (lastChar === 'm') {
              // Convert to millions (multiply by 1000000)
              numericValue *= 1000000;
            } else if (lastChar === 'b') {
              // Convert to billions (multiply by 1000000000)
              numericValue *= 1000000000;
            }
            else if (lastChar === 't') {
              // Convert to billions (multiply by 1000000000)
              numericValue *= 10;
            }
            this.input.value = numericValue.toLocaleString();

          }
        }
      });
    };

    CustomAmountEditor.prototype.getGui = function () {
      console.log("vijay12345", this.input.value);
      return this.input;
    };

    CustomAmountEditor.prototype.afterGuiAttached = function () {
      console.log("vijay123", this.input.value);
      this.input.focus();
    };

    CustomAmountEditor.prototype.getValue = function () {
      console.log("vijay", this.input.value);
      const inputValue = this.input.value;
      // Remove currency symbol and commas, if any
      const numericValue = parseFloat(inputValue.replace(/[$,]/g, ''));
      console.log("numericValue", numericValue);
      return isNaN(numericValue) ? 0 : numericValue;
    };
    console.log("vijay 00");
    CustomAmountEditor.prototype.destroy = function () { };

    return CustomAmountEditor;
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setTimeout(() => {
      console.log("focus ere");
      const newColId = columnDefs[1].field;
      const firstRowNode = params.api.getDisplayedRowAtIndex(0);
      if (firstRowNode) {
        params.api.setFocusedCell(0, 'eventType');
        params.api.startEditingCell({
          rowIndex: firstRowNode.rowIndex,
          colKey: columnDefs[1].field, // Assuming the first column should be edited
        });
      }
    }, 100);
  };

  const components = {
    customAmountEditor: getCustomAmountEditor(),
  };
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const loadStates = () => {
    if (states.length > 0) return;
    const tableurl = BASE_URL+"/api/tables";
    axios.get(
      tableurl
    ).then((response) => {
      setStates(response.data);
      onStateChanged(response.data[0])
    }).catch((error) => { });

  }


  const addRow = (api) => {

    const displayedRowCount = api.getDisplayedRowCount();
    const lastRowIndex = displayedRowCount - 1;

    const lastRowNode = api.getDisplayedRowAtIndex(lastRowIndex);
    // if (!lastRowNode) {
    //   return; // No rows are displayed, return early
    // }
    let nextName = "Tax";
    if(lastRowNode) {
      const { data } = api.getDisplayedRowAtIndex(api.getLastDisplayedRow());

      if (data === null) return;
      const { eventType, eventDate } = data;


      // const nextDate = nextEventDate(eventDate);
      nextName = nextEventType(eventType);
    }
    

    const newRow = {
      eventType: nextName?nextName:"Tax",
      eventDate: '',
      eventAmount: '',
      eventDescription: '',
    };

    api.applyTransaction({ add: [newRow] });
    api.redrawRows();

    setTimeout(() => {
      const newRowIndex = lastRowIndex + 1;
      const newColId = columnDefs[1].field;
      api.setFocusedCell(newRowIndex, newColId);
      api.startEditingCell({ rowIndex: newRowIndex, colKey: newColId });
    });
    onAgGridValueChanged(api);

  };

  const insertRow = (api, row) => {
    const rowIndex = row.node.rowIndex;

    const rowNode = api.getDisplayedRowAtIndex(rowIndex);
    if (!rowNode) {
      return; // No rows are displayed, return early
    }

    const { data } = api.getDisplayedRowAtIndex(rowIndex);
    if (data === null) return;
    const { eventType, eventDate } = data;


    // const nextDate = nextEventDate(eventDate);
    const nextName = nextEventType(eventType);

    const newRow = {
      eventType: nextName,
      eventDate: '',
      eventAmount: '',
      eventDescription: '',
    };

    api.applyTransaction({ add: [newRow], addIndex: rowIndex });
    api.redrawRows();

    setTimeout(() => {
      const newRowIndex = rowIndex;
      const newColId = columnDefs[1].field;
      api.setFocusedCell(newRowIndex, newColId);
      api.startEditingCell({ rowIndex: newRowIndex, colKey: newColId });
    });
    onAgGridValueChanged(api);
  }

  const deleteRow = (api, data) => {
    api.applyTransaction({ remove: [data] });
    api.refreshCells();
    onAgGridValueChanged(api);
  }
  
  const onAgGridValueChanged = (api) => {
    let gd = gridApi;
    if (!gridApi && api) {
      gd = api;
    }
    const allRowDatum = [];
    //gd.clearFocusedCell();
    const p = penalties;
    let taxCount = 0
    let taxDate = '';
    let paymentCount = 0;
    gd.forEachNode((rowNode, index) => {
      const rowData = rowNode.data;
      const datum = {}
      datum.eventType = rowData.eventType//eventNames[`${}`]
      const utcDate = rowData.eventDate
      datum.eventDate = utcDate
      datum.eventDescription = rowData.eventDescription
      datum.eventAmount = rowData.eventAmount
      allRowDatum.push(datum)
      const eventType = eventNames[`${rowData.eventType}`]
      console.log(eventType);
      const isValuesUpdated = false;
      if (eventType === 'TXIEventTypeTax' || eventType === 'TXIEventTypeMotivatedTax') {
          taxCount += rowData.eventAmount;
          taxDate = rowData.eventDate;
      }
      if (eventType === 'TXIEventTypePayment') {
          if(taxDate == rowData.eventDate)
            paymentCount += rowData.eventAmount;
      }
      console.log("taxCount " + taxCount);
      console.log("paymentCount "+ paymentCount );
      let taxDueAmount = taxCount - paymentCount;
      console.log("taxDueAmount "+ taxDueAmount );
      for (let i = 0; i < penalties.length; i++) {
        p[i].amount = taxDueAmount//p[i].amount !== rowData.eventAmount ? p[i].amount : rowData.eventAmount
        cCorp.threshHoldAmount =  taxDueAmount;
        p[i].dueDate = taxDate //p[i].dueDate !== rowData.eventDate ? p[i].dueDate : rowData.eventDate
      }
      });
    const newValue = {
      ...props.tValue,
      // penalties: p,
      events: allRowDatum
    }
    console.log("newValue", newValue);
    console.log(curData)
    const isChanged = !_.isEqual(newValue,curData);
    console.log("ischa",isChanged);
    props.onChangeTvalue(newValue, isChanged);
    console.log("gd.rowIndex",api);
    let nextColumn= '';
    if(api && api.column) {
       nextColumn = findNextColumn(api.column.colId)
    }
    
    console.log("column.getColDef().field", nextColumn);
    setTimeout(() => {
      if(nextColumn){
        gd.startEditingCell({
          rowIndex: api.rowIndex,
          colKey: nextColumn.field,
        });
      }
      
    });
  }

  const handleDateError = (error) => {
    setErrorTitle("Invalid Date");
    setErrorMessage(error);
    setShowErroMessage(true);
    setDateError(true);
  }

  const columnDefs = useMemo(() => [
    {
      headerName: "#",
      cellRenderer: PopupCellRenderer,
      flex: .3,
      editable: false,
      suppressMenu: true,
      cellRendererParams: {
        addNewRow: addRow,
        insertRow: insertRow,
        deleteRow: deleteRow
      },
      headerClass: 'header-center'
    },
    {
      headerName: 'Event',
      field: 'eventType',
      cellEditor: MaterialSelect,
      flex: .85,
      cellEditorParams: {
        values: eventTypes,
      },
      cellEditorPopup: true,
      suppressMenu: true,
    },
    {
      headerName: 'Date',
      field: 'eventDate',
      cellEditor: ReactDatePicker,
      cellEditorParams: {
        onError: handleDateError,
      },
      flex: .85,
      cellEditorPopup: true,
      suppressMenu: true,
      headerClass: 'header-center'
    },
    {
      headerName: 'Amount',
      field: 'eventAmount',
      cellEditor: 'customAmountEditor',
      valueFormatter: currencyFormatter,
      flex: 1,
      suppressMenu: true,
      type: 'rightAligned',
    },
    {
      headerName: 'Memo',
      field: 'eventDescription',
      flex: 2,
      cellEditorParams: {
        maxLength: 40 // Define the character limit
      },
      suppressMenu: true,
      headerClass: "header-center header-memo",
    },
  ], [eventTypes]);


  useEffect(() => {
    if (states.length === 0) {
      loadStates();
    }
  }, [])

  useEffect(() => {
    if (gridApi) {
      setTimeout(() => {
        const firstRowNode = gridApi.getDisplayedRowAtIndex(0);
        if (firstRowNode) {
          gridApi.startEditingCell({
            rowIndex: firstRowNode.rowIndex,
            colKey: columnDefs[1].field, // Assuming the first column should be edited
          });
        }
      }, 100); // Adjust the delay as needed
    }
  }, [fileIndex])
  const handleTabPress = (event) => {
    if (event.key === 'Tab') {
      gridApi.stopEditing();
      event.preventDefault(); // Prevent the default tab behavior
      const firstRowNode = gridApi.getDisplayedRowAtIndex(0);
      if (firstRowNode) {
        gridApi.setFocusedCell(0, 'eventType');
        gridApi.startEditingCell({
          rowIndex: firstRowNode.rowIndex,
          colKey: columnDefs[1].field, // Assuming the first column should be edited
        });


      }
    }
  };
  useEffect(() => {
    setPenalties(tValue.penalties);
    setHotInterest(tValue.hotInterest);
    setcCorp(tValue.cCorp);
    setUser(tValue.user);
    setState(tValue.state);
    setRowData(tValue.events);
  }, [tValue.user, tValue.hotInterest, tValue.cCorp, tValue.state, tValue.penalties])


  const handleOutsideClick = useCallback((event) => {
    console.log(event.srcElement.classList);
    if(gridRef.current  && !event.srcElement.classList.contains('MuiButtonBase-root') && !event.srcElement.classList.contains('custom-amount-input') && !event.target.closest('.react-calendar__tile') && !event.srcElement.classList.contains('MuiSelect-select') && !event.srcElement.classList.contains('date-field') && !event.srcElement.classList.contains('MuiMenuItem-root') && !event.srcElement.classList.contains('calendar-icon') && !event.srcElement.classList.contains('react-calendar__tile') && ![...event.srcElement.classList].some(className => className.startsWith('react-calendar'))) {
      console.log("out click");
      gridApi.stopEditing();
    }
    if (gridRef.current) {
      console.log("in click");
      // Stop editing in ag-Grid
     // gridApi.stopEditing();
    }
  }, [gridApi]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [gridApi, handleOutsideClick]);



  const saveAsFile = () => {
    gridApi.stopEditing();
    const allRowDatum = [];
    gridApi.forEachNode((rowNode, index) => {
      const rowData = rowNode.data;
      const datum = {}
      datum.eventType = eventNames[`${rowData.eventType}`]
      const utcDate = rowData.eventDate
      datum.eventDate = utcDate
      datum.eventDescription = rowData.eventDescription
      datum.eventAmount = rowData.eventAmount
      allRowDatum.push(datum)
    });
    const payload = {
      events: allRowDatum,
      state: state,
      penalties: penalties,
      user: user,
      hotInterest: hotInterest,
      cCorp: cCorp
    };
    const fileurl = BASE_URL+"/api/files";
    axios.post(
      fileurl, payload)
      .then(res => {
        console.log(res.data);
        const data = res.data.data;
        console.log(data);
      })
      .catch(err => {
        setErrorTitle('API Error');
        setErrorMessage('Oops! Something went wrong. Please try again or contact your administrator');
        setShowErroMessage(true)
      })
  }

  const events = () => {
    const allRowDatum = [];
    gridApi.forEachNode((rowNode, index) => {
      const rowData = rowNode.data;
      const datum = {}
      datum.eventType = eventNames[`${rowData.eventType}`]
      const utcDate = rowData.eventDate
      datum.eventDate = utcDate
      datum.eventDescription = rowData.eventDescription
      datum.eventAmount = rowData.eventAmount
      allRowDatum.push(datum)
    });
    return allRowDatum;
  }

  const onCompute = params => {
    gridApi.stopEditing();
    props.onCompute();
  }

  const collapseButtonTapped = () => {
    const pageSize = gridApi.paginationGetPageSize();
    gridApi.paginationSetPageSize(pageSize === 3 ? 0 : 3);
  }

  const gridOptions = {
    getRowStyle: (params) => {
      if (params.node.rowIndex % 2 === 0) {
        // Even row
        return { background: 'white' }; // Set your desired background color
      } else {
        // Odd row
        return { background: '#FAFAFA' }; // Set your desired background color
      }
    },
    stopEditingWhenValidationFails: false,
    suppressAnimationFrame: true,
    singleClickEdit: true,
  };

  const findNextColumn = (current) => {
    const indexOfCurrentColumn = columnDefs.findIndex(c => {
      console.log(c.field);
      return c.field === current
    });
    return columnDefs[indexOfCurrentColumn + 1];
  }

  const navigateToNextHeader = (params) => {
    if (params.nextHeaderPosition === undefined || params.nextHeaderPosition.headerRowIndex === -1) {
      params.api.startEditingCell({
        rowIndex: 0,
        colKey: columnDefs[1].field
      });
    } else {
      return {
        headerRowIndex: 0,
        column: params.nextHeaderPosition.column
      };
    }
  }
  const onNewClick = (e) => {
    setRowData([initialRow]);
  }
  const onTabsOpen = (e) => {
    const taxRows = [];
    gridApi.clearFocusedCell();
    const p = penalties;
    gridApi.forEachNode((rowNode, index) => {
      const rowData = rowNode.data
      const eventType = eventNames[`${rowData.eventType}`]
      console.log(eventType);
      const isValuesUpdated = false;
      if (eventType === 'TXIEventTypeTax' || eventType === 'TXIEventTypeMotivatedTax') {
        if (!isValuesUpdated) {
          for (let i = 0; i < penalties.length; i++) {
            p[i].amount = rowData.eventAmount//p[i].amount !== rowData.eventAmount ? p[i].amount : rowData.eventAmount
            p[i].dueDate = rowData.eventDate //p[i].dueDate !== rowData.eventDate ? p[i].dueDate : rowData.eventDate
          }
        }
        taxRows.push(rowData);
      }
    });
    setPenalties(p);
    if (taxRows.length === 1) {
      setOpenTabs(true);
    } else {
      setErrorTitle('TaxInterest');
      setErrorMessage('Penalties are not available either because there is no underpayment, or there are multiple tax due events');
      setShowErroMessage(true)
    }

  }

  const onTapHotInterest = () => {
    setOpenHotInterest(true);
  }

  const onCloseHotInterest = () => {
    props.closeHotInterest(false);
    setOpenHotInterest(false);
  }

  const onSubmitHotInterest = (p) => {
    const newValue = {
      ...props.tValue,
      hotInterest: p
    }
    const isChanged = !_.isEqual(newValue,curData);
    console.log("ischa",isChanged);
    props.onChangeTvalue(newValue, isChanged);
    setOpenHotInterest(false)
  }

  const onCloseCCorp = () => {
    props.closeCCorp(false);
    setopenCCorp(false);
  }

  const onSubmitCCorp = (p) => {
    const newValue = {
      ...props.tValue,
      cCorp: p
    }
    const isChanged = !_.isEqual(newValue,curData);
    console.log("ischa",isChanged);
    props.onChangeTvalue(newValue, isChanged);
    setopenCCorp(false);
  }

  const onClosTabs = () => {
    props.closePenalty(false)
    setOpenTabs(false);
    setopenTablesPicker(false);
  }

  const onCloseErrorAlert = () => {
    console.log("setAmountError", amountError);
    setShowErroMessage(false);
    setTimeout(function () {
      if(amountError) {
        setAmountError(false);
        moveToAmountCell();
      }
      if(dateError) {
        setDateError(false);
        moveToDateCell();
      }
    });
  }
  const moveToAmountCell = () => {
    const focusedCell = gridApi.getFocusedCell();
    console.log("focusedCell", focusedCell);
      const rowIndex = focusedCell.rowIndex;
      gridApi.startEditingCell({ rowIndex: rowIndex, colKey: 'eventAmount' });
    
  }
  const moveToDateCell = () => {
    const focusedCell = gridApi.getFocusedCell();
    console.log("focusedCell", focusedCell);
      const rowIndex = focusedCell.rowIndex;
      gridApi.startEditingCell({ rowIndex: rowIndex, colKey: 'eventDate' });
    
  }

  const undo = (api) => {
    api.undoCellEditing();
  }

  const redo = (api) => {
    api.redoCellEditing();
  }

  const onStateChanged = (state) => {
    setState(state);
    const newValue = {
      ...props.tValue,
      state: state
    }
    const isChanged = !_.isEqual(newValue,curData);
    console.log("ischa",isChanged);
    props.onChangeTvalue(newValue, isChanged);
    setopenStateList(false);
  }

  const onCloseStates = () => {
    setopenStateList(false);
  }

  const onOpenStates = () => {
    setopenStateList(true);
  }

  const activePenaltyTapped = (p) => {
    const index = penalties.findIndex(item => item.id === p.id);
    setSelectedPenaltyIndex(index)
    props.closePenalty(true)
  }

  const onCloseComputeDialog = (p) => {
    setOpenComputeDialog(false);
  }

  const getSelectedPenalties = () => {
    const p = penalties && penalties.filter((p) => p.enabled === true);
    const onlyTwoPenalties = [];
    if(p){
      for (let i = 0; i < p.length; i++) {
        onlyTwoPenalties.push(p[i]);
      }
    }
    
    return onlyTwoPenalties
  }



  const cellStyle = { borderRight: '1px solid #E8E8E8' };
  const getPenalties = getSelectedPenalties(); // Assuming getSelectedPenalties() returns an array of penalties
  const displayedPenalties = getPenalties.slice(0, 2);

  return (
    <>
      <div className='ag-grid-container'>
        <div className="ag-grid-section">
          <div className="table-and-rates-container">
            <div className="table-name" onClick={onOpenStates}>
              <div className="table-name-value">
                Table: {`${state.name || `Interest Rates`} Interest Rates - ${state.code || `FEDERAL`}`}
                <span className="rates-upto" >
                  Rates current to -  {state.rateCurrentsTo || `${todayDate.toDateString()}`}
                </span>
                <img src={EditIcon} className='rates-edit' onClick={onOpenStates} />
              </div>
            </div>
          </div>

          <div className="name-and-report-container">
            <div className="name-and-id-container">
              <CustomTextField label={"Name: "} value={user.name} maxLength="40" placeholder={"Type name"} id="user_name"  onChange={(e) => {
                const newUser = { ...user, name: e.target.value }
                const newValue = {
                  ...props.tValue,
                  user: newUser
                }
                setUser(newUser)
                const isChanged = !_.isEqual(newValue,curData);
                console.log("ischa",isChanged);
                props.onChangeTvalue(newValue, isChanged);
              }

              } />
              <CustomTextField label={"ID: "} value={user.id} maxLength="11" placeholder={"Type ID"} id="user_id" onTabPress={handleTabPress} onChange={(e) => {
                const newUser = { ...user, id: e.target.value }
                let newValue = [];
                if(e.target.value!=''){
                   newValue = {
                    ...props.tValue,
                    user: newUser
                  }
                } else {
                  newValue = props.tValue;
                }
                
                setUser(newUser)
                const isChanged = !_.isEqual(newValue,curData);
                console.log("ischa",isChanged);
                props.onChangeTvalue(newValue, isChanged);
              }

              } />
            </div>

            <div className="report-container">
              <input className='report-button' type="button" value="Calculate" onClick={onCompute} />
            </div>
          </div>

          <div id="aggrid-table" className="ag-theme-alpine" style={{ fontFamily: 'Inter', height: '51vh', width: '100%' }} onKeyDown={handleKeyDown} ref={gridRef}>
            <AgGridReact
              ref={gridRef}
              gridOptions={gridOptions}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              suppressWarning={true}
              components={components}
              onGridReady={onGridReady}
              rowData={rowData}
              animateRows={true}
              onCellKeyDown={handleCellKeyDown}
              paginationPageSize={0}
              pagination={true}
              suppressPaginationPanel={true}
              suppressScrollOnNewData={false}
              rowSelection='multiple'
              /*stopEditingWhenCellsLoseFocus={true}*/
              // popupParent={document.querySelector('ag-grid-container')}
              popupParent={document.body}
              getContextMenuItems={getContextMenuItems}
              undoRedoCellEditing={undoRedoCellEditing}
              undoRedoCellEditingLimit={undoRedoCellEditingLimit}
              cellStyle={cellStyle}
              onCellValueChanged={onAgGridValueChanged}
            // onRowDataUpdated={onAgGridValueChanged}
            />
          </div>
        </div>



        {props.openPenalty && <Penalties index={selectedPenaltyIndex} onOpen={props.openPenalty} onClose={onClosTabs} values={penalties} onChangedPenalties={p => {
          console.log(p)
          const newValue = {
            ...props.tValue,
            penalties: p
          }
          const isChanged = !_.isEqual(newValue,curData);
                console.log("ischa",isChanged);
                props.onChangeTvalue(newValue, isChanged);
        }} />}
        <LoadTablePicker open={openTablesPicker} onClose={onClosTabs} />
        {openStateList && <StatesList states={states} onOpen={openStateList} onSubmit={onStateChanged} onClose={onCloseStates} />}
        {/* {showErroMessage && <AlertDialog title={errorTitle} message={errorMessage} open={showErroMessage} onClose={onCloseErrorAlert} />} */}
        {
          showErroMessage && <AlertDialogWithCancel title={errorTitle} message={errorMessage} open={onCloseErrorAlert} hideAcceptButton={true} cancelButtonTitle={"OK"} onCancel={onCloseErrorAlert} />
        }
        {props.openHotInterest && <Penalty6621C value={hotInterest} onOpen={props.openHotInterest} onClose={onCloseHotInterest} title={hotInterest.name} onSubmit={onSubmitHotInterest} />}
        {props.openCCorp && <CCorpPenalty value={cCorp} name={user.name} rowValue={gridApi} onOpen={props.openCCorp} onClose={onCloseCCorp} title={cCorp.name} onSubmit={onSubmitCCorp} />}
        {openComputeDialog && <Compute openComputeDialog={openComputeDialog} onCloseComputeDialog={onCloseComputeDialog} value={computeData} payload={computePayload} />}
        <div className="acitve-penalties-container">
          <div className="active-penalties-header">
            Active Penalties:
          </div>
          {
            penalties.filter((p) => p.enabled === true).length === 0 && <div onClick={() => activePenaltyTapped(penalties[0])} className="none">None</div>
          }

          <div className="active-penalties">
            {
              displayedPenalties.map((p) => <div className='active-penalty' onClick={() => activePenaltyTapped(p)}><span>{p.name} - IRC 	&sect;{p.section}</span> </div>)
            }
            {getPenalties.length > 2 && (
              <div className="more-link" onClick={() => activePenaltyTapped(penalties[0])} >
                <span>...</span>
              </div>
            )}
          </div>

        </div>
      </div>

    </>
  );
};

export default TaxInterestForm;
