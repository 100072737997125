// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.menu-container {
    font-family: 'Inter';
    position: absolute;
    z-index: 1000;
    min-width: 9rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
  }
  
  .menu-item:hover {
    cursor: pointer;
    background: rgb(240, 240, 240);
  }
  
  .menu-item.active {
    background-color: #dc3545;
    border-color: #dc3545;
    color: white;
  }
  .menu-item {
    display: block;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    border: 0;
  }

  .menu-item.hide{
    display: none;
  }

  .btn{
    display: flex;
    background-color: transparent;
    border: none;
    cursor: pointer;
    gap: 10px;
    align-items: center;
    justify-content: center;
    height: 44px;
  }

  `, "",{"version":3,"sources":["webpack://./src/components/Popup/PopupCellRenderer.css"],"names":[],"mappings":";AACA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,iBAAiB;IACjB,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,sBAAsB;IACtB,4BAA4B;IAC5B,qCAAqC;IACrC,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,8BAA8B;EAChC;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;IACrB,YAAY;EACd;EACA;IACE,cAAc;IACd,qBAAqB;IACrB,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,mBAAmB;IACnB,qBAAqB;IACrB,mBAAmB;IACnB,SAAS;EACX;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,SAAS;IACT,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;EACd","sourcesContent":["\n.menu-container {\n    font-family: 'Inter';\n    position: absolute;\n    z-index: 1000;\n    min-width: 9rem;\n    padding: 0.5rem 0;\n    margin: 0;\n    font-size: 1rem;\n    text-align: left;\n    list-style: none;\n    background-color: #fff;\n    background-clip: padding-box;\n    border: 1px solid rgba(0, 0, 0, 0.15);\n    border-radius: 0.25rem;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    font-size: 14px;\n  }\n  \n  .menu-item:hover {\n    cursor: pointer;\n    background: rgb(240, 240, 240);\n  }\n  \n  .menu-item.active {\n    background-color: #dc3545;\n    border-color: #dc3545;\n    color: white;\n  }\n  .menu-item {\n    display: block;\n    padding: 0.25rem 1rem;\n    clear: both;\n    font-weight: 400;\n    color: #212529;\n    text-align: inherit;\n    text-decoration: none;\n    white-space: nowrap;\n    border: 0;\n  }\n\n  .menu-item.hide{\n    display: none;\n  }\n\n  .btn{\n    display: flex;\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n    gap: 10px;\n    align-items: center;\n    justify-content: center;\n    height: 44px;\n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
