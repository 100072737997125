import React, { useState } from 'react';
import axios from 'axios';
import { useSession } from '@clerk/clerk-react';
import toast from 'react-hot-toast';
import useClerk from '../../hooks/userClerk'
const CreateUser = () => {
    const {getClerkToken} = useClerk();
    const { isLoaded, session, isSignedIn } = useSession();
    console.log(session);
    // State variables to hold form data and validation errors
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        password: ''
    });

    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        password: ''
    });

    // Handle form submit
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate form fields
        let isValid = true;
        const newErrors = { ...formErrors };

        if (formData.firstName.trim() === '') {
            newErrors.firstName = 'First Name is required';
            isValid = false;
        } else {
            newErrors.firstName = '';
        }

        if (formData.lastName.trim() === '') {
            newErrors.lastName = 'Last Name is required';
            isValid = false;
        } else {
            newErrors.lastName = '';
        }

        if (formData.emailAddress.trim() === '') {
            newErrors.emailAddress = 'Email Address is required';
            isValid = false;
        } else {
            newErrors.emailAddress = '';
        }

        if (formData.password.trim() === '') {
            newErrors.password = 'Phone number is required';
            isValid = false;
        } else {
            newErrors.password = '';
        }

        setFormErrors(newErrors);

        if (isValid) {
            try {
                const token = await getClerkToken();
                const userResponse = await axios.post(`http://localhost:4002/api/users`, formData, {headers:{'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}});
                console.log('Response:', userResponse.data); // Assuming response includes created user details
                // Reset form or perform any other actions on successful creation
                setFormData({
                    firstName: '',
                    lastName: '',
                    emailAddress: '',
                    password: ''
                });
                toast.success('User created successfully');
                console.log("user response");
                console.log(userResponse.data.id)
                const userId = userResponse.data.id; // Adjust to match your actual response structure
                const organizationId = "org_2khg7QAJycibqffN8qJGE0HPW0g"; // Replace with the actual organization ID
                const role = "org:member";
                const membershipData = {
                    userId,
                    organizationId,
                    role
                };
                //const membershipResponse = await axios.post(`http://localhost:4002/api/organizations/${organizationId}/membership/`, membershipData, {headers:{'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}});
               // console.log('Membership Response:', membershipResponse.data); // Assuming response includes membership details

            } catch (error) {
                console.error('Error submitting form:', error);
                alert('Failed to create user. Please try again later.');
            }
        }
    };

    // Handle input changes
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <form id="clerk-forms" onSubmit={handleSubmit}>
            <div>
                <label>
                    First Name:
                    <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                    />
                </label>
                {formErrors.firstName && <span style={{ color: 'red' }}>{formErrors.firstName}</span>}
            </div>
            <div>
                <label>
                    Last Name:
                    <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                    />
                </label>
                {formErrors.lastName && <span style={{ color: 'red' }}>{formErrors.lastName}</span>}
            </div>
            <div>
                <label>
                    Email Address:
                    <input
                        type="email"
                        name="emailAddress"
                        value={formData.emailAddress}
                        onChange={handleChange}
                        required
                    />
                </label>
                {formErrors.emailAddress && <span style={{ color: 'red' }}>{formErrors.emailAddress}</span>}
            </div>
            <div>
                <label>
                    Phone Number:
                    <input
                        type="text"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </label>
                {formErrors.password && <span style={{ color: 'red' }}>{formErrors.password}</span>}
            </div>
            <div>
                <label>
                    'Company Share' Folder Access Type:
                   <select>
                   <option>Full Access</option>
                   <option>Sub Folders Only</option>
                   <option>Documents Only</option>
                   <option>View Only</option>
                   <option>No Access</option>
                   </select>
                   </label>
                {formErrors.password && <span style={{ color: 'red' }}>{formErrors.password}</span>}
            </div>
            <br />
            <button className="report-button" type="submit">Create User</button>
        </form>
    );
};

export default CreateUser;
