import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSession } from '@clerk/clerk-react';
import toast from 'react-hot-toast';
import useClerk from '../../hooks/userClerk'
const UserList = () => {
    const {getClerkToken} = useClerk();
    const { isLoaded, session, isSignedIn } = useSession();
    const [users, setUsers] = useState([]);
    const userid = session.user.id;
    console.log("vijay");
    console.log(users);
    useEffect(() => {
        
        const fetchUsers = async () => {
          try {
            const token = await getClerkToken();

            const organizationId = 'org_2khg7QAJycibqffN8qJGE0HPW0g';
            const userId = "user_2khg4UsYLDoXRtEfgOBeoXS2wDy";
            const response = await axios.get(`http://localhost:4002/api/organizations/${organizationId}/members`, {headers:{'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}}); 
            setUsers(response.data.data); // Assuming your API returns an array of users
          } catch (error) {
            toast.error('Error fetching user list');
            console.error('Error fetching user list', error);
          }
        };
    
        fetchUsers();
      }, []); 
    return (
        <div>
      <h2>User List</h2>
      
      <table className="user-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Last Login</th>
            <th>Edit</th>

          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.emailAddresses[0].emailAddress}</td>
              <td>{user.lastSignInAt ? new Date(user.lastSignInAt).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }): "-"}</td>
              <td>
                <a href={`/edit-user/${user.id}`}>Edit</a>
                {/* Replace '/edit-user/' with your actual edit link route */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    );
};

export default UserList;
