import React, { useState } from 'react'
import DropDown from '../../assets/drop-down.svg'
import DropDownActive from '../../assets/drop-down-active.svg'
import './Sheet.css'
import { Popover } from '@mui/material';

import DuplicateIcon from '../../assets/duplicate-pop-over-icon.svg';
import DeleteIcon from '../../assets/delete-pop-over-icon.svg';
import EditIcon from '../../assets/edit-pop-over-icon.svg';
import CloseIcon from '../../assets/close-menu-icon.svg';
import SaveIcon from '../../assets/save-icon.svg';

import PopoverMenuItem from '../PopoverMenuItem/PopoverMenuItem';

const Sheet = (props) => {
  const {onSave, onAddNewFile, onClose, onDelete, onDuplicate, onRename, index, onSelect, selectedIndex, hasChange} = props;
  console.log("props.filesaveinfo", props);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover1' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    setAnchorEl(null);
    // Handle different options here, e.g., navigate to account settings or log out
    console.log("Selected option:", option);
  };

  const handleRename = (option) => {
    setAnchorEl(null);
    onRename(index)
  }

  const onOpenFile = (event) => {
    event.stopPropagation();
    onSelect(index)
  }

  const handleDelete = () => {
    setAnchorEl(null);
    onDelete(index);
  }

  const handleDuplicate = () => {
    setAnchorEl(null);
    onDuplicate(index);
  }

  const handleCloseFile = () => {
    setAnchorEl(null);
    onClose();
    if(index == 0){
      //onAddNewFile();
    }

  }

  const handleSave = () => {
    setAnchorEl(null);
    if(!props.fileinfo) {
      onRename(index)
    } else {
      onSave();
    }
    
    
  }

  return (
    <div className={`sheet-container${selectedIndex === index ? ` selected` : ''}` } >
      <div className='sheet-holder' onClick={selectedIndex === index ? handleClick : onOpenFile} >
        <div className="sheet-name">
          <span>{props.name} {props.filesaveinfo && <span>*</span>}</span>
        </div>
        <div className="dropdown-icon" >
          <img src={selectedIndex === index ? DropDownActive : DropDown} alt="dropdown icon" />
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        elevation={0}
        slotProps={{
          paper: {
             sx:{
              width: '182px',
              borderRadius: '15px',
              boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.05)'
             }
          }
      }}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >

        <div className="sheet-pop-over-menu">
          <PopoverMenuItem icon={CloseIcon} title={'Close'} onClick= {handleCloseFile}/>
          <PopoverMenuItem icon={SaveIcon} title={'Save'} onClick= {handleSave}/>
          <PopoverMenuItem icon={EditIcon} title={'Edit name'} onClick= {handleRename}/>
          <PopoverMenuItem icon={DuplicateIcon} title={'Duplicate'} onClick= {handleDuplicate}/>
          <PopoverMenuItem icon={DeleteIcon} title={'Delete'} onClick= {handleDelete}/>
        </div>
        {/* <Typography className="settings-menu-item" onClick={handleOptionClick} sx={{ p: 1.5 }}>Rename</Typography>
        <Typography className="settings-menu-item" onClick={handleOptionClick} sx={{ p: 1.5 }}>Close</Typography> */}
      </Popover>
    </div>
  )
}

export default Sheet