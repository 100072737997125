// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tips-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10x;
    font-family: 'Inter';
    font-size: 13px;
    text-align: left !important;
    border-radius: 10px;
    color: #010C12; 
    font-size: 14px; 
    font-family: Inter; 
    font-weight: 400;
}

.horizandal-line {
    width: 100%;
    height: 2px;
    background-color: transparent;
}
.tips-text {
    margin-left:15px;
    margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/penalties/Tips/Tips.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB,eAAe;IACf,2BAA2B;IAC3B,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,6BAA6B;AACjC;AACA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".tips-container{\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    padding: 10x;\n    font-family: 'Inter';\n    font-size: 13px;\n    text-align: left !important;\n    border-radius: 10px;\n    color: #010C12; \n    font-size: 14px; \n    font-family: Inter; \n    font-weight: 400;\n}\n\n.horizandal-line {\n    width: 100%;\n    height: 2px;\n    background-color: transparent;\n}\n.tips-text {\n    margin-left:15px;\n    margin-right: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
