
const useToday = ()=>{
    const todayDate = new Date();
    return `${formattedNumber(todayDate.getMonth() + 1)}/${formattedNumber(todayDate.getDate())}/${formattedNumber(todayDate.getFullYear())}`; 
}

 const formattedNumber = number => {
    return number.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
  }

  export default useToday;