// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tvalue-contrainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px;
}

.dashboard-container{
    display: flex;
    width: 100%;
}

/* .sidebar-container{
    flex: 1.5;
} */

.tax-grid-container{
    display: flex;
    flex: 8 1;
    flex-direction: column;
    background-color: white;
    justify-content: space-between;
    width: 80%;
}

.active-penalties-container{
    background-color: #FAFAFA;
    height: 42px;
    padding-bottom: 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
}
.user-container {
    display: flex;
    flex: 8 1;
    flex-direction: column;
    background-color: white;
    justify-content: space-between;
    width: 80%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top:30px;
}
.vr-line-user {
    border-right: 1px solid #E8E8E8;
    height: 1000vh;
}`, "",{"version":3,"sources":["webpack://./src/components/TaxInterest/TaxInterest.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,WAAW;AACf;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;;GAEG;;AAEH;IACI,aAAa;IACb,SAAO;IACP,sBAAsB;IACtB,uBAAuB;IACvB,8BAA8B;IAC9B,UAAU;AACd;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,oBAAoB;IACpB,eAAe;IACf,SAAS;IACT,WAAW;AACf;AACA;IACI,aAAa;IACb,SAAO;IACP,sBAAsB;IACtB,uBAAuB;IACvB,8BAA8B;IAC9B,UAAU;IACV,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,+BAA+B;IAC/B,cAAc;AAClB","sourcesContent":[".tvalue-contrainer{\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    margin: 0px;\n}\n\n.dashboard-container{\n    display: flex;\n    width: 100%;\n}\n\n/* .sidebar-container{\n    flex: 1.5;\n} */\n\n.tax-grid-container{\n    display: flex;\n    flex: 8;\n    flex-direction: column;\n    background-color: white;\n    justify-content: space-between;\n    width: 80%;\n}\n\n.active-penalties-container{\n    background-color: #FAFAFA;\n    height: 42px;\n    padding-bottom: 20px;\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n}\n.user-container {\n    display: flex;\n    flex: 8;\n    flex-direction: column;\n    background-color: white;\n    justify-content: space-between;\n    width: 80%;\n    padding-left: 30px;\n    padding-right: 30px;\n    padding-top:30px;\n}\n.vr-line-user {\n    border-right: 1px solid #E8E8E8;\n    height: 1000vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
