import React from 'react'
import './Tips.css'
const Tips = (props) => {
    return (
        <div className='tips-container'>
            <div className="tips-text">
                <p>Here’s how TaxInterest uses this information. </p>
				<p className="tips-text-sub">This payment is based on the amount of an underpayment that is due to a substantial understatement of income tax.</p>
				<p className="tips-text-sub">This penalty is one of the accuracy-related penalties. Amounts subject to this penalty will not be subjected to other parts of the accuracy-related penalty.</p>
				<p className="tips-text-sub">Interest on the penalty starts on the due date of the return (with extensions).</p>
				<p className="tips-text-sub">Taxpayers who underreport their income by 10% or $5,000, whichever is higher, pay a 20% penalty of the underreported income. For corporations, other than S corporations and personal holding companies, the minimum is $10,000.</p>
			</div>
        </div>
    )
}

export default Tips