// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sheet-bar-container{
    display: flex;
    width: 100%;
    height: 49px;
    background-color: #FAFAFA;
    border-bottom: 1px #E8E8E8 solid;
    justify-content: space-between;
    align-items: center;
}

.sheets-container{
    display: flex;
    gap: 15px;
    align-items: center;
    margin-left: 15px;
    overflow-x: auto;
}
.save-all {
    margin-left: auto;
    margin-right: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sheets/Sheets.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,gCAAgC;IAChC,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".sheet-bar-container{\n    display: flex;\n    width: 100%;\n    height: 49px;\n    background-color: #FAFAFA;\n    border-bottom: 1px #E8E8E8 solid;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.sheets-container{\n    display: flex;\n    gap: 15px;\n    align-items: center;\n    margin-left: 15px;\n    overflow-x: auto;\n}\n.save-all {\n    margin-left: auto;\n    margin-right: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
