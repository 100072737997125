import { useState, useRef } from 'react';
import React from 'react';
import Tippy from '@tippyjs/react';
import './PopupCellRenderer.css';
import MoreAction from '../../assets/more-action.svg';
const PopupCellRenderer = (props) => {
  const tippyRef = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const dropDownContent = (
    <div className="menu-container">
      <div onClick={() => onClickHandler('create')} className={`menu-item ${props.rowIndex !== (props.api.getDisplayedRowCount()-1) ? 'hide' : ''}`}>
        Add New
      </div>
      <div onClick={() => onClickHandler('insert')} className={`menu-item`}>
        Insert
      </div>
      <div onClick={() => onClickHandler('delete')} className="menu-item">
        Delete Row
      </div>
    </div>
  );

  const onClickHandler = (option) => {
    hide();
    if (option === 'create') {
      props.addNewRow(props.api);
    }
    if (option === 'delete') {
      props.deleteRow(props.api, props.data)
    }

    if (option === 'insert') {
      props.insertRow(props.api, props)
    }
  };

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="bottom"
    >
      <button className="btn btn-primary" onClick={visible ? hide : show}>
        <span>{props.node.rowIndex + 1}</span><img src={MoreAction} alt="" srcset="" />
      </button>
    </Tippy>
  );
};

export default PopupCellRenderer;
